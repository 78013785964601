import AdminApi from "../../../api/adminApi";

export const FETCH_PAYMENT_REJECT_DESC = "FETCH_PAYMENT_REJECT_DESC";

export const SET_PAYMENT_REJECT_DESC = "SET_PAYMENT_REJECT_DESC";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";

const state = {
  paymentRejectDescriptions: [],
  isLoading: false,
  error: "",
};

const actions = {
  [FETCH_PAYMENT_REJECT_DESC]: async ({ commit, state }) => {
    try {
      if (state.paymentRejectDescriptions.length > 0) return;

      commit(SET_IS_LOADING, true);

      let paymentRejects = await AdminApi.getPaparaPaymentRejectDescriptions();
      commit(SET_PAYMENT_REJECT_DESC, paymentRejects);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_PAYMENT_REJECT_DESC]: (state, paymentRejectDescriptions) => (state.paymentRejectDescriptions = paymentRejectDescriptions),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
