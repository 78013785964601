import AdminApi from "../../api/adminApi";

export const FILTER_JOBS = "FILTER_JOBS";
export const FETCH_JOB_DETAILS = "FETCH_JOB_DETAILS";
export const CREATE_JOB = "CREATE_JOB";
export const CANCEL_JOB = "CANCEL_JOB";

export const SET_JOBS = "SET_JOBS";
export const SET_JOBS_COUNT = "SET_JOBS_COUNT";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_JOB_REFRESH = "SET_JOB_REFRESH";

export const TOGGLE_CREATE_DIALOG = "TOGGLE_CREATE_DIALOG";
export const SET_CREATE_IS_LOADING = "SET_CREATE_IS_LOADING";
export const SET_CREATE_SUCCESS = "SET_CREATE_SUCCESS";
export const SET_CREATE_ERROR = "SET_CREATE_ERROR";

export const SET_CANCEL_IS_LOADING = "SET_CANCEL_IS_LOADING";
export const SET_CANCEL_ERROR = "SET_CANCEL_ERROR";

export const TOGGLE_DETAIL_DIALOG = "TOGGLE_DETAIL_DIALOG";
export const SET_DETAIL = "SET_DETAIL";
export const SET_DETAIL_IS_LOADING = "SET_DETAIL_IS_LOADING";
export const SET_DETAIL_ERROR = "SET_DETAIL_ERROR";

const state = {
  jobs: [],
  totalCount: 0,
  isLoading: false,
  error: "",
  refresh: false,
  create: {
    isLoading: false,
    success: false,
    error: "",
  },
  cancel: {
    isLoading: false,
    error: "",
  },
  detail: {
    details: [],
    id: "",
    dialog: false,
    isLoading: false,
    error: "",
  },
};

const actions = {
  [FILTER_JOBS]: async ({ commit }, queryItems) => {
    try {
      commit(SET_IS_LOADING, true);

      let result = await AdminApi.filterJobs(queryItems);
      commit(SET_JOBS, result.rows);
      commit(SET_JOBS_COUNT, result.count);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [FETCH_JOB_DETAILS]: async ({ commit }, id) => {
    try {
      commit(SET_DETAIL_IS_LOADING, true);

      let result = await AdminApi.getJobDetails(id);
      commit(SET_DETAIL, result);
    } catch (error) {
      commit(SET_DETAIL_ERROR, error.response.data);
    } finally {
      commit(SET_DETAIL_IS_LOADING, false);
    }
  },
  [CREATE_JOB]: async ({ commit }, job) => {
    try {
      commit(SET_CREATE_IS_LOADING, true);

      await AdminApi.createJob(job);

      commit(SET_CREATE_SUCCESS, true);
      commit(SET_JOB_REFRESH, true);
    } catch (error) {
      commit(
        SET_CREATE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_CREATE_IS_LOADING, false);
    }
  },
  [CANCEL_JOB]: async ({ commit }, id) => {
    try {
      commit(SET_CANCEL_IS_LOADING, true);

      await AdminApi.cancelJob(id);

      commit(SET_JOB_REFRESH, true);
    } catch (error) {
      commit(
        SET_CANCEL_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_CANCEL_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_JOBS]: (state, jobs) => (state.jobs = jobs),
  [SET_JOBS_COUNT]: (state, totalCount) => (state.totalCount = totalCount),
  [SET_JOB_REFRESH]: (state, refresh) => (state.refresh = refresh),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),

  [TOGGLE_CREATE_DIALOG]: (state) => {
    state.create.isLoading = false;
    state.create.success = false;
    state.create.error = "";
  },
  [SET_CREATE_IS_LOADING]: (state, isLoading) => (state.create.isLoading = isLoading),
  [SET_CREATE_SUCCESS]: (state) => (state.create.success = true),
  [SET_CREATE_ERROR]: (state, error) => (state.create.error = error),

  [SET_CANCEL_IS_LOADING]: (state, isLoading) => (state.cancel.isLoading = isLoading),
  [SET_CANCEL_ERROR]: (state, error) => (state.cancel.error = error),

  [TOGGLE_DETAIL_DIALOG]: (state, id = "") => {
    state.detail.id = id;
    state.detail.dialog = !state.detail.dialog;
    state.detail.isLoading = false;
    state.detail.error = "";
  },
  [SET_DETAIL]: (state, details) => (state.detail.details = details),
  [SET_DETAIL_IS_LOADING]: (state, isLoading) => (state.detail.isLoading = isLoading),
  [SET_DETAIL_ERROR]: (state, error) => (state.detail.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
