<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form" :lazy-validation="false">
        <v-card-title>
          <span class="headline">Ödemeyi Reddet</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">
          <span v-html="error" />
        </v-alert>
        <v-alert v-show="success" text prominent type="success" icon="mdi-cloud-check">Ödeme Reddedildi</v-alert>
        <v-card-text>
          <v-container v-if="!success && selectedItem">
            <p>Aşağıdaki ödemeyi reddetmek istediğinize emin misiniz?</p>
            <v-simple-table style="border:1px solid #ccc">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="text-left">Müşteri</td>
                    <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                    <td class="font-weight-bold">{{ selectedItem.customer.name }} {{ selectedItem.customer.surname }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Kripto - No</td>
                    <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                    <td class="font-weight-bold">
                      <div class="green--text font-weight-bold pt-1 pb-1">{{ selectedItem.cryptoAccount.crypto.name }}({{ selectedItem.cryptoAccount.crypto.code }})</div>
                      <div class="font-weight-normal pb-1" style="width:240px;"><v-icon x-small color="light" class="pr-1">mdi-checkbook</v-icon> {{ selectedItem.cryptoAccount.number }}</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Tutar</td>
                    <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                    <td class="green--text font-weight-bold" style="font-size:16px">{{ selectedItem.amount }} <small>TL</small></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container>
            <v-form>
              <v-row>
                <v-col cols="3">
                  <v-subheader>Açıklama:</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-select
                    v-model="selected"
                    :hint="`${selected.description}`"
                    :items="descriptionItems"
                    item-text="name"
                    item-value="description"
                    label="Red Açıklaması"
                    persistent-hint
                    return-object
                    single-line
                    :rules="descriptionRules"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-show="!success" color="error" class="pl-10 pr-10" @click="reject">Reddet</v-btn>
          <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="toggleDialog">{{ success ? "Kapat" : "İptal" }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { REJECT_CRYPTO_PAYMENT, TOGGLE_REJECT_DIALOG } from "../../../../store/modules/payment/cryptoPayment";
import { FETCH_PAYMENT_REJECT_DESC } from "../../../../store/modules/paymentReject/cryptoReject";

export default {
  data: () => ({
    selected: { description: "" },
    descriptionRules: [(v) => !!v || "Lütfen ret açıklamasını giriniz"],
  }),
  computed: {
    ...mapState("cryptoPayment", {
      selectedItem: (state) => state.reject.item,
      dialog: (state) => state.reject.dialog,
      loading: (state) => state.reject.isLoading,
      success: (state) => state.reject.success,
      error: (state) => state.reject.error,
    }),
    ...mapState("cryptoReject", {
      descriptionItems: (state) => state.paymentRejectDescriptions,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
        this.description = "";
      }
    },
  },
  created() {
    this.fetchRejectDesc();
  },
  methods: {
    ...mapActions("cryptoPayment", {
      rejectPayment: REJECT_CRYPTO_PAYMENT,
    }),
    ...mapActions("cryptoReject", {
      fetchRejectDesc: FETCH_PAYMENT_REJECT_DESC,
    }),
    ...mapMutations("cryptoPayment", {
      toggleDialog: TOGGLE_REJECT_DIALOG,
    }),
    reject() {
      if (!this.$refs.form.validate()) return;

      this.rejectPayment({ id: this.selectedItem.id, descriptionId: this.selected.id });

      this.selected = { description: "" };
    },
  },
};
</script>
