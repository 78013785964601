import Axios from "axios";
import config from "../config";

class Api {
  constructor(tokenId) {
    this.sender = Axios.create({
      baseURL: `${config.SERVER_URL}${config.API_URL_PATH}/payment`,
      transformRequest: [
        function(data, headers) {
          headers["token-id"] = tokenId;
          if (headers["Content-Type"] == "application/json") {
            return JSON.stringify(data);
          }
          return data;
        },
      ],
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async paymentRequest() {
    let { data } = await this.sender.get("/");
    return data;
  }

  async selectBank(bankId) {
    let { data } = await this.sender.get(`/bank/${bankId}`);
    return data;
  }

  async selectCrypto(cryptoId) {
    let { data } = await this.sender.get(`/crypto/${cryptoId}`);
    return data;
  }

  async createPayment() {
    let { data } = await this.sender.post("/");
    return data;
  }
}

export default Api;
