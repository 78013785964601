<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-form ref="form" :lazy-validation="false">
        <v-card-title>
          <span class="headline">Ödemeyi Reddet</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">
          <span v-html="error" />
        </v-alert>
        <v-alert v-show="success" text prominent type="success" icon="mdi-cloud-check">Ödeme Reddedildi</v-alert>
        <v-card-text>
          <v-container v-if="!success && selectedItem">
            <p>Aşağıdaki ödemeyi reddetmek istediğinize emin misiniz?</p>
            <v-card elevation="2">
              <v-simple-table dense style="border:1px solid #ccc">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-left">Müşteri Adı Soyadı</td>
                      <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                      <td class="font-weight-bold">{{ selectedItem.customer.name }} {{ selectedItem.customer.surname }}</td>
                    </tr>
                    <tr>
                      <td class="text-left">Alıcı Hesap</td>
                      <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                      <td class="font-weight-bold">
                        <div class="green--text font-weight-bold pt-1 pb-1">{{ selectedItem.bank.name }} - {{ selectedItem.bankAccount.name }}</div>
                        <div class="font-weight-normal pb-1" style="width:240px;">{{ selectedItem.bankAccount.iban | iban }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">Tutar</td>
                      <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                      <td class="green--text font-weight-bold" style="font-size:16px">{{ selectedItem.amount }} <small>TL</small></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-container>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container>
            <v-form>
              <v-row>
                <v-col cols="3">
                  <v-subheader>Açıklama:</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-select
                    v-model="selected"
                    :hint="`${selected.description}`"
                    :items="descriptionItems"
                    item-text="name"
                    item-value="description"
                    label="Red Açıklaması"
                    persistent-hint
                    return-object
                    single-line
                    :rules="descriptionRules"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-btn v-if="lastPaymentsError" outlined class="pl-4 pr-4 ml-2" color="grey" text @click="lastPayments">Yeniden dene</v-btn>
          <v-alert v-if="lastPaymentsError" text prominent type="error" icon="mdi-alert-circle">
            <span v-html="lastPaymentsError" />
          </v-alert>
          <v-skeleton-loader class="mx-auto" type="table-heading, list-item-two-line" v-if="loadingPrevRequest"></v-skeleton-loader>
          <v-alert v-if="!loadingPrevRequest && !lastPaymentsError && previousPayments.length === 0" class="mt-4 mb-0" type="info" style="font-size:14px" transition="fab-transition">
            Üyenin ilk işlemidir. Daha eski işlemi bulunmamaktadır.
          </v-alert>
          <v-container v-if="!loadingPrevRequest && previousPayments.length > 0">
            <div style="color: #fbb203" class="pa-2 text-left"><strong>SON 3 İŞLEMİ</strong></div>
            <v-divider></v-divider>
            <v-card class="mx-auto" elevation="0">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #eee">
                      <th class="text-left">Tarih / ID</th>
                      <th class="text-left">Tutar</th>
                      <th class="text-left">Durum</th>
                      <th class="text-left">İşlem Bilgileri</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in previousPayments" :key="index">
                      <td class="text-left">
                        <small
                          >{{ toDate(item.createdAt) }}
                          <div class="grey--text">{{ item.customer.loginId.indexOf("-") !== -1 ? item.customer.loginId : `${item.customer.loginId}-${item.transactionId}` }}</div>
                        </small>
                      </td>
                      <td class="text-left" style="width: 100px">
                        <small>{{ item.amount }} TL</small>
                      </td>
                      <td class="text-left">
                        <v-chip small :color="item.paymentStatus.name === 'Onay Bekliyor' ? '#fcab13' : item.paymentStatus.name === 'Onaylandı' ? 'green' : 'red'" text-color="white">
                          {{ item.paymentStatus.name }}
                        </v-chip>
                      </td>
                      <td class="text-left">
                        <small
                          >{{ item.approved ? toDate(item.approvedAt) : item.rejected ? toDate(item.rejectedAt) : "" }}
                          <div class="grey--text">{{ item.approved ? item.approved.name : item.rejected ? item.rejected.name : "" }}</div></small
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-show="!success" color="error" class="pl-10 pr-10" @click="reject">Reddet</v-btn>
          <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="toggleDialog">{{ success ? "Kapat" : "İptal" }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { REJECT_BANK_PAYMENT, TOGGLE_REJECT_DIALOG, FILTER_BANK_LAST_PAYMENTS } from "../../../../store/modules/payment/bankPayment";
import { FETCH_PAYMENT_REJECT_DESC } from "../../../../store/modules/paymentReject/bankReject";
import IBAN from "iban";
import moment from "moment";

export default {
  data: () => ({
    selected: { description: "" },
    descriptionRules: [(v) => !!v || "Lütfen ret açıklamasını giriniz"],
  }),
  computed: {
    ...mapState("bankPayment", {
      selectedItem: (state) => state.reject.item,
      dialog: (state) => state.reject.dialog,
      loading: (state) => state.reject.isLoading,
      success: (state) => state.reject.success,
      error: (state) => state.reject.error,
      previousPayments: (state) => state.lastPayments.payments,
      loadingPrevRequest: (state) => state.lastPayments.isLoading,
      lastPaymentsError: (state) => state.lastPayments.error,
    }),
    ...mapState("bankReject", {
      descriptionItems: (state) => state.paymentRejectDescriptions,
    }),
  },
  created() {
    this.fetchRejectDesc();
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.filterBankLastPayments({ loginId: this.selectedItem.customer.loginId });
      }
    },
  },
  methods: {
    ...mapActions("bankPayment", {
      rejectPayment: REJECT_BANK_PAYMENT,
      filterBankLastPayments: FILTER_BANK_LAST_PAYMENTS,
    }),
    ...mapActions("bankReject", {
      fetchRejectDesc: FETCH_PAYMENT_REJECT_DESC,
    }),
    ...mapMutations("bankPayment", {
      toggleDialog: TOGGLE_REJECT_DIALOG,
    }),
    reject() {
      if (!this.$refs.form.validate()) return;

      this.rejectPayment({ id: this.selectedItem.id, descriptionId: this.selected.id });

      this.selected = { description: "" };
    },
    toDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
    lastPayments() {
      this.filterBankLastPayments({ loginId: this.selectedItem.customer.loginId });
    },
  },
  filters: {
    iban: function(iban) {
      return IBAN.printFormat(`TR${iban}`, " ");
    },
  },
};
</script>
