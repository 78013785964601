let config = null;

module.exports.initialize = async () => {
  if (config === null) {
    config = await fetch(`/config.json?v=${uuidv4()}`).then((res) => res.json());
  }

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  module.exports = config;
};
