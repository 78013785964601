import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import role from "./modules/role";
import pageAuth from "./modules/pageAuth";
import paymentTypeAuth from "./modules/paymentTypeAuth";
import page from "./modules/page";
import paymentType from "./modules/paymentType";
import paparaAccount from "./modules/account/paparaAccount";
import bankAccount from "./modules/account/bankAccount";
import cryptoAccount from "./modules/account/cryptoAccount";
import bank from "./modules/bank";
import crypto from "./modules/crypto";
import paymentStatus from "./modules/paymentStatus";
import paparaPayment from "./modules/payment/paparaPayment";
import bankPayment from "./modules/payment/bankPayment";
import cryptoPayment from "./modules/payment/cryptoPayment";
import payment from "./modules/payment/payment";
import global from "./modules/global";
import paparaReject from "./modules/paymentReject/paparaReject";
import bankReject from "./modules/paymentReject/bankReject";
import cryptoReject from "./modules/paymentReject/cryptoReject";
import withdraw from "./modules/withdraw/withdraw";
import paparaWithdraw from "./modules/withdraw/paparaWithdraw";
import bankWithdraw from "./modules/withdraw/bankWithdraw";
import cryptoWithdraw from "./modules/withdraw/cryptoWithdraw";
import job from "./modules/job";
import cache from "./modules/cache";

Vue.use(Vuex);

const debug = process.env.NODE_ENV === "DEV";

export default new Vuex.Store({
  modules: {
    user,
    role,
    pageAuth,
    paymentTypeAuth,
    page,
    paymentType,
    paparaAccount,
    bankAccount,
    cryptoAccount,
    bank,
    crypto,
    paymentStatus,
    paparaPayment,
    bankPayment,
    cryptoPayment,
    payment,
    global,
    paparaReject,
    bankReject,
    cryptoReject,
    withdraw,
    paparaWithdraw,
    bankWithdraw,
    cryptoWithdraw,
    job,
    cache,
  },
  strict: debug,
});
