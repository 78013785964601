<template>
  <div v-if="counts">
    <v-card class="mx-auto pa-2" outlined color="#e0e0e0">
      <div class="text-overline ml-2 text--warning">
        HAVALE
      </div>
      <v-row>
        <v-col cols="12" xs="12" md="12">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, index) in counts" :key="index">
                  <td class="text-left">
                    <v-icon size="medium" class="mr-2" :color="getColor(item.status)">
                      {{ getIcon(item.status) }}
                    </v-icon>
                    {{ item.status }}
                  </td>
                  <td class="text--primary text-center font-weight-bold">
                    <v-btn block elevation="0" @click="redirect(item.id)">
                      {{ item.count }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["counts"],
  methods: {
    redirect(statusId) {
      this.$router.push(`Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/payments/bank?status=${statusId}`);
    },
    getColor(name) {
      switch (name) {
        case "Onay Bekliyor":
          return "waiting";
        case "Onaylandı":
          return "success";
        case "Reddedildi":
          return "danger";
      }
    },
    getIcon(name) {
      switch (name) {
        case "Onay Bekliyor":
          return "mdi-clock";
        case "Onaylandı":
          return "mdi-check";
        case "Reddedildi":
          return "mdi-close";
      }
    },
  },
};
</script>
