<template>
  <div>
    <v-alert text dense color="info" icon="mdi-account-cash" size="12" border="left"
      ><strong class="mr-1">Çekimler Ekranı</strong> • <small class="ml-1">Bu ekranda sadece Müşteri Çekim Talepleri listelenir</small></v-alert
    >
    <v-row>
      <v-col class="gray-border">
        <v-tabs v-model="tab">
          <v-tab v-for="item in paymentTypes" :key="item.name" :to="getUrl(item.name)">
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <router-view>
          <component v-bind:is="item.name" :selectedstatus="status" v-for="item in paymentTypes" :key="item.name"></component>
        </router-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PaparaWithdraws from "./papara/PaparaWithdraws";
import BankWithdraws from "./bank/BankWithdraws";
import CryptoWithdraws from "./crypto/CryptoWithdraws";
import { mapState, mapActions, mapMutations } from "vuex";
import { FETCH_PAYMENT_TYPES } from "../../../store/modules/paymentType";
import { FETCH_PAYMENT_STATUSES } from "../../../store/modules/paymentStatus";
import { FILTER_PAPARA_PAYMENTS } from "../../../store/modules/payment/paparaPayment";
import { FILTER_BANK_PAYMENTS } from "../../../store/modules/payment/bankPayment";
import { FILTER_CRYPTO_PAYMENTS } from "../../../store/modules/payment/cryptoPayment";
import { RESET_NEW_PAYMENT } from "../../../store/modules/global";

export default {
  data() {
    return {
      tab: null,
      withdrawTypes: [
        { name: "Papara", component: "papara-withdraws", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/withdraws/papara" },
        { name: "Havale", component: "bank-withdraws", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/withdraws/bank" },
        { name: "Kripto", component: "crypto-withdraws", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/withdraws/crypto" },
      ],
      status: "",
      mapping: new Map([
        ["Papara", "papara"],
        ["Havale", "bank"],
        ["Kripto", "crypto"],
      ]),
    };
  },
  computed: {
    ...mapState("paymentType", {
      paymentTypes: (state) => state.paymentTypes,
    }),
    ...mapState("global", {
      hasNewPaymentType: (state) => state.paymentType,
    }),
  },
  components: {
    Papara: PaparaWithdraws,
    Havale: BankWithdraws,
    Kripto: CryptoWithdraws,
  },
  // watch: {
  //   async tab(newVal) {
  //     // if (this.paymentTypes.length === 0) {
  //     //   await this.fetchPaymentTypes();
  //     // }
  //     // switch (this.paymentTypes[newVal].name) {
  //     //   case "Papara":
  //     //     if (this.hasNewPaymentType === "Papara") this.resetNewPayment();
  //     //     this.filterPaparaPayments({ status: this.status });
  //     //     break;
  //     //   case "Havale":
  //     //     if (this.hasNewPaymentType === "Havale") this.resetNewPayment();
  //     //     this.filterBankPayments({ status: this.status });
  //     //     break;
  //     //   case "Kripto":
  //     //     if (this.hasNewPaymentType === "Kripto") this.resetNewPayment();
  //     //     this.filterCryptoPayments({ status: this.status });
  //     //     break;
  //     // }
  //   },
  // },
  created() {
    this.fetchPaymentStatuses();
    this.fetchPaymentTypes();

    if (this.$route.query.status) {
      this.status = [this.$route.query.status];
    }

    if (this.$route.query.index) {
      this.tab = parseInt(this.$route.query.index, 10);
    }
  },
  methods: {
    ...mapActions("paymentType", {
      fetchPaymentTypes: FETCH_PAYMENT_TYPES,
    }),
    ...mapActions("paymentStatus", {
      fetchPaymentStatuses: FETCH_PAYMENT_STATUSES,
    }),
    ...mapActions("paparaPayment", {
      filterPaparaPayments: FILTER_PAPARA_PAYMENTS,
    }),
    ...mapActions("bankPayment", {
      filterBankPayments: FILTER_BANK_PAYMENTS,
    }),
    ...mapActions("cryptoPayment", {
      filterCryptoPayments: FILTER_CRYPTO_PAYMENTS,
    }),
    ...mapMutations("global", {
      resetNewPayment: RESET_NEW_PAYMENT,
    }),
    getUrl(paymentType) {
      return `/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/withdraws/${this.mapping.get(paymentType)}`;
    },
  },
};
</script>
