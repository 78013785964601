<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Hesabı Güncelle</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <v-form ref="form">
          <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
          <v-alert v-show="success" color="primary" type="success" icon="mdi-cloud-check">Hesap Güncellendi</v-alert>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="item.name" label="Hesap Adı" required :readonly="!isAdmin && !isSupervisor" :rules="nameRules" maxlength="50" counter :disabled="success"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="item.number" label="Hesap No" required :readonly="!isAdmin && !isSupervisor" :rules="numberRules" maxlength="20" counter :disabled="success"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field v-model="item.lowerLimit" label="Alt Limit" type="number" required :rules="lowerLimitRules" counter :disabled="success"></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field v-model="item.upperLimit" label="Üst Limit" type="number" required :rules="upperLimitRules" counter :disabled="success"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" class="pl-10 pr-10" v-show="!success && !loading" @click="save">Kaydet</v-btn>
        <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="toggleDialog">{{ !success && !loading ? "İptal" : "Kapat" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { UPDATE_PAPARA_ACCOUNT, TOGGLE_UPDATE_DIALOG } from "../../../../store/modules/account/paparaAccount";

export default {
  data: () => ({
    id: "",
    item: {
      name: "",
      number: "",
      lowerLimit: 0,
      upperLimit: 1,
    },
    nameRules: [(v) => !!v || "Lütfen hesap adını giriniz"],
    numberRules: [(v) => !!v || "Lütfen hesap numarasını giriniz"],
    lowerLimitRules: [(v) => v >= 0 || "Lütfen alt limiti minimum 0 olarak giriniz"],
    upperLimitRules: [(v) => v >= 1 || "Lütfen üst limiti minimum 1 olarak giriniz"],
  }),
  computed: {
    ...mapState("paparaAccount", {
      updateItem: (state) => state.update.item,
      dialog: (state) => state.update.dialog,
      loading: (state) => state.update.isLoading,
      error: (state) => state.update.error,
      success: (state) => state.update.success,
    }),
    ...mapState("user", {
      isAdmin: (state) => state.user.role.isAdmin,
      isPaparaRole: (state) => state.user.role.isPaparaRole,
      isSupervisor: (state) => state.user.role.isSupervisor,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
      }
    },
    updateItem(newVal) {
      this.id = newVal.id;
      this.item = {
        name: newVal.name,
        number: newVal.number,
        lowerLimit: newVal.lowerLimit,
        upperLimit: newVal.upperLimit,
      };
    },
  },
  methods: {
    ...mapActions("paparaAccount", {
      updatePaparaAccount: UPDATE_PAPARA_ACCOUNT,
    }),
    ...mapMutations("paparaAccount", {
      toggleUpdateDialog: TOGGLE_UPDATE_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.updatePaparaAccount({ id: this.id, account: this.item });
    },
    toggleDialog() {
      this.toggleUpdateDialog();
    },
  },
};
</script>
