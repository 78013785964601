<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" depressed v-on="on">
        <v-icon left>mdi-plus-thick</v-icon>Yeni</v-btn
      >
    </template>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Yetki Oluştur</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-show="error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
            >Yetki Oluşturuldu</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="item.paymentTypeId"
                  :items="paymentTypes"
                  item-text="name"
                  item-value="id"
                  dense
                  label="Ödeme Yöntemi Seçiniz"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            v-show="!success && !loading"
            @click="save"
            >Kaydet</v-btn
          >
          <v-btn color="grey darken-1" text @click="dialog = false"
            >İptal</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  CREATE_PAYMENT_TYPE_AUTHS,
  CLEAR_CREATE,
} from "../../../store/modules/paymentTypeAuth";
import { FETCH_PAYMENT_TYPES } from "../../../store/modules/paymentType";

export default {
  props: ["roleId"],
  data: () => ({
    dialog: false,
    item: {
      paymentTypeId: "",
    },
    nameRules: [(v) => !!v || "Ödeme Yöntemi seçiniz"],
  }),
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
        this.fetchPaymentTypes();
        this.clearCreate();
        this.item.paymentTypeId = "";
      }
    },
  },
  computed: {
    ...mapState("paymentTypeAuth", {
      loading: (state) => state.create.isLoading,
      error: (state) => state.create.error,
      success: (state) => state.create.success,
    }),
    ...mapState("paymentType", {
      paymentTypes: (state) => state.paymentTypes,
    }),
  },
  methods: {
    ...mapActions("paymentTypeAuth", {
      createPaymentTypeAuths: CREATE_PAYMENT_TYPE_AUTHS,
    }),
    ...mapActions("paymentType", {
      fetchPaymentTypes: FETCH_PAYMENT_TYPES,
    }),
    ...mapMutations("paymentTypeAuth", {
      clearCreate: CLEAR_CREATE,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.createPaymentTypeAuths({ ...this.item, roleId: this.roleId });
    },
  },
};
</script>
