var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"1000px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.loading && _vm.details.length == 0)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card, list-item"}}):_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Görev Detayı")])]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"450px"}},[(_vm.details.length == 0 && _vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table-heading, table-tbody, table-tfoot"}}):_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.details,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.processDate",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("date")(item.processDate)))])]}},{key:"item.processStart",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.processStart)))])]}},{key:"item.processEnd",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.processEnd)))])]}},{key:"item.isError",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!item.isError},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"small":"","color":item.isError ? 'danger' : 'green',"dark":""}},on),[_vm._v(" "+_vm._s(item.isError ? "Hata Var" : "Hata Yok")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.errorMessage))])])]}}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"pl-4 pr-4 ml-2",attrs:{"outlined":"","color":"grey","text":""},on:{"click":_vm.toggleDialog}},[_vm._v("Kapat")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }