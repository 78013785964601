<template>
  <div>
    <v-alert v-model="isPageError" dismissible text prominent type="error" icon="mdi-alert-circle">{{ pageError }}</v-alert>
    <v-skeleton-loader class="mx-auto" type="table-heading, table-tbody, table-tfoot" v-if="payments.length == 0 && isLoading"></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="payments"
      @update:options="changedOptions"
      :options.sync="options"
      :server-items-length="totalCount"
      class="elevation-1"
      :footer-props="footerSettings"
      v-else
    >
      <template v-slot:top>
        <v-container class="filter-area" fluid>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                v-model="filters.status"
                :items="paymentStatuses"
                clearable
                dense
                multiple
                outlined
                item-text="name"
                item-value="id"
                label="Durum Seçiniz"
                background-color="white"
                v-on:change="filter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" xs="6" sm="6" md="4">
              <v-datetime-picker label="Başlangıç Tarihi" v-model="filters.startDate" :text-field-props="{ appendIcon: 'mdi-calendar-today' }"
                ><template slot="dateIcon">
                  <v-icon>mdi-calendar-blank-multiple</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-time-four-outline</v-icon>
                </template></v-datetime-picker
              >
            </v-col>
            <v-col cols="6" xs="6" sm="6" md="4">
              <v-datetime-picker label="Bitiş Tarihi" v-model="filters.endDate" :text-field-props="{ appendIcon: 'mdi-calendar-today' }"
                ><template slot="dateIcon">
                  <v-icon>mdi-calendar-blank-multiple</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-time-four-outline</v-icon>
                </template></v-datetime-picker
              >
            </v-col>
            <v-col cols="12" xs="6" sm="4" md="4">
              <v-text-field v-model="filters.name" label="Ad" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="4" md="4">
              <v-text-field v-model="filters.surname" label="Soyad" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="4" md="4">
              <v-text-field v-model="filters.loginId" label="User ID - Payment ID" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12" sm="6" md="12">
              <downloadexcel
                class="v-btn theme--light v-size--default v-btn--is-elevated white"
                :fetch="exportToExcel"
                :fields="excelFields"
                :before-generate="startDownload"
                :before-finish="finishDownload"
                worksheet="Rapor"
                name="Kripto_Odemeleri.xls"
                inline-block
                style="width: 140px"
                v-if="excelAuth"
              >
                <span class="v-btn__content" v-if="!startDownloadParam">
                  <v-icon color="success" class="mr-2" small>
                    mdi-microsoft-excel
                  </v-icon>
                  Rapor Al
                </span>
                <span class="v-btn__load`er" v-else
                  ><div
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="v-progress-circular v-progress-circular--visible v-progress-circular--indeterminate"
                    style="height: 23px; width: 23px;"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="21.904761904761905 21.904761904761905 43.80952380952381 43.80952380952381" style="transform: rotate(0deg);">
                      <circle
                        fill="transparent"
                        cx="43.80952380952381"
                        cy="43.80952380952381"
                        r="20"
                        stroke-width="3.8095238095238093"
                        stroke-dasharray="125.664"
                        stroke-dashoffset="125.66370614359172px"
                        class="v-progress-circular__overlay"
                      ></circle>
                    </svg>
                    <div class="v-progress-circular__info"></div></div
                ></span>
              </downloadexcel>
              <v-btn class="float-right" @click="reset" inline-block outlined> Reset</v-btn>
              <v-btn color="primary" class="mr-2 float-right" @click="filter" inline-block> Listele</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <approve-crypto-payment-dialog />
        <reject-crypto-payment-dialog />
        <crypto-payment-history-dialog />
      </template>
      <template v-slot:item.loginId="{ item }"> {{ item.customer.loginId.indexOf("-") !== -1 ? item.customer.loginId : `${item.customer.loginId}-${item.transactionId}` }}</template>
      <template v-slot:item.customerInfo="{ item }">
        {{ `${item.customer.name} ${item.customer.surname}` }}
        <div class="grey--text">{{ item.customer.email }}</div>
      </template>
      <template v-slot:item.amount="{ item }">
        <div class="text-right primary--text font-weight-bold">{{ `${item.amount}` }} <small class="text-muted">TL</small></div>
      </template>
      <template v-slot:item.coinConversion="{ item }">
        <div class="text-right primary--text font-weight-bold" v-if="item.coinConversion">
          {{ `${item.coinConversion}` }}
          <small class="text-muted">{{ item.cryptoAccount.crypto.code }}</small>
        </div>
      </template>
      <template v-slot:item.account="{ item }">
        <div class="green--text font-weight-bold pb-1 pt-1">
          {{ `${item.cryptoAccount.crypto.name}` }}
        </div>
        <div class="font-weight-normal">
          <v-icon x-small color="light" class="pr-1">mdi-checkbook</v-icon>
          {{ `${item.cryptoAccount.number}` }}
        </div>
        <div class="font-weight-normal pb-1" v-if="item.cryptoAccount.destinationTag">
          <v-icon x-small color="light" class="pr-1">mdi-pound</v-icon>
          <small class="grey--text">{{ `${item.cryptoAccount.destinationTag}` }}</small>
        </div>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment }}
      </template>
      <template v-slot:item.transactions="{ item }"
        >{{ item.approved ? toDate(item.approvedAt) : item.rejected ? toDate(item.rejectedAt) : "" }}
        <br />
        <div class="grey--text">
          {{ item.approved ? item.approved.name : item.rejected ? item.rejected.name : "" }}
        </div>
        <div class="grey--text" v-if="historyAuth && item.paymentStatus.name !== 'Onay Bekliyor'">
          <v-btn class="ma-1 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--x-small" x-small @click="() => toggleHistoryDialog(item.id)">
            <v-icon class="mr-1" size="12">mdi-information</v-icon> DETAY
          </v-btn>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip small :color="item.paymentStatus.name === 'Onay Bekliyor' ? '#fcab13' : item.paymentStatus.name === 'Onaylandı' ? 'green' : 'red'" text-color="white">
          {{ item.paymentStatus.name }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }" v-if="processAuth">
        <v-tooltip bottom v-if="item.paymentStatus.name === 'Onay Bekliyor'">
          <template v-slot:activator="{ on }">
            <v-btn class="ma-1" color="success" small @click="() => toggleApproveDialog(item)" v-on="on">
              <v-icon size="18">mdi-check</v-icon>
            </v-btn>
          </template>
          <span>Onayla</span> </v-tooltip
        ><v-tooltip bottom v-if="item.paymentStatus.name === 'Onay Bekliyor'">
          <template v-slot:activator="{ on }">
            <v-btn class="ma-1" color="error" small @click="() => toggleRejectDialog(item)" v-on="on">
              <v-icon size="18">mdi-window-close</v-icon>
            </v-btn>
          </template>
          <span>Reddet</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.paymentStatus.name === 'Reddedildi'">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" size="24" small>mdi-comment-text-multiple</v-icon>
          </template>
          <span>{{ item.description || item.rejectDescription.name }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.url="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :href="`/${item.tokenId}`" target="blank" v-on="on" x-small>
              <v-icon size="18">mdi-link</v-icon>
            </v-btn>
          </template>
          <span>{{ item.tokenId }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { FILTER_CRYPTO_PAYMENTS, TOGGLE_APPROVE_DIALOG, TOGGLE_REJECT_DIALOG, SET_CRYPTO_REFRESH, TOGGLE_HISTORY_DIALOG } from "../../../../store/modules/payment/cryptoPayment";
import { RESET_NEW_PAYMENT } from "../../../../store/modules/global";
import { FETCH_PAYMENT_STATUSES } from "../../../../store/modules/paymentStatus";
import ApproveCryptoPaymentDialog from "./ApproveCryptoPaymentDialog";
import RejectCryptoPaymentDialog from "./RejectCryptoPaymentDialog";
import CryptoPaymentHistoryDialog from "./CryptoPaymentHistoryDialog";
import moment from "moment";
import AdminApi from "../../../../api/adminApi";
import dateUtil from "../../../../utils/date";
import downloadexcel from "vue-json-excel";

export default {
  data: () => ({
    isPageError: false,
    pageError: "",
    options: {
      itemsPerPage: 15,
      page: 1,
    },
    filters: { startDate: "", endDate: "", status: [], name: "", surname: "", loginId: "" },
    headers: [
      { text: "User Id", sortable: false, value: "loginId" },
      { text: "Müşteri Bilgileri", sortable: false, value: "customerInfo" },
      { text: "Tutar", sortable: false, value: "amount", align: "center" },
      { text: "Kripto Tutar", sortable: false, value: "coinConversion", align: "center" },
      { text: "Alıcı Hesap", sortable: false, value: "account" },
      { text: "Tarih", sortable: false, value: "createdAt" },
      { text: "Durum", sortable: false, value: "status", align: "center" },
      { text: "İşlem Bilgileri", sortable: false, value: "transactions", align: "center" },
      { text: "İşlemler", sortable: false, value: "actions" },
      { text: "Url", sortable: false, value: "url" },
    ],
    footerSettings: {
      itemsPerPageOptions: [15],
    },
    excelFields: {
      "Müşteri Id": "customerLoginId",
      "Müşteri Adı": "customerName",
      "Müşteri E-mail": "customer.email",
      "Yatırılan Tutar(TL)": "amount",
      "Kripto Tutar": "coinConversion",
      Birim: "coinCode",
      "Alıcı Kripto": "cryptoAccount.crypto.name",
      "Alıcı Hesap Cüzdanı": "cryptoAccount.number",
      "Alıcı Hesap Etiketi": "cryptoAccount.destinationTag",
      "Talep Tarihi": "createdAt",
      "Cevap Süresi(dk)": "responseTime",
      Durum: "paymentStatus.name",
      "Onay Tarihi": "approvedAt",
      "Onay Veren": "approvedBy",
      "Ret Tarihi": "rejectedAt",
      "Ret Veren": "rejectedBy",
      "Ret Açıklaması": "description",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    startDownloadParam: false,
  }),
  components: {
    "approve-crypto-payment-dialog": ApproveCryptoPaymentDialog,
    "reject-crypto-payment-dialog": RejectCryptoPaymentDialog,
    "crypto-payment-history-dialog": CryptoPaymentHistoryDialog,
    downloadexcel,
  },
  computed: {
    ...mapState("cryptoPayment", {
      payments: (state) => state.cryptoPayments,
      totalCount: (state) => state.totalCount,
      isLoading: (state) => state.isLoading,
      error: (state) => state.error,
      refresh: (state) => state.refresh,
    }),
    ...mapState("paymentStatus", {
      paymentStatuses: (state) => state.paymentStatuses,
    }),
    ...mapState("global", {
      hasNewPaymentType: (state) => state.paymentType,
    }),
    ...mapState("user", {
      processAuth: (state) => state.user.role.auth.some((a) => a.code == "cpp"),
      historyAuth: (state) => state.user.role.auth.some((a) => a.code == "cph"),
      excelAuth: (state) => state.user.role.isAdmin || state.user.auth.some((a) => a.code == "cpee"),
    }),
    status() {
      return this.filters.status;
    },
  },
  watch: {
    status(newValue, oldValue) {
      if (newValue.length === 0 && newValue.length !== oldValue.length) {
        this.filterAccounts({ ...this.options, ...this.filters });
      }
    },
    selectedstatus(newVal) {
      this.filters.status = newVal;
    },
    refresh(value) {
      if (value) {
        this.setRefresh(false);
        this.filterAccounts({ ...this.options, ...this.filters });
      }
    },
    hasNewPaymentType(newVal) {
      if (newVal === "Kripto") this.resetNewPayment();
    },
    pageError(value) {
      this.isPageError = value != "";
    },
  },
  created() {
    if (this.hasNewPaymentType === "Kripto") this.resetNewPayment();

    if (this.$route.query.status) {
      this.filters.status = this.$route.query.status;
    }

    this.filter();
    this.fetchPaymentStatus();
  },
  methods: {
    ...mapActions("cryptoPayment", {
      filterAccounts: FILTER_CRYPTO_PAYMENTS,
    }),
    ...mapMutations("cryptoPayment", {
      toggleApproveDialog: TOGGLE_APPROVE_DIALOG,
      toggleRejectDialog: TOGGLE_REJECT_DIALOG,
      setRefresh: SET_CRYPTO_REFRESH,
      toggleHistoryDialog: TOGGLE_HISTORY_DIALOG,
    }),
    ...mapMutations("global", {
      resetNewPayment: RESET_NEW_PAYMENT,
    }),
    ...mapMutations("paymentStatus", {
      fetchPaymentStatus: FETCH_PAYMENT_STATUSES,
    }),
    filter() {
      this.pageError = "";

      this.options = { itemsPerPage: 15, page: 1 };
      this.filterAccounts({ ...this.options, ...this.filters });
    },
    reset() {
      this.pageError = "";

      this.filters = { startDate: "", endDate: "", status: [], name: "", surname: "", loginId: "" };
      this.options = { itemsPerPage: 15, page: 1 };

      this.filterAccounts({ ...this.options, ...this.filters });
    },
    changedOptions(data) {
      if (data.itemsPerPage != this.options.itemsPerPage || data.page != this.options.page) {
        this.pageError = "";

        this.filterAccounts({ ...data, ...this.filters });
      }
    },
    toDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
    async exportToExcel() {
      this.pageError = "";

      try {
        let result = await AdminApi.exportCryptoPayments({
          ...this.options,
          ...this.filters,
          itemsPerPage: -1,
          page: -1,
        });

        if (result.rows.length == 0) {
          this.pageError = "Excel'e aktarılacak veri bulunamadı filtre alanını kontrol ediniz";
          this.startDownloadParam = false;
          return;
        }

        return result.rows.map((item) => {
          return {
            ...item,
            customerLoginId: item.customer.loginId.indexOf("-") !== -1 ? item.customer.loginId : `${item.customer.loginId}-${item.transactionId}`,
            customerName: `${item.customer.name} ${item.customer.surname}`,
            coinConversion: `${item.coinConversion}`,
            coinCode: `${item.cryptoAccount.crypto.code}`,
            responseTime: item.approved || item.rejected ? `${dateUtil.getMinutes(item.approvedAt || item.rejectedAt, item.createdAt)}` : "",
            createdAt: this.toDate(item.createdAt),
            approvedAt: item.approved ? this.toDate(item.approvedAt) : "",
            approvedBy: item.approved ? item.approved.name : "",
            rejectedAt: item.rejected ? this.toDate(item.rejectedAt) : "",
            rejectedBy: item.rejected ? item.rejected.name : "",
            description: item.description ? item.description : item.rejectDescription ? item.rejectDescription.name : "",
          };
        });
      } catch (error) {
        this.pageError = error;
        this.startDownloadParam = false;
      }
    },
    startDownload() {
      this.startDownloadParam = true;
    },
    finishDownload() {
      setTimeout(() => (this.startDownloadParam = false), 1200);
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
  },
};
</script>
