<template>
  <div>
    <v-skeleton-loader class="mx-auto" type="table-heading, table-tbody, table-tfoot" v-if="isLoading"></v-skeleton-loader>
    <v-data-table :headers="headers" :items="cacheList" class="elevation-1" hide-default-footer disable-pagination v-else>
      <template v-slot:top>
        <v-container class="filter-area" fluid>
          <v-row class="mb-2">
            <v-col cols="12" xs="6" sm="6" md="10">
              <v-text-field v-model="filters.key" label="Cache Key" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="2" class="text-right">
              <v-btn color="primary" inline-block @click="filter">Listele</v-btn>
              <v-btn @click="reset" inline-block outlined class="ml-2">Reset</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.expireDate="{ item }">
        {{ item.expireDate | moment }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="danger" dark small elevation="2" class="mx-2" @click="() => deleteCache(item.key)" v-on="on">
              <v-icon size="18">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Cache Sil</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { FETCH_CACHE, DELETE_CACHE } from "../../../store/modules/cache";
import moment from "moment";

export default {
  data: () => ({
    filters: {
      key: "",
    },
    headers: [
      { text: "Key", sortable: false, align: "center", value: "key" },
      { text: "Bitiş Tarihi", sortable: false, align: "center", value: "expireDate" },
      { text: "İşlemler", sortable: false, value: "actions" },
    ],
  }),
  computed: {
    ...mapState("cache", {
      cacheList: (state) => state.cacheList,
      isLoading: (state) => state.isLoading,
    }),
  },
  created() {
    this.filter();
  },
  methods: {
    ...mapActions("cache", {
      fetchCache: FETCH_CACHE,
      deleteCache: DELETE_CACHE,
    }),
    filter() {
      this.fetchCache(this.filters);
    },
    reset() {
      this.filters = {
        key: "",
      };

      this.filter();
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
  },
};
</script>
