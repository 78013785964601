<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Kullanıcıyı Güncelle</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 400px;">
        <v-form ref="form">
          <v-alert
            v-show="error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
            >Kullanıcı Güncellendi</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.name"
                  label="İsim"
                  required
                  :rules="nameRules"
                  maxlength="20"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.email"
                  label="Email"
                  required
                  :rules="emailRules"
                  maxlength="100"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="9" sm="3" md="9">
                <v-text-field
                  v-model="item.password"
                  label="Şifre"
                  required
                  :rules="passwordRules"
                  maxlength="12"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-btn
                  color="primary"
                  depressed
                  @click="generatePassword"
                  :disabled="success"
                  >Oluştur</v-btn
                >
              </v-col>
              <v-col cols="9" sm="4" md="9">
                <v-autocomplete
                  v-model="item.roleId"
                  :items="roles"
                  item-text="name"
                  item-value="id"
                  dense
                  label="Yetki Seçiniz"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" sm="2" md="3">
                <role-dialog />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="pl-10 pr-10"
          v-show="!success && !loading"
          @click="save"
          >Kaydet</v-btn
        >
        <v-btn
          outlined
          class="pl-4 pr-4 ml-2"
          color="grey"
          text
          @click="toggleDialog"
          >{{ !success && !loading ? "İptal" : "Kapat" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { UPDATE_USER, TOGGLE_UPDATE_DIALOG } from "../../../store/modules/user";
import { FETCH_ROLES } from "../../../store/modules/role";
import RoleDialog from "../role/RoleDialog";
import { generatePassword } from "../../../utils/password";

export default {
  data: () => ({
    id: "",
    item: {
      name: "",
      email: "",
      password: "",
      roleId: "",
    },
    nameRules: [(v) => !!v || "Lütfen isim giriniz"],
    emailRules: [(v) => !!v || "Lütfen email adresini giriniz"],
    passwordRules: [
      (v) =>
        v.length === 0 ||
        v.length >= 6 ||
        "Lütfen şifreyi en az 6 karakter olarak giriniz",
      (v) =>
        v.length === 0 ||
        v.length <= 12 ||
        "Lütfen şifreyi en fazla 12 karakter olarak giriniz",
    ],
    roleRules: [(v) => !!v || "Lütfen yetkiyi seçiniz"],
  }),
  components: {
    "role-dialog": RoleDialog,
  },
  computed: {
    ...mapState("user", {
      updateItem: (state) => state.update.item,
      dialog: (state) => state.update.dialog,
      loading: (state) => state.update.isLoading,
      error: (state) => state.update.error,
      success: (state) => state.update.success,
    }),
    ...mapState("role", {
      roles: (state) => state.roles,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
        this.fetchRoles();
      }
    },
    updateItem(newVal) {
      this.id = newVal.id;
      this.item = {
        name: newVal.name,
        email: newVal.email,
        password: "",
        roleId: newVal.role?.id,
      };
    },
  },
  methods: {
    ...mapActions("user", {
      updateUser: UPDATE_USER,
    }),
    ...mapMutations("user", {
      toggleUpdateDialog: TOGGLE_UPDATE_DIALOG,
    }),
    ...mapActions("role", {
      fetchRoles: FETCH_ROLES,
    }),
    generatePassword() {
      this.item.password = generatePassword(8);
    },
    save() {
      if (!this.$refs.form.validate()) return;

      this.updateUser({ id: this.id, user: this.item });
    },
    toggleDialog() {
      this.toggleUpdateDialog();
    },
  },
};
</script>
