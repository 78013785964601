import AdminApi from "../../api/adminApi";

export const FETCH_ROLES = "FETCH_ROLES";
export const CREATE_ROLE = "CREATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

export const SET_ROLES = "SET_ROLES";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";

export const TOGGLE_CREATE_DIALOG = "TOGGLE_CREATE_DIALOG";
export const SET_CREATE_IS_LOADING = "SET_CREATE_IS_LOADING";
export const SET_CREATE_SUCCESS = "SET_CREATE_SUCCESS";
export const SET_CREATE_ERROR = "SET_CREATE_ERROR";

export const TOGGLE_DELETE_DIALOG = "TOGGLE_DELETE_DIALOG";
export const SET_DELETE_IS_LOADING = "SET_DELETE_IS_LOADING";
export const SET_DELETE_SUCCESS = "SET_DELETE_SUCCESS";
export const SET_DELETE_ERROR = "SET_DELETE_ERROR";

const state = {
  roles: [],
  isLoading: false,
  error: "",
  create: {
    isLoading: false,
    success: false,
    error: "",
  },
  delete: {
    dialog: false,
    id: "",
    isLoading: false,
    success: false,
    error: "",
  },
};

const actions = {
  [FETCH_ROLES]: async ({ commit }) => {
    try {
      commit(SET_IS_LOADING, true);

      let roles = await AdminApi.getRoles();
      commit(SET_ROLES, roles);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [CREATE_ROLE]: async ({ commit, dispatch }, role) => {
    try {
      commit(SET_CREATE_IS_LOADING, true);

      await AdminApi.createRole(role);

      commit(SET_CREATE_SUCCESS, true);
      dispatch(FETCH_ROLES);
    } catch (error) {
      commit(
        SET_CREATE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_CREATE_IS_LOADING, false);
    }
  },
  [DELETE_ROLE]: async ({ commit, dispatch }, id) => {
    try {
      commit(SET_DELETE_IS_LOADING, true);

      await AdminApi.deleteRole(id);

      commit(SET_DELETE_SUCCESS, true);
      dispatch(FETCH_ROLES);
    } catch (error) {
      commit(
        SET_DELETE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_DELETE_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_ROLES]: (state, roles) => (state.roles = roles),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),

  [TOGGLE_CREATE_DIALOG]: (state) => {
    (state.create.isLoading = false), (state.create.success = false), (state.create.error = "");
  },
  [SET_CREATE_IS_LOADING]: (state, isLoading) => (state.create.isLoading = isLoading),
  [SET_CREATE_SUCCESS]: (state) => (state.create.success = true),
  [SET_CREATE_ERROR]: (state, error) => (state.create.error = error),

  [TOGGLE_DELETE_DIALOG]: (state, id = "") => {
    state.delete.dialog = !state.delete.dialog;
    state.delete.id = id;
    state.delete.isLoading = false;
    state.delete.success = false;
    state.delete.error = "";
  },
  [SET_DELETE_IS_LOADING]: (state, isLoading) => (state.delete.isLoading = isLoading),
  [SET_DELETE_SUCCESS]: (state) => (state.delete.success = true),
  [SET_DELETE_ERROR]: (state, error) => (state.delete.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
