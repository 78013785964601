<template>
  <v-app>
    <v-app-bar app color="#151414" v-if="paymentLayout && !classIsHome.isHome" v-bind:class="classIsHome">
      <v-container>
        <v-row>
          <v-col align="center"><img id="clogo" :src="getLogoUrl()" style="height: 42px; margin:auto auto -8px auto;"/></v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main v-bind:class="!paymentLayout ? 'isAdmin' : ''">
      <v-container fluid>
        <router-view> </router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import config from "./config";

export default {
  name: "App",
  computed: {
    ...mapState("global", {
      paymentLayout: (state) => state.layout === "Payment",
    }),
    classIsAdmin: function() {
      return {
        isAdmin: ["Login", "Dashboard", "Users", "Payments", "Accounts", "Settings", "AdminErrorPage", "Home"].includes(this.$route.name),
      };
    },
    classIsHome: function() {
      return {
        isHome: ["Home"].includes(this.$route.name),
      };
    },
  },
  data: () => ({}),
  methods: {
    getLogoUrl() {
      return `${config.ASSET_URL}${config.APP_LOGO}`;
    },
  },
};
</script>
<style>
.bg-img {
  padding: 0;
  background-color: #f0f0f0;
  background-size: 100% auto;
  min-height: 1000px;
  padding-top: 0px;
  position: fixed;
  left: 0;
  top: 0;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
  min-height: 2px !important;
}
</style>
