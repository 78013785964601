<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" depressed v-on="on"> <v-icon left>mdi-plus-thick</v-icon>Yeni</v-btn>
    </template>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Yetki Oluştur</span>
        </v-card-title>
        <v-card-text>
          <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
          <v-alert v-show="success" text prominent type="success" icon="mdi-cloud-check">Yetki Oluşturuldu</v-alert>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="item.pageId"
                  :items="pages"
                  item-text="name"
                  item-value="id"
                  dense
                  label="Sayfa Seçiniz"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" v-show="!success && !loading" @click="save">Kaydet</v-btn>
          <v-btn color="grey darken-1" text @click="dialog = false">İptal</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { CREATE_PAGE_AUTHS, CLEAR_CREATE } from "../../../store/modules/pageAuth";
import { FETCH_PAGES } from "../../../store/modules/page";

export default {
  props: ["roleId"],
  data: () => ({
    dialog: false,
    item: {
      pageId: "",
    },
    nameRules: [(v) => !!v || "Sayfa seçiniz"],
  }),
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
        this.fetchPages();
        this.clearCreate();
        this.item.pageId = "";
      }
    },
  },
  computed: {
    ...mapState("pageAuth", {
      loading: (state) => state.create.isLoading,
      error: (state) => state.create.error,
      success: (state) => state.create.success,
    }),
    ...mapState("page", {
      pages: (state) => state.pages,
    }),
  },
  methods: {
    ...mapActions("pageAuth", {
      createPageAuths: CREATE_PAGE_AUTHS,
    }),
    ...mapActions("page", {
      fetchPages: FETCH_PAGES,
    }),
    ...mapMutations("pageAuth", {
      clearCreate: CLEAR_CREATE,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.createPageAuths({ ...this.item, roleId: this.roleId });
    },
  },
};
</script>
