import AdminApi from "../../../api/adminApi";

export const FILTER_CRYPTO_ACCOUNTS = "FILTER_CRYPTO_ACCOUNTS";
export const CREATE_CRYPTO_ACCOUNT = "CREATE_CRYPTO_ACCOUNT";
export const UPDATE_CRYPTO_ACCOUNT = "UPDATE_CRYPTO_ACCOUNT";
export const CHANGE_CRYPTO_ACCOUNT_STATUS = "CHANGE_CRYPTO_ACCOUNT_STATUS";
export const BANNED_CRYPTO_ACCOUNT = "BANNED_CRYPTO_ACCOUNT";
export const BAN_ROLLBACK_CRYPTO_ACCOUNT = "BAN_ROLLBACK_CRYPTO_ACCOUNT";
export const UPDATE_CRYPTO_ACCOUNT_NOTE = "UPDATE_CRYPTO_ACCOUNT_NOTE";

export const SET_CRYPTO_ACCOUNTS = "SET_CRYPTO_ACCOUNTS";
export const SET_CRYPTO_ACCOUNTS_COUNT = "SET_CRYPTO_ACCOUNTS_COUNT";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_CRYPTO_REFRESH = "SET_CRYPTO_REFRESH";

export const TOGGLE_CREATE_DIALOG = "TOGGLE_CREATE_DIALOG";
export const SET_CREATE_IS_LOADING = "SET_CREATE_IS_LOADING";
export const SET_CREATE_SUCCESS = "SET_CREATE_SUCCESS";
export const SET_CREATE_ERROR = "SET_CREATE_ERROR";

export const TOGGLE_UPDATE_DIALOG = "TOGGLE_UPDATE_DIALOG";
export const SET_UPDATE_IS_LOADING = "SET_UPDATE_IS_LOADING";
export const SET_UPDATE_SUCCESS = "SET_UPDATE_SUCCESS";
export const SET_UPDATE_ERROR = "SET_UPDATE_ERROR";

export const SET_STATUS_IS_LOADING = "SET_STATUS_IS_LOADING";
export const SET_STATUS_ERROR = "SET_STATUS_ERROR";

export const SET_BANNED_IS_LOADING = "SET_BANNED_IS_LOADING";
export const SET_BANNED_ERROR = "SET_BANNED_ERROR";

export const TOGGLE_NOTE_DIALOG = "TOGGLE_NOTE_DIALOG";

const state = {
  cryptoAccounts: [],
  totalCount: 0,
  isLoading: false,
  error: "",
  refresh: false,
  create: {
    isLoading: false,
    success: false,
    error: "",
  },
  update: {
    item: {},
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  status: {
    isLoading: false,
    error: "",
  },
  banned: {
    isLoading: false,
    error: "",
  },
  note: {
    dialog: false,
  },
};

const actions = {
  [FILTER_CRYPTO_ACCOUNTS]: async ({ commit }, queryItems) => {
    try {
      commit(SET_IS_LOADING, true);

      let result = await AdminApi.filterCryptoAccounts(queryItems);

      commit(SET_CRYPTO_ACCOUNTS, result.rows);
      commit(SET_CRYPTO_ACCOUNTS_COUNT, result.count);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [CREATE_CRYPTO_ACCOUNT]: async ({ commit, dispatch }, account) => {
    try {
      commit(SET_CREATE_IS_LOADING, true);

      await AdminApi.createCryptoAccount(account);

      commit(SET_CREATE_SUCCESS, true);
      dispatch(FILTER_CRYPTO_ACCOUNTS, {});
    } catch (error) {
      commit(
        SET_CREATE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_CREATE_IS_LOADING, false);
    }
  },
  [UPDATE_CRYPTO_ACCOUNT]: async ({ commit, dispatch }, { id, account }) => {
    try {
      commit(SET_UPDATE_IS_LOADING, true);

      await AdminApi.updateCryptoAccount(id, account);

      commit(SET_UPDATE_SUCCESS, true);
      dispatch(FILTER_CRYPTO_ACCOUNTS, {});
    } catch (error) {
      commit(
        SET_UPDATE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_UPDATE_IS_LOADING, false);
    }
  },
  [CHANGE_CRYPTO_ACCOUNT_STATUS]: async ({ commit, dispatch }, id) => {
    try {
      commit(SET_STATUS_IS_LOADING, true);

      await AdminApi.changeCryptoAccountStatus(id);

      dispatch(FILTER_CRYPTO_ACCOUNTS, {});
    } catch (error) {
      commit(
        SET_STATUS_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_STATUS_IS_LOADING, false);
    }
  },
  [BANNED_CRYPTO_ACCOUNT]: async ({ commit, dispatch }, id) => {
    try {
      commit(SET_BANNED_IS_LOADING, true);

      await AdminApi.bannedCryptoAccount(id);

      dispatch(FILTER_CRYPTO_ACCOUNTS, {});
    } catch (error) {
      commit(
        SET_BANNED_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_BANNED_IS_LOADING, false);
    }
  },
  [BAN_ROLLBACK_CRYPTO_ACCOUNT]: async ({ commit }, id) => {
    try {
      commit(SET_BANNED_IS_LOADING, true);

      await AdminApi.banRollbackCryptoAccount(id);

      commit(SET_CRYPTO_REFRESH, {});
    } catch (error) {
      commit(
        SET_BANNED_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_BANNED_IS_LOADING, false);
    }
  },
  [UPDATE_CRYPTO_ACCOUNT_NOTE]: async ({ commit, dispatch }, { id, note }) => {
    try {
      commit(SET_UPDATE_IS_LOADING, true);

      await AdminApi.updateCryptoAccountNote(id, note);

      commit(SET_UPDATE_SUCCESS, true);
      dispatch(FILTER_CRYPTO_ACCOUNTS, {});
    } catch (error) {
      commit(
        SET_UPDATE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_UPDATE_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_CRYPTO_ACCOUNTS]: (state, cryptoAccounts) => (state.cryptoAccounts = cryptoAccounts),
  [SET_CRYPTO_ACCOUNTS_COUNT]: (state, totalCount) => (state.totalCount = totalCount),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),

  [TOGGLE_CREATE_DIALOG]: (state) => {
    state.create.isLoading = false;
    state.create.success = false;
    state.create.error = "";
  },

  [SET_CREATE_IS_LOADING]: (state, isLoading) => {
    state.create.isLoading = isLoading;

    if (isLoading) {
      state.create.success = false;
      state.create.error = "";
    }
  },

  [SET_CREATE_SUCCESS]: (state) => (state.create.success = true),
  [SET_CREATE_ERROR]: (state, error) => (state.create.error = error),
  [SET_CRYPTO_REFRESH]: (state, refresh) => (state.refresh = refresh),

  [TOGGLE_UPDATE_DIALOG]: (state, item = {}) => {
    state.update.item = item;
    state.update.dialog = !state.update.dialog;
    state.update.isLoading = false;
    state.update.success = false;
    state.update.error = "";
  },

  [SET_UPDATE_IS_LOADING]: (state, isLoading) => {
    state.update.isLoading = isLoading;

    if (isLoading) {
      state.update.success = false;
      state.update.error = "";
    }
  },

  [SET_UPDATE_SUCCESS]: (state) => (state.update.success = true),
  [SET_UPDATE_ERROR]: (state, error) => (state.update.error = error),

  [SET_STATUS_IS_LOADING]: (state, isLoading) => (state.status.isLoading = isLoading),
  [SET_STATUS_ERROR]: (state, error) => (state.status.error = error),

  [SET_BANNED_IS_LOADING]: (state, isLoading) => (state.banned.isLoading = isLoading),
  [SET_BANNED_ERROR]: (state, error) => (state.banned.error = error),

  [TOGGLE_NOTE_DIALOG]: (state, item = {}) => {
    state.update.item = item;
    state.note.dialog = !state.note.dialog;
    state.update.isLoading = false;
    state.update.success = false;
    state.update.error = "";
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
