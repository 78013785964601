import AdminApi from "../../api/adminApi";

export const FETCH_PAYMENT_TYPES = "FETCH_PAYMENT_TYPES";

export const SET_PAYMENT_TYPES = "SET_PAYMENT_TYPES";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";

const state = {
  paymentTypes: [],
  isLoading: false,
  error: "",
};

const actions = {
  [FETCH_PAYMENT_TYPES]: async ({ commit, state }) => {
    try {
      if (state.paymentTypes.length > 0) return;

      commit(SET_IS_LOADING, true);

      let paymentTypes = await AdminApi.getPaymentTypes();
      commit(SET_PAYMENT_TYPES, paymentTypes);
      return paymentTypes;
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_PAYMENT_TYPES]: (state, paymentTypes) => (state.paymentTypes = paymentTypes),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
