<template>
  <div>
    <v-alert type="info" icon="mdi-information-outline">Arşiv ekranlarında Listele butonuna basarak kayıtları görüntüleyebilirsiniz.</v-alert>
    <v-row>
      <v-col class="gray-border">
        <v-tabs v-model="tab">
          <v-tab v-for="item in paymentTypes" :key="item.name">
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in paymentTypes" :key="item.name">
            <component v-bind:is="item.name" :selectedstatus="status"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PaparaWithdraws from "./papara/PaparaWithdrawArchives";
import BankWithdraws from "./bank/BankWithdrawArchives";
import { mapState, mapActions } from "vuex";
import { FETCH_PAYMENT_TYPES } from "../../../store/modules/paymentType";
import { FETCH_PAYMENT_STATUSES } from "../../../store/modules/paymentStatus";
import { FILTER_PAPARA_WITHDRAW_ARCHIVES } from "../../../store/modules/withdraw/paparaWithdraw";
import { FILTER_BANK_WITHDRAW_ARCHIVES } from "../../../store/modules/withdraw/bankWithdraw";

export default {
  data() {
    return {
      tab: null,
      status: "",
    };
  },
  computed: {
    ...mapState("paymentType", {
      paymentTypes: (state) => state.paymentTypes.filter((a) => a.name != "Kripto"),
    }),
  },
  components: {
    Papara: PaparaWithdraws,
    Havale: BankWithdraws,
  },
  watch: {
    async tab() {
      if (this.paymentTypes.length === 0) {
        await this.fetchPaymentTypes();
      }

      // switch (this.paymentTypes[newVal].name) {
      //   case "Papara":
      //     this.filterPaparaWithdraws({ status: this.status });
      //     break;
      //   case "Havale":
      //     this.filterBankWithdraws({ status: this.status });
      //     break;
      // }
    },
  },
  created() {
    this.fetchPaymentStatuses();
    this.fetchPaymentTypes();

    if (this.$route.query.status) {
      this.status = [this.$route.query.status];
    }

    if (this.$route.query.index) {
      this.tab = parseInt(this.$route.query.index, 10);
    }
  },
  methods: {
    ...mapActions("paymentType", {
      fetchPaymentTypes: FETCH_PAYMENT_TYPES,
    }),
    ...mapActions("paymentStatus", {
      fetchPaymentStatuses: FETCH_PAYMENT_STATUSES,
    }),
    ...mapActions("paparaWithdraw", {
      filterPaparaWithdraws: FILTER_PAPARA_WITHDRAW_ARCHIVES,
    }),
    ...mapActions("bankWithdraw", {
      filterBankWithdraws: FILTER_BANK_WITHDRAW_ARCHIVES,
    }),
  },
};
</script>
