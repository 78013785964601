<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-form ref="form" :lazy-validation="false">
        <v-card-title>
          <span class="headline">Ödemeyi Onayla</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">
          <span v-html="error" />
        </v-alert>
        <v-alert v-show="success" text prominent type="success" icon="mdi-cloud-check">Ödeme Onaylandı</v-alert>
        <v-card-text>
          <v-container v-if="!success && selectedItem">
            <p>Aşağıdaki ödemeyi onaylamak istediğinize emin misiniz?</p>
            <v-card elevation="2">
              <v-simple-table dense style="border:1px solid #ccc">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-left">Müşteri Adı Soyadı</td>
                      <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                      <td class="font-weight-bold">{{ selectedItem.customer.name }} {{ selectedItem.customer.surname }}</td>
                    </tr>
                    <tr>
                      <td class="text-left">Alıcı Hesap</td>
                      <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                      <td class="font-weight-bold">
                        <div class="green--text font-weight-bold pt-1 pb-1">{{ selectedItem.bank.name }} - {{ selectedItem.bankAccount.name }}</div>
                        <div class="font-weight-normal pb-1" style="width:240px;">{{ selectedItem.bankAccount.iban | iban }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">Tutar</td>
                      <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                      <td class="green--text font-weight-bold" style="font-size:16px">{{ selectedItem.amount }} <small>TL</small></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-container>
          <v-btn v-if="lastPaymentsError" outlined class="pl-4 pr-4 ml-2" color="grey" text @click="lastPayments">Yeniden dene</v-btn>
          <v-alert v-if="lastPaymentsError" text prominent type="error" icon="mdi-alert-circle">
            <span v-html="lastPaymentsError" />
          </v-alert>
          <v-skeleton-loader class="mx-auto" type="table-heading, list-item-two-line" v-if="loadingPrevRequest"></v-skeleton-loader>
          <v-alert v-if="!loadingPrevRequest && !lastPaymentsError && previousPayments.length === 0" class="mt-4 mb-0" type="info" style="font-size:14px" transition="fab-transition">
            Üyenin ilk işlemidir. Daha eski işlemi bulunmamaktadır.
          </v-alert>
          <v-container v-if="!loadingPrevRequest && previousPayments.length > 0">
            <div style="color: #fbb203" class="pa-2 text-left"><strong>SON 3 İŞLEMİ</strong></div>
            <v-divider></v-divider>
            <v-card class="mx-auto" elevation="0">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #eee">
                      <th class="text-left">Tarih / ID</th>
                      <th class="text-left">Tutar</th>
                      <th class="text-left">Durum</th>
                      <th class="text-left">İşlem Bilgileri</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in previousPayments" :key="index">
                      <td class="text-left">
                        <small
                          >{{ toDate(item.createdAt) }}
                          <div class="grey--text">{{ item.customer.loginId.indexOf("-") !== -1 ? item.customer.loginId : `${item.customer.loginId}-${item.transactionId}` }}</div>
                        </small>
                      </td>
                      <td class="text-left" style="width: 100px">
                        <small>{{ item.amount }} TL</small>
                      </td>
                      <td class="text-left">
                        <v-chip small :color="item.paymentStatus.name === 'Onay Bekliyor' ? '#fcab13' : item.paymentStatus.name === 'Onaylandı' ? 'green' : 'red'" text-color="white">
                          {{ item.paymentStatus.name }}
                        </v-chip>
                      </td>
                      <td class="text-left">
                        <small
                          >{{ item.approved ? toDate(item.approvedAt) : item.rejected ? toDate(item.rejectedAt) : "" }}
                          <div class="grey--text">{{ item.approved ? item.approved.name : item.rejected ? item.rejected.name : "" }}</div></small
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-container>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-show="!success" color="success" class="pl-10 pr-10" @click="approvePayment(selectedItem.id)">Onayla</v-btn>
          <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="toggleDialog">{{ success ? "Kapat" : "İptal" }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { APPROVE_BANK_PAYMENT, TOGGLE_APPROVE_DIALOG, FILTER_BANK_LAST_PAYMENTS } from "../../../../store/modules/payment/bankPayment";
import IBAN from "iban";
import moment from "moment";

export default {
  computed: {
    ...mapState("bankPayment", {
      selectedItem: (state) => state.approve.item,
      dialog: (state) => state.approve.dialog,
      loading: (state) => state.approve.isLoading,
      success: (state) => state.approve.success,
      error: (state) => state.approve.error,
      previousPayments: (state) => state.lastPayments.payments,
      loadingPrevRequest: (state) => state.lastPayments.isLoading,
      lastPaymentsError: (state) => state.lastPayments.error,
    }),
  },
  watch: {
    dialog(val) {
      if (val) {
        this.filterBankLastPayments({ loginId: this.selectedItem.customer.loginId });
      }
    },
  },
  methods: {
    ...mapActions("bankPayment", {
      approvePayment: APPROVE_BANK_PAYMENT,
      filterBankLastPayments: FILTER_BANK_LAST_PAYMENTS,
    }),
    ...mapMutations("bankPayment", {
      toggleDialog: TOGGLE_APPROVE_DIALOG,
    }),
    toDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
    lastPayments() {
      this.filterBankLastPayments({ loginId: this.selectedItem.customer.loginId });
    },
  },
  filters: {
    iban: function(iban) {
      return IBAN.printFormat(`TR${iban}`, " ");
    },
  },
};
</script>
