<template>
  <v-dialog v-model="dialog" scrollable max-width="1000px" persistent>
    <v-skeleton-loader class="mx-auto" type="card, list-item" v-if="loading && details.length == 0"></v-skeleton-loader>
    <v-card v-else>
      <v-card-title>
        <span class="headline">Görev Detayı</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 450px;">
        <v-skeleton-loader class="mx-auto" type="table-heading, table-tbody, table-tfoot" v-if="details.length == 0 && loading"></v-skeleton-loader>
        <v-data-table :headers="headers" :items="details" class="elevation-0" hide-default-footer disable-pagination v-else>
          <template v-slot:item.processDate="{ item }">
            <small>{{ item.processDate | date }}</small>
          </template>
          <template v-slot:item.processStart="{ item }">
            <small>{{ item.processStart | moment }}</small>
          </template>
          <template v-slot:item.processEnd="{ item }">
            <small>{{ item.processEnd | moment }}</small>
          </template>
          <template v-slot:item.isError="{ item }">
            <v-tooltip bottom :disabled="!item.isError">
              <template v-slot:activator="{ on }">
                <v-chip small :color="item.isError ? 'danger' : 'green'" dark v-on="on">
                  {{ item.isError ? "Hata Var" : "Hata Yok" }}
                </v-chip>
              </template>
              <span>{{ item.errorMessage }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="toggleDialog">Kapat</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { TOGGLE_DETAIL_DIALOG, FETCH_JOB_DETAILS } from "../../../store/modules/job";
import moment from "moment";

export default {
  data: () => ({
    headers: [
      { text: "İşlem Tarihi", sortable: false, value: "processDate" },
      { text: "İşlem Başlama", sortable: false, value: "processStart" },
      { text: "İşlem Bitiş", sortable: false, value: "processEnd" },
      { text: "Arşivlenecek", sortable: false, align: "center", value: "toBeArchivedCount" },
      { text: "Arşivlenen", sortable: false, align: "center", value: "archivedCount" },
      { text: "Silinen", sortable: false, align: "center", value: "deletedCount" },
      { text: "Hata", sortable: false, value: "isError" },
    ],
  }),
  computed: {
    ...mapState("job", {
      id: (state) => state.detail.id,
      dialog: (state) => state.detail.dialog,
      details: (state) => state.detail.details,
      loading: (state) => state.detail.isLoading,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.fetchDetails(this.id);
      }
    },
  },
  methods: {
    ...mapActions("job", {
      fetchDetails: FETCH_JOB_DETAILS,
    }),
    ...mapMutations("job", {
      toggleDialog: TOGGLE_DETAIL_DIALOG,
    }),
  },
  filters: {
    moment: function(date) {
      if (!date) {
        return "";
      }

      return moment(date).format("DD.MM.YYYY - HH:mm:ss");
    },
    date: function(date) {
      if (!date) {
        return "";
      }

      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>
