<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-col>
            <span class="headline">Hesabı Güncelle</span>
          </v-col>
          <v-col cols="4" sm="6" md="4" v-if="isAdmin">
            <crypto-dialog />
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <v-form ref="form">
          <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
          <v-alert v-show="success" color="primary" type="success" icon="mdi-cloud-check">Hesap Güncellendi</v-alert>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="item.cryptoId"
                  :rules="cryptoIdRules"
                  :items="cryptos"
                  item-text="name"
                  item-value="id"
                  dense
                  :readonly="!isAdmin"
                  label="Kripto Seçiniz"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="item.number" label="Cüzdan No" required :readonly="!isAdmin" :rules="ibanRules" maxlength="100" counter :disabled="success"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="item.destinationTag" label="Etiket" maxlength="200" counter :disabled="success"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" class="pl-10 pr-10" v-show="!success && !loading" @click="save">Kaydet</v-btn>
        <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="toggleDialog">{{ !success && !loading ? "İptal" : "Kapat" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { UPDATE_CRYPTO_ACCOUNT, TOGGLE_UPDATE_DIALOG } from "../../../../store/modules/account/cryptoAccount";
import { FETCH_CRYPTOS } from "../../../../store/modules/crypto";
import CryptoDialog from "../../crypto/CryptoDialog";

export default {
  data: () => ({
    id: "",
    item: {
      cryptoId: "",
      number: "",
      destinationTag: "",
    },
    cryptoIdRules: [(v) => !!v || "Lütfen kripto seçiniz"],
    ibanRules: [(v) => !!v || "Lütfen cüzdan numarasını giriniz"],
  }),
  computed: {
    ...mapState("cryptoAccount", {
      updateItem: (state) => state.update.item,
      dialog: (state) => state.update.dialog,
      loading: (state) => state.update.isLoading,
      error: (state) => state.update.error,
      success: (state) => state.update.success,
    }),
    ...mapState("crypto", {
      cryptos: (state) => state.cryptos.filter((item) => item.isActive),
    }),
    ...mapState("user", {
      isAdmin: (state) => state.user.role.isAdmin,
      isSupervisor: (state) => state.user.role.isSupervisor,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
        this.fetchCryptos();
      }
    },
    updateItem(newVal) {
      this.id = newVal.id;
      this.item = {
        number: newVal.number,
        cryptoId: newVal.crypto?.id,
        destinationTag: newVal.destinationTag,
      };
    },
  },
  components: {
    "crypto-dialog": CryptoDialog,
  },
  methods: {
    ...mapActions("cryptoAccount", {
      updateCryptoAccount: UPDATE_CRYPTO_ACCOUNT,
    }),
    ...mapMutations("cryptoAccount", {
      toggleUpdateDialog: TOGGLE_UPDATE_DIALOG,
    }),
    ...mapActions("crypto", {
      fetchCryptos: FETCH_CRYPTOS,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.updateCryptoAccount({ id: this.id, account: this.item });
    },
    toggleDialog() {
      this.toggleUpdateDialog();
    },
  },
};
</script>
