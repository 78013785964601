<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Ödeme Yöntemi Yetkileri</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="paymentTypeAuths"
          hide-default-footer
          :search="search"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-row>
                <v-col cols="10" sm="3" md="8" offset-md="2">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <new-payment-type-auth-dialog :roleId="roleId" />
              <delete-payment-type-auth-dialog />
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  color="error"
                  dark
                  @click="
                    toggleDialog({
                      paymentTypeId: item.paymentTypeId,
                      roleId: item.roleId,
                    })
                  "
                  v-on="on"
                >
                  <v-icon size="18">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Yetkiyi Sil</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="togglePaymentTypeAuthDialog()"
          >İptal</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  TOGGLE_PAYMENT_TYPE_AUTH_DIALOG,
  FETCH_PAYMENT_TYPE_AUTHS,
  TOGGLE_DELETE_DIALOG,
} from "../../../store/modules/paymentTypeAuth";
import NewPaymentTypeAuthDialog from "./NewPaymentTypeAuthDialog";
import DeletePaymentTypeAuthDialog from "./DeletePaymentTypeAuthDialog";

export default {
  data: () => ({
    search: "",
    headers: [
      {
        text: "Yetki",
        sortable: false,
        value: "role.name",
      },
      {
        text: "Sayfa",
        sortable: false,
        value: "paymentType.name",
      },
      {
        text: "İşlemler",
        sortable: false,
        value: "actions",
      },
    ],
  }),
  components: {
    "new-payment-type-auth-dialog": NewPaymentTypeAuthDialog,
    "delete-payment-type-auth-dialog": DeletePaymentTypeAuthDialog,
  },
  computed: {
    ...mapState("paymentTypeAuth", {
      dialog: (state) => state.dialog,
      roleId: (state) => state.roleId,
      paymentTypeAuths: (state) => state.paymentTypeAuths,
      isLoading: (state) => state.isLoading,
      error: (state) => state.error,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) this.fetchPaymentTypeAuths(this.roleId);
    },
  },
  methods: {
    ...mapActions("paymentTypeAuth", {
      fetchPaymentTypeAuths: FETCH_PAYMENT_TYPE_AUTHS,
    }),
    ...mapMutations("paymentTypeAuth", {
      toggleDialog: TOGGLE_DELETE_DIALOG,
      togglePaymentTypeAuthDialog: TOGGLE_PAYMENT_TYPE_AUTH_DIALOG,
    }),
  },
};
</script>
