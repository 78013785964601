<template>
  <div class="text-center">
    <v-divider />
    <v-btn depressed small class="mt-5 mb-5" center v-on:click="redirect"><v-icon class="mr-2" small>mdi-arrow-left</v-icon>SİTEYE GERİ DÖN</v-btn>
  </div>
</template>
<script>
import config from "../../config";

export default {
  methods: {
    redirect() {
      window.location = config.SITE_URL;
    },
  },
};
</script>
