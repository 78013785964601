const { SET_COMPONENT, SET_DATA } = require("../../store/modules/payment/payment");
import config from "../../config";

class Socket {
  constructor(tokenId, vue) {
    this.tokenId = tokenId;
    this.vue = vue;

    const io = require("socket.io-client");
    this.socket = io(config.SERVER_URL, {
      path: `${config.API_URL_PATH}/socket.io`,
    });

    this.onConnect();
    this.onUpdatePayment();
  }

  onConnect() {
    this.socket.on("connect", () => {
      console.log("connect");
      this.socket.emit("joinPayment", this.tokenId, (joined) => !joined);
    });
  }

  onUpdatePayment() {
    this.socket.on("update_payment", (payment) => {
      this.vue.$store.commit(`payment/${SET_COMPONENT}`, `${payment.paymentType}-${payment.step}`, { root: true });
      this.vue.$store.commit(`payment/${SET_DATA}`, payment, { root: true });
    });
  }
}

export default Socket;
