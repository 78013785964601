<template>
  <div>
    <v-alert v-model="isPageError" dismissible text prominent type="error" icon="mdi-alert-circle">{{ pageError }}</v-alert>
    <v-skeleton-loader class="mx-auto" type="table-heading, table-tbody, table-tfoot" v-if="isLoading"></v-skeleton-loader>
    <v-data-table :headers="headers" :items="payments" :options.sync="options" :server-items-length="totalCount" class="elevation-0" :footer-props="footerSettings" v-else>
      <template v-slot:top>
        <v-container class="filter-area" fluid>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                v-model="filters.status"
                :items="paymentStatuses"
                clearable
                dense
                multiple
                outlined
                item-text="name"
                item-value="id"
                label="Durum Seçiniz"
                background-color="white"
                v-on:change="filter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete v-model="filters.year" :items="years" clearable dense multiple outlined label="Yıl Seçiniz" background-color="white" v-on:change="filter"></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-datetime-picker label="Başlangıç Tarihi" v-model="filters.startDate" :text-field-props="{ appendIcon: 'mdi-calendar-today' }"
                ><template slot="dateIcon">
                  <v-icon>mdi-calendar-blank-multiple</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-time-four-outline</v-icon>
                </template></v-datetime-picker
              >
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-datetime-picker label="Bitiş Tarihi" v-model="filters.endDate" :text-field-props="{ appendIcon: 'mdi-calendar-today' }"
                ><template slot="dateIcon">
                  <v-icon>mdi-calendar-blank-multiple</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-time-four-outline</v-icon>
                </template></v-datetime-picker
              >
            </v-col>
            <v-col cols="12" xs="6" sm="4" md="4">
              <v-text-field v-model="filters.name" label="Ad" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="4" md="4">
              <v-text-field v-model="filters.surname" label="Soyad" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="4" md="4">
              <v-text-field v-model="filters.loginId" label="User ID - Payment ID" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12" sm="6" md="12">
              <downloadexcel
                class="v-btn theme--light v-size--default v-btn--is-elevated white"
                :fetch="exportToExcel"
                :before-generate="startDownload"
                :before-finish="finishDownload"
                :fields="excelFields"
                worksheet="Rapor"
                name="Papara_Arsiv_Odemeleri.xls"
                inline-block
                style="width: 140px"
                v-if="excelAuth"
              >
                <span class="v-btn__content" v-if="!startDownloadParam">
                  <v-icon color="success" class="mr-2" small>
                    mdi-microsoft-excel
                  </v-icon>
                  Rapor Al
                </span>
                <span class="v-btn__load`er" v-else
                  ><div
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="v-progress-circular v-progress-circular--visible v-progress-circular--indeterminate"
                    style="height: 23px; width: 23px;"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="21.904761904761905 21.904761904761905 43.80952380952381 43.80952380952381" style="transform: rotate(0deg);">
                      <circle
                        fill="transparent"
                        cx="43.80952380952381"
                        cy="43.80952380952381"
                        r="20"
                        stroke-width="3.8095238095238093"
                        stroke-dasharray="125.664"
                        stroke-dashoffset="125.66370614359172px"
                        class="v-progress-circular__overlay"
                      ></circle>
                    </svg>
                    <div class="v-progress-circular__info"></div></div
                ></span>
              </downloadexcel>
              <v-btn class="float-right" @click="reset" inline-block outlined> Reset</v-btn>
              <v-btn color="primary" class="mr-2 float-right" @click="filter" inline-block> Listele</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <papara-payment-archive-history-dialog />
      </template>
      <template v-slot:item.loginId="{ item }"> {{ item.customerLoginId.indexOf("-") !== -1 ? item.customerLoginId : `${item.customerLoginId}-${item.transactionId}` }}</template>
      <template v-slot:item.amount="{ item }">
        <div class="text-right primary--text font-weight-bold">{{ item.amount }} <small class="text-muted">TL</small></div>
      </template>
      <template v-slot:item.customerInfo="{ item }">
        {{ `${item.customerName} ${item.customerSurname}` }}
        <div class="grey--text">{{ item.customerEmail }}</div>
      </template>
      <template v-slot:item.account="{ item }">
        <div class="green--text font-weight-bold pb-1 pt-1">
          {{ item.paparaAccountName }}
        </div>
        <div class="font-weight-normal pb-1">
          <v-icon x-small color="light" class="pr-1">mdi-checkbook</v-icon>
          {{ item.paparaAccountNumber }}
        </div>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip small :color="item.paymentStatusName === 'Onay Bekliyor' ? '#fcab13' : item.paymentStatusName === 'Onaylandı' ? 'green' : 'red'" text-color="white">
          {{ item.paymentStatusName }}
        </v-chip>
      </template>
      <template v-slot:item.transactions="{ item }"
        >{{ item.approved ? toDate(item.approvedAt) : item.rejected ? toDate(item.rejectedAt) : "" }}
        <br />
        <div class="grey--text">
          {{ item.approved ? item.approved : item.rejected ? item.rejected : "" }}
        </div>
        <div class="grey--text" v-if="historyAuth && item.paymentStatusName !== 'Onay Bekliyor'">
          <v-btn class="ma-1 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--x-small" x-small @click="() => toggleHistoryDialog(item.id)">
            <v-icon class="mr-1" size="12">mdi-information</v-icon> DETAY
          </v-btn>
        </div>
      </template>
      <template v-slot:item.url="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :href="`/${item.tokenId}`" target="blank" v-on="on" x-small>
              <v-icon size="18">mdi-link</v-icon>
            </v-btn>
          </template>
          <span>{{ item.tokenId }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { FILTER_PAPARA_PAYMENT_ARCHIVES, TOGGLE_ARCHIVE_HISTORY_DIALOG } from "../../../../store/modules/payment/paparaPayment";
import moment from "moment";
import AdminApi from "../../../../api/adminApi";
import dateUtil from "../../../../utils/date";
import downloadexcel from "vue-json-excel";
import PaparaPaymentArchiveHistoryDialog from "./PaparaPaymentArchiveHistoryDialog.vue";

export default {
  props: ["selectedstatus"],
  data: () => ({
    isPageError: false,
    pageError: "",
    options: { itemsPerPage: 10, page: 1 },
    isHidden: null,
    filters: { year: "", startDate: "", endDate: "", status: "", name: "", surname: "", loginId: "" },
    years: [],
    headers: [
      { text: "User Id", sortable: false, value: "loginId" },
      { text: "Müşteri Bilgileri", sortable: false, value: "customerInfo" },
      { text: "Tutar", sortable: false, value: "amount" },
      { text: "Alıcı Hesap", sortable: false, value: "account" },
      { text: "Tarih", sortable: false, value: "createdAt" },
      { text: "Durum", sortable: false, value: "status", align: "center" },
      { text: "İşlem Bilgileri", sortable: false, value: "transactions", align: "center" },
      { text: "Url", sortable: false, value: "url" },
    ],
    footerSettings: {
      itemsPerPageOptions: [10],
    },
    excelFields: {
      "Müşteri Id": "customerLoginId",
      "Müşteri Adı": "customerNameSurname",
      "Müşteri E-mail": "customerEmail",
      "Yatırılan Tutar(TL)": "amount",
      "Alıcı Hesap Adı": "paparaAccountName",
      "Alıcı Hesap No": "paparaAccountNumber",
      "Talep Tarihi": "createdAt",
      "Cevap Süresi(dk)": "responseTime",
      Durum: "paymentStatusName",
      "Onay Tarihi": "approvedAt",
      "Onay Veren": "approvedBy",
      "Ret Tarihi": "rejectedAt",
      "Ret Veren": "rejectedBy",
      "Ret Açıklaması": "description",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    startDownloadParam: false,
  }),
  components: {
    "papara-payment-archive-history-dialog": PaparaPaymentArchiveHistoryDialog,
    downloadexcel,
  },
  computed: {
    ...mapState("paparaPayment", {
      payments: (state) => state.paparaPaymentArchives,
      totalCount: (state) => state.archiveTotalCount,
      isLoading: (state) => state.isLoading,
      error: (state) => state.error,
      refresh: (state) => state.refresh,
    }),
    ...mapState("paymentStatus", {
      paymentStatuses: (state) => state.paymentStatuses,
    }),
    ...mapState("user", {
      historyAuth: (state) => state.user.role.auth.some((a) => a.code == "bph"),
      excelAuth: (state) => state.user.role.isAdmin || state.user.auth.some((a) => a.code == "ppee"),
    }),
    status() {
      return this.filters.status;
    },
  },
  watch: {
    status(newValue, oldValue) {
      if (newValue.length === 0 && newValue.length !== oldValue.length) {
        this.filterAccounts({ ...this.options, ...this.filters });
      }
    },
    options(oldVal, newVal) {
      if (oldVal.itemsPerPage !== newVal.itemsPerPage || oldVal.page !== newVal.page) this.filter();
    },
    refresh(value) {
      if (value) {
        this.setRefresh(false);
        this.filterAccounts({ ...this.options, ...this.filters });
      }
    },
    pageError(value) {
      this.isPageError = value != "";
    },
  },
  created() {
    if (this.selectedstatus) this.filters.status = this.selectedstatus;

    this.getYears();
  },
  methods: {
    ...mapActions("paparaPayment", {
      filterAccounts: FILTER_PAPARA_PAYMENT_ARCHIVES,
    }),
    ...mapMutations("paparaPayment", {
      toggleHistoryDialog: TOGGLE_ARCHIVE_HISTORY_DIALOG,
    }),
    filter() {
      this.pageError = "";

      this.filterAccounts({ ...this.options, ...this.filters });
    },
    reset() {
      this.pageError = "";

      this.filters = { year: "", startDate: "", endDate: "", status: "", name: "", surname: "", loginId: "" };

      this.filter();
    },
    toDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
    getYears() {
      for (let i = 2021; i <= new Date().getFullYear(); i++) {
        this.years.push(i);
      }
    },
    async exportToExcel() {
      this.pageError = "";

      try {
        let result = await AdminApi.exportPaparaPaymentArchives({
          ...this.options,
          ...this.filters,
          itemsPerPage: -1,
          page: -1,
        });

        if (result.rows.length == 0) {
          this.pageError = "Excel'e aktarılacak veri bulunamadı filtre alanını kontrol ediniz";
          this.startDownloadParam = false;
          return;
        }

        return result.rows.map((item) => {
          return {
            ...item,
            customerLoginId: item.customerLoginId.indexOf("-") !== -1 ? item.customerLoginId : `${item.customerLoginId}-${item.transactionId}`,
            customerNameSurname: `${item.customerName} ${item.customerSurname}`,
            responseTime: item.approved || item.rejected ? `${dateUtil.getMinutes(item.approvedAt || item.rejectedAt, item.createdAt)}` : "",
            createdAt: this.toDate(item.createdAt),
            approvedAt: item.approved ? this.toDate(item.approvedAt) : "",
            approvedBy: item.approved,
            rejectedAt: item.rejected ? this.toDate(item.rejectedAt) : "",
            rejectedBy: item.rejected,
            description: item.description ? item.description : item.rejectDescription,
          };
        });
      } catch (error) {
        this.pageError = error;
        this.startDownloadParam = false;
      }
    },
    startDownload() {
      this.startDownloadParam = true;
    },
    finishDownload() {
      setTimeout(() => (this.startDownloadParam = false), 1200);
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
  },
};
</script>
