var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"700px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.loading && _vm.history.length == 0)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card, list-item"}}):_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("İşlem Geçmişi")])]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"450px"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.history,"hide-default-footer":"","disable-pagination":"","no-data-text":"Hata alan işlem bulunmamaktadır"},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt))+" ")]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ma-2",attrs:{"text-color":"white","color":item.fundistIsError ? 'red' : 'green'}},on),[_vm._v(" "+_vm._s(item.fundistIsError ? "İşlem Hata Aldı" : "İşlem Başarıyla Tamamlandı")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.fundistResultJson))])])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.created)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","outlined":"","small":"","elevation":"2"},on:{"click":function () { return _vm.copyContent(item.fundistResultJson); }}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-floppy")])],1)]}}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"pl-4 pr-4 ml-2",attrs:{"outlined":"","color":"grey","text":""},on:{"click":_vm.toggleDialog}},[_vm._v(_vm._s(!_vm.success && !_vm.loading ? "Kapat" : "Kapat"))])],1)],1),_c('v-snackbar',{attrs:{"timeout":"2000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"cyellow","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Kapat ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Sonuç kopyalandı ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }