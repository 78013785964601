<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form" :lazy-validation="false">
        <v-card-title>
          <span class="headline">Ödemeyi Onayla</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">
          <span v-html="error" />
        </v-alert>
        <v-alert v-show="success" text prominent type="success" icon="mdi-cloud-check">Ödeme Onaylandı</v-alert>
        <v-card-text>
          <v-container v-if="!success && selectedItem">
            <p>Aşağıdaki ödemeyi onaylamak istediğinize emin misiniz?</p>
            <v-simple-table style="border:1px solid #ccc">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="text-left">Müşteri</td>
                    <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                    <td class="font-weight-bold">{{ selectedItem.customer.name }} {{ selectedItem.customer.surname }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Papara No</td>
                    <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                    <td class="font-weight-bold">
                      <div class="green--text font-weight-bold pt-1 pb-1">{{ selectedItem.paparaAccount.name }}</div>
                      <div class="font-weight-normal pb-1" style="width:240px;"><v-icon x-small color="light" class="pr-1">mdi-checkbook</v-icon> {{ selectedItem.paparaAccount.number }}</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Tutar</td>
                    <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                    <td class="green--text font-weight-bold" style="font-size:16px">{{ selectedItem.amount }} <small>TL</small></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-show="!success" color="success" class="pl-10 pr-10" @click="approvePayment(selectedItem.id)">Onayla</v-btn>
          <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="toggleDialog">{{ success ? "Kapat" : "İptal" }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { APPROVE_PAPARA_PAYMENT, TOGGLE_APPROVE_DIALOG } from "../../../../store/modules/payment/paparaPayment";

export default {
  computed: {
    ...mapState("paparaPayment", {
      selectedItem: (state) => state.approve.item,
      dialog: (state) => state.approve.dialog,
      loading: (state) => state.approve.isLoading,
      success: (state) => state.approve.success,
      error: (state) => state.approve.error,
    }),
  },
  methods: {
    ...mapActions("paparaPayment", {
      approvePayment: APPROVE_PAPARA_PAYMENT,
    }),
    ...mapMutations("paparaPayment", {
      toggleDialog: TOGGLE_APPROVE_DIALOG,
    }),
  },
};
</script>
