import { getToken } from "../../auth";
import config from "../../config";

const io = require("socket.io-client");
const socket = io(config.SERVER_URL, {
  path: `${config.API_URL_PATH}/socket.io`,
});

import { ADD_PAPARA_PAYMENT, REPLACE_PAPARA_PAYMENT } from "../../store/modules/payment/paparaPayment";
import { ADD_PAPARA_WITHDRAW, REPLACE_PAPARA_WITHDRAW } from "../../store/modules/withdraw/paparaWithdraw";
import { ADD_BANK_PAYMENT, REPLACE_BANK_PAYMENT } from "../../store/modules/payment/bankPayment";
import { ADD_BANK_WITHDRAW, REPLACE_BANK_WITHDRAW } from "../../store/modules/withdraw/bankWithdraw";
import { ADD_CRYPTO_PAYMENT, REPLACE_CRYPTO_PAYMENT } from "../../store/modules/payment/cryptoPayment";
import { ADD_CRYPTO_WITHDRAW, REPLACE_CRYPTO_WITHDRAW } from "../../store/modules/withdraw/cryptoWithdraw";
import { ADD_PAYMENT_COUNTS, CHANGE_PAYMENT_COUNTS } from "../../store/modules/payment/payment";
import { ADD_WITHDRAW_COUNTS, CHANGE_WITHDRAW_COUNTS } from "../../store/modules/withdraw/withdraw";
import { SET_HAS_NEW_PAYMENT, SET_HAS_NEW_WITHDRAW } from "../../store/modules/global";

import Vue from "../../index";

socket.on("connect", () => {
  console.log("connect");
  socket.emit("joinAdmin", getToken(), (joined) => !joined);
});

socket.on("reconnect_attempt", () => {
  console.log("reconnect_attempt");
});

socket.on("reconnect", () => {
  console.log("reconnect");
  socket.emit("joinAdmin", getToken(), (joined) => !joined);
});

socket.on("connect_error", () => {
  console.log("connect_error");

  setTimeout(() => {
    socket.connect();
  }, 1000);
});

socket.on("disconnect", (reason) => {
  console.log("disconnect", reason);
});

socket.on("update_papara_payments", (data) => {
  Vue.$store.commit(`paparaPayment/${ADD_PAPARA_PAYMENT}`, data, { root: true });
  Vue.$store.commit(`payment/${ADD_PAYMENT_COUNTS}`, "Papara", { root: true });
  Vue.$store.commit(`global/${SET_HAS_NEW_PAYMENT}`, { hasNewPayment: true, paymentType: "Papara" }, { root: true });
});

socket.on("refresh_papara_payments", (data) => {
  Vue.$store.commit(`paparaPayment/${REPLACE_PAPARA_PAYMENT}`, data, { root: true });
  Vue.$store.commit(`payment/${CHANGE_PAYMENT_COUNTS}`, { paymentType: "Papara", status: data.paymentStatus.name }, { root: true });
});

socket.on("update_papara_withdraws", (data) => {
  Vue.$store.commit(`paparaWithdraw/${ADD_PAPARA_WITHDRAW}`, data, { root: true });
  Vue.$store.commit(`withdraw/${ADD_WITHDRAW_COUNTS}`, "Papara", { root: true });
  Vue.$store.commit(`global/${SET_HAS_NEW_WITHDRAW}`, { hasNewWithdraw: true, paymentType: "Papara" }, { root: true });
});

socket.on("refresh_papara_withdraws", (data) => {
  Vue.$store.commit(`paparaWithdraw/${REPLACE_PAPARA_WITHDRAW}`, data, { root: true });
  Vue.$store.commit(`withdraw/${CHANGE_WITHDRAW_COUNTS}`, { paymentType: "Papara", status: data.withdrawStatus.name }, { root: true });
});

socket.on("update_bank_payments", (data) => {
  Vue.$store.commit(`bankPayment/${ADD_BANK_PAYMENT}`, data, { root: true });
  Vue.$store.commit(`payment/${ADD_PAYMENT_COUNTS}`, "Havale", { root: true });
  Vue.$store.commit(`global/${SET_HAS_NEW_PAYMENT}`, { hasNewPayment: true, paymentType: "Havale" }, { root: true });
});

socket.on("refresh_bank_payments", (data) => {
  Vue.$store.commit(`bankPayment/${REPLACE_BANK_PAYMENT}`, data, { root: true });
  Vue.$store.commit(`payment/${CHANGE_PAYMENT_COUNTS}`, { paymentType: "Havale", status: data.paymentStatus.name }, { root: true });
});

socket.on("update_bank_withdraws", (data) => {
  Vue.$store.commit(`bankWithdraw/${ADD_BANK_WITHDRAW}`, data, { root: true });
  Vue.$store.commit(`withdraw/${ADD_WITHDRAW_COUNTS}`, "Havale", { root: true });
  Vue.$store.commit(`global/${SET_HAS_NEW_WITHDRAW}`, { hasNewWithdraw: true, paymentType: "Havale" }, { root: true });
});

socket.on("refresh_bank_withdraws", (data) => {
  Vue.$store.commit(`bankWithdraw/${REPLACE_BANK_WITHDRAW}`, data, { root: true });
  Vue.$store.commit(`withdraw/${CHANGE_WITHDRAW_COUNTS}`, { paymentType: "Havale", status: data.withdrawStatus.name }, { root: true });
});

socket.on("update_crypto_payments", (data) => {
  Vue.$store.commit(`cryptoPayment/${ADD_CRYPTO_PAYMENT}`, data, { root: true });
  Vue.$store.commit(`payment/${ADD_PAYMENT_COUNTS}`, "Kripto", { root: true });
  Vue.$store.commit(`global/${SET_HAS_NEW_PAYMENT}`, { hasNewPayment: true, paymentType: "Kripto" }, { root: true });
});

socket.on("refresh_crypto_payments", (data) => {
  Vue.$store.commit(`cryptoPayment/${REPLACE_CRYPTO_PAYMENT}`, data, { root: true });
  Vue.$store.commit(`payment/${CHANGE_PAYMENT_COUNTS}`, { paymentType: "Kripto", status: data.paymentStatus.name }, { root: true });
});

socket.on("update_crypto_withdraws", (data) => {
  Vue.$store.commit(`cryptoWithdraw/${ADD_CRYPTO_WITHDRAW}`, data, { root: true });
  Vue.$store.commit(`withdraw/${ADD_WITHDRAW_COUNTS}`, "Kripto", { root: true });
  Vue.$store.commit(`global/${SET_HAS_NEW_WITHDRAW}`, { hasNewWithdraw: true, paymentType: "Kripto" }, { root: true });
});

socket.on("refresh_crypto_withdraws", (data) => {
  Vue.$store.commit(`cryptoWithdraw/${REPLACE_CRYPTO_WITHDRAW}`, data, { root: true });
  Vue.$store.commit(`withdraw/${CHANGE_WITHDRAW_COUNTS}`, { paymentType: "Kripto", status: data.withdrawStatus.name }, { root: true });
});

export default socket;
