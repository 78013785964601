<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" block depressed v-on="on" v-if="user.role.isAdmin">Yetkiler</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Yetkiler</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 450px;">
        <v-data-table :headers="headers" :items="roles" hide-default-footer :search="search" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-row>
                <v-col cols="10" sm="3" md="8" offset-md="2">
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                </v-col>
              </v-row>
              <new-role-dialog />
              <delete-role-dialog />
              <page-auth-dialog />
              <payment-type-auth-dialog />
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="width:180px">
              <v-tooltip bottom v-if="!item.isAdmin">
                <template v-slot:activator="{ on }">
                  <v-btn small color="error" dark @click="toggleDialog(item.id)" v-on="on">
                    <v-icon size="18">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Yetkiyi Sil</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!item.isAdmin">
                <template v-slot:activator="{ on }">
                  <v-btn small color="secondary" @click="togglePageAuthDialog(item.id)" v-on="on" class="mx-1">
                    <v-icon size="18">mdi-file-find</v-icon>
                  </v-btn>
                </template>
                <span>Sayfa Yetkileri</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!item.isAdmin">
                <template v-slot:activator="{ on }">
                  <v-btn small color="cyellow" dark @click="togglePaymentTypeAuthDialog(item.id)" v-on="on">
                    <v-icon size="18">mdi-credit-card-check</v-icon>
                  </v-btn>
                </template>
                <span>Ödeme Yöntemi Yetkileri</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="dialog = false">Kapat</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { FETCH_ROLES, TOGGLE_DELETE_DIALOG } from "../../../store/modules/role";
import { TOGGLE_PAGE_AUTH_DIALOG } from "../../../store/modules/pageAuth";
import { TOGGLE_PAYMENT_TYPE_AUTH_DIALOG } from "../../../store/modules/paymentTypeAuth";
import NewRoleDialog from "./NewRoleDialog";
import DeleteRoleDialog from "./DeleteRoleDialog";
import PageAuthDialog from "../auth/PageAuthDialog";
import PaymentTypeAuthDialog from "../auth/PaymentTypeAuthDialog";

export default {
  data: () => ({
    roleId: "",
    dialog: false,
    search: "",
    headers: [
      {
        text: "Yetki",
        sortable: false,
        value: "name",
      },
      {
        text: "Tam Yetki",
        sortable: false,
        value: "fullAuth",
      },
      {
        text: "İşlemler",
        sortable: false,
        value: "actions",
      },
    ],
  }),
  components: {
    "new-role-dialog": NewRoleDialog,
    "delete-role-dialog": DeleteRoleDialog,
    "page-auth-dialog": PageAuthDialog,
    "payment-type-auth-dialog": PaymentTypeAuthDialog,
  },
  computed: {
    ...mapState("role", {
      roles: (state) =>
        state.roles.map((item) => ({
          ...item,
          fullAuth: item.isAdmin ? "Evet" : "Hayır",
        })),
      isLoading: (state) => state.isLoading,
      error: (state) => state.error,
    }),
    ...mapState("user", {
      user: (state) => state.user,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) this.fetchRoles();
    },
  },
  methods: {
    ...mapActions("role", {
      fetchRoles: FETCH_ROLES,
    }),
    ...mapMutations("role", {
      toggleDialog: TOGGLE_DELETE_DIALOG,
    }),
    ...mapMutations("pageAuth", {
      togglePageAuthDialog: TOGGLE_PAGE_AUTH_DIALOG,
    }),
    ...mapMutations("paymentTypeAuth", {
      togglePaymentTypeAuthDialog: TOGGLE_PAYMENT_TYPE_AUTH_DIALOG,
    }),
  },
};
</script>
