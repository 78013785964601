<template>
  <div>
    <v-skeleton-loader class="mx-auto" type="table-heading, table-tbody, table-tfoot" v-if="isLoading"></v-skeleton-loader>
    <v-data-table :headers="selectedHeaders" :items="accounts" :options.sync="options" :server-items-length="totalCount" class="elevation-1" :footer-props="footerSettings" v-else>
      >
      <template v-slot:top>
        <v-container class="filter-area" fluid>
          <v-row>
            <v-col cols="12" xs="6" sm="6" md="2">
              <v-autocomplete
                v-model="filters.banks"
                :items="banks"
                item-text="name"
                item-value="id"
                label="Banka Seç"
                dense
                outlined
                multiple
                background-color="white"
                v-on:change="filter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="2">
              <v-text-field v-model="filters.name" label="Hesap Adı" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-text-field v-model="filters.iban" label="Iban No" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="2">
              <v-autocomplete
                v-model="filters.status"
                :items="status"
                item-text="name"
                item-value="value"
                label="Durumu"
                dense
                outlined
                multiple
                background-color="white"
                v-on:change="filter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="2">
              <v-switch v-model="filters.isBanned" dense color="danger" label="Banlanan Hesaplar" v-on:change="filter"></v-switch>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12" xs="6" sm="6" md="6" class="text-left"
              ><new-bank-account-dialog v-if="createAuth" />
              <downloadexcel
                class="v-btn v-btn--contained v-size--default white ml-2"
                :fetch="exportToExcel"
                :fields="excelFields"
                worksheet="Rapor"
                name="Havale_Hesaplari.xls"
                inline-block
                v-if="excelAuth"
              >
                <v-icon color="success" class="mr-2" small>
                  mdi-microsoft-excel
                </v-icon>
                Rapor Al
              </downloadexcel>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="6" class="text-right">
              <v-btn color="primary" @click="filter" inline-block>Listele</v-btn>
              <v-btn @click="reset" inline-block outlined class="ml-2">Reset</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <edit-bank-account-dialog v-if="processAuth" />
        <note-bank-account-dialog v-if="processAuth" />
      </template>
      <template v-slot:item.note="{ item }">
        <div v-if="item.note">
          <strong class="info--text">{{ item.note }}</strong>
          <br />
          <small class="text--secondary" style="font-size:10px"
            >{{ item.notedAt | moment }}
            -
            {{ item.noted.name }}</small
          >
        </div></template
      >
      <template v-slot:item.banks="{ item }">
        <v-expansion-panels class="ma-1" style="width: 180px;">
          <v-expansion-panel>
            <v-expansion-panel-header class="pt-0 pb-0 pl-3 pr-3">
              Banka Listesi
            </v-expansion-panel-header>
            <v-expansion-panel-content style="max-width: 180px; padding: 0px">
              <v-chip label small v-for="bank in item.banks" :key="bank.id" style="display: flex" class="mb-1 mr-1" dark :color="bank.isFast ? 'blue' : 'success'">
                {{ `${bank.name}${bank.isFast ? " (Fast)" : ""}` }}
              </v-chip>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template v-slot:item.iban="{ item }"> <span class="trIban">TR</span>{{ item.iban }} </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip class="ma-2" :text-color="item.isBanned || item.isActive ? 'white' : 'black'" :color="item.isBanned ? 'red' : item.isActive ? 'green' : 'depressed'">
          {{ item.isBanned ? "Banlandı" : item.status }}
        </v-chip>
      </template>
      <template v-slot:item.ban="{ item }">
        {{ item.bannedAt ? toDate(item.bannedAt) : "" }} -
        {{ item.banned ? item.banned.name : "" }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div style="width:300px">
          <v-tooltip bottom v-if="statusAuth && !item.isBanned">
            <template v-slot:activator="{ on }">
              <v-btn depressed class="mx-2" v-on="on">
                <v-switch v-model="item.isActive" :loading="statusIsLoading" @click="changeStatus(item.id)" class="my-1" color="green" hide-details></v-switch>
              </v-btn>
            </template>
            <span>{{ item.isActive ? "Hesabı Pasifle" : "Hesabı Aktif Et" }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="processAuth && !item.isBanned">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" outlined small elevation="2" class="my-2" @click="() => toggleDialog(item)" v-on="on">
                <v-icon size="18">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Hesabı Güncelle</span>
          </v-tooltip>
          <v-tooltip bottom v-if="processAuth && !item.isBanned">
            <template v-slot:activator="{ on }">
              <v-btn color="danger" dark small class="mx-1 my-2" @click="() => banned(item)" v-on="on">
                <v-icon size="18">mdi-cancel</v-icon>
              </v-btn>
            </template>
            <span>Hesabı Banla</span>
          </v-tooltip>
          <v-tooltip bottom v-if="processAuth && !item.isBanned">
            <template v-slot:activator="{ on }">
              <v-btn color="info" outlined small class="my-2" @click="() => toggleNoteDialog(item)" v-on="on">
                <v-icon size="18">mdi-comment-text</v-icon>
              </v-btn>
            </template>
            <span>Not Ekle</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.isBanned && banRollbackAuth">
            <template v-slot:activator="{ on }">
              <v-btn color="orange" small class="my-2 white--text" @click="() => banRollbackAccount(item.id)" v-on="on">
                Banı Kaldır
              </v-btn>
            </template>
            <span>Bu hesabı banlı listesinden çıkarmak için tıklayınız</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  FILTER_BANK_ACCOUNTS,
  CHANGE_BANK_ACCOUNT_STATUS,
  BANNED_BANK_ACCOUNT,
  BAN_ROLLBACK_BANK_ACCOUNT,
  TOGGLE_UPDATE_DIALOG,
  TOGGLE_NOTE_DIALOG,
  SET_BANK_REFRESH,
} from "../../../../store/modules/account/bankAccount";
import { FETCH_BANKS } from "../../../../store/modules/bank";
import { TOGGLE_DIALOG } from "../../../../store/modules/global";
import NewBankAccountDialog from "./NewBankAccountDialog";
import EditBankAccountDialog from "./EditBankAccountDialog";
import NoteBankAccountDialog from "./NoteBankAccountDialog";
import moment from "moment";
import downloadexcel from "vue-json-excel";
import AdminApi from "../../../../api/adminApi";

export default {
  data: () => ({
    bankList: false,
    options: {
      itemsPerPage: 15,
      page: 1,
    },
    filters: {
      banks: "",
      name: "",
      iban: "",
      startDate: "",
      endDate: "",
      status: undefined,
      isBanned: false,
    },
    status: [
      { name: "Aktif", value: true },
      { name: "Pasif", value: false },
    ],
    footerSettings: {
      itemsPerPageOptions: [15],
    },
    excelFields: {
      Banka: "banks",
      "Hesap Adı": "name",
      İban: "iban",
      "Alt Limit": "lowerLimit",
      "Üst Limit": "upperLimit",
      Durum: "status",
      "Banlanma Tarihi": "bannedAt",
      "Banlayan Kişi": "banned.name",
      Not: "note",
      "Not Tarihi": "notedAt",
      "Not Alan": "noted.name",
      "Oluşturma Tarihi": "createdAt",
    },
    headers: [
      { text: "Not", sortable: false, value: "note" },
      { text: "Bankalar", sortable: false, value: "banks" },
      { text: "Hesap Adı", sortable: false, value: "name" },
      { text: "Iban No", sortable: false, value: "iban" },
      { text: "Açıklama", value: "description" },
      { text: "Alt Limit", sortable: false, value: "lowerLimit" },
      { text: "Üst Limit", sortable: false, value: "upperLimit" },
      { text: "Eklenme Tarihi", sortable: false, value: "createdAt" },
      { text: "Durum", sortable: false, value: "status", align: "center" },
      { text: "Ban Bilgileri", sortable: false, value: "ban", isBanned: true },
      { text: "İşlemler", sortable: false, value: "actions" },
    ],
  }),
  components: {
    "new-bank-account-dialog": NewBankAccountDialog,
    "edit-bank-account-dialog": EditBankAccountDialog,
    "note-bank-account-dialog": NoteBankAccountDialog,
    downloadexcel,
  },
  computed: {
    ...mapState("bankAccount", {
      accounts: (state) =>
        state.bankAccounts.map((item) => ({
          ...item,
          status: item.isActive ? "Aktif" : "Pasif",
        })),
      totalCount: (state) => state.totalCount,
      isLoading: (state) => state.isLoading,
      error: (state) => state.error || state.status.error,
      statusIsLoading: (state) => state.status.isLoading,
      refresh: (state) => state.refresh,
    }),
    ...mapState("bank", {
      banks: (state) => state.banks.filter((item) => item.isActive),
    }),
    ...mapState("user", {
      banRollbackAuth: (state) => state.user.role.isAdmin || state.user.role.isSupervisor || state.user.auth.some((a) => a.code == "bbr"),
      createAuth: (state) => state.user.role.auth.some((a) => a.code == "bac"),
      statusAuth: (state) => state.user.role.auth.some((a) => a.code == "bas"),
      processAuth: (state) => state.user.role.auth.some((a) => a.code == "bap"),
      excelAuth: (state) => state.user.role.isAdmin || state.user.auth.some((a) => a.code == "baee"),
    }),
    selectedHeaders() {
      return this.headers.filter((a) => a.isBanned == undefined || a.isBanned == this.filters.isBanned);
    },
  },
  watch: {
    options(oldVal, newVal) {
      if (oldVal.itemsPerPage !== newVal.itemsPerPage || oldVal.page !== newVal.page) {
        this.filter();
        this.fetchBanks();
      }
    },
    refresh(value) {
      if (value) {
        this.setRefresh(false);
        this.filterAccounts({ ...this.options, ...this.filters });
      }
    },
  },
  methods: {
    ...mapActions("bankAccount", {
      filterAccounts: FILTER_BANK_ACCOUNTS,
      changeStatus: CHANGE_BANK_ACCOUNT_STATUS,
      bannedAccount: BANNED_BANK_ACCOUNT,
      banRollbackAccount: BAN_ROLLBACK_BANK_ACCOUNT,
    }),
    ...mapMutations("bankAccount", {
      toggleDialog: TOGGLE_UPDATE_DIALOG,
      toggleNoteDialog: TOGGLE_NOTE_DIALOG,
      setRefresh: SET_BANK_REFRESH,
    }),
    ...mapMutations("global", {
      toogleApproveDialog: TOGGLE_DIALOG,
    }),
    ...mapActions("bank", {
      fetchBanks: FETCH_BANKS,
    }),
    filter() {
      this.filterAccounts({ ...this.options, ...this.filters });
    },
    reset() {
      this.filters = {
        banks: "",
        name: "",
        iban: "",
        startDate: "",
        endDate: "",
        status: "",
        isBanned: false,
      };

      this.filter();
    },
    banned(item) {
      this.toogleApproveDialog({
        title: "Banlanacak Hesap",
        body: `- ${item.name} - adlı Havale hesabını banlanamak üzeresiniz. Bu işlem geri alınamaz, emin misiniz?`,
        params: item.id,
        cb: this.bannedAccount,
      });
    },
    toDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
    async exportToExcel() {
      let result = await AdminApi.exportBankAccounts({
        ...this.options,
        ...this.filters,
        itemsPerPage: -1,
        page: -1,
      });

      return result.rows.map((item) => {
        return {
          ...item,
          banks: item.banks.map((bank) => bank.name).join(", "),
          iban: `TR ${item.iban}`,
          status: item.isBanned ? "Banlandı" : item.isActive ? "Aktif" : "Pasif",
          createdAt: this.toDate(item.createdAt),
          bannedAt: item.banned ? this.toDate(item.bannedAt) : "",
          bannedBy: item.banned ? item.banned.name : "",
          notedAt: item.noted ? this.toDate(item.notedAt) : "",
          notedBy: item.noted ? item.noted.name : "",
        };
      });
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD.MM.YYYY - HH:mm:ss");
    },
  },
};
</script>
<style scoped>
.trIban {
  border-radius: 2px;
  text-align: center;
  background-color: #eee;
  padding: 2px;
  margin-right: 2px;
}
.v-expansion-panel-content__wrap {
  padding: 0 12px 12px;
}
</style>
