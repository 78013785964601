import AdminApi from "../../../api/adminApi";

export const FILTER_CRYPTO_WITHDRAWS = "FILTER_CRYPTO_WITHDRAWS";
export const HISTORY_CRYPTO_WITHDRAW = "HISTORY_CRYPTO_WITHDRAW";
export const CREATE_CRYPTO_WITHDRAWS = "CREATE_CRYPTO_WITHDRAWS";
export const IN_PROGRESS_CRYPTO_WITHDRAW = "IN_PROGRESS_CRYPTO_WITHDRAW";
export const CANCEL_CRYPTO_WITHDRAW = "CANCEL_CRYPTO_WITHDRAW";
export const APPROVE_CRYPTO_WITHDRAW = "APPROVE_CRYPTO_WITHDRAW";
export const REJECT_CRYPTO_WITHDRAW = "REJECT_CRYPTO_WITHDRAW";

export const SET_CRYPTO_WITHDRAWS = "FILTER_CRYPTO_WITHDRAWS";
export const SET_CRYPTO_WITHDRAWS_COUNT = "SET_CRYPTO_WITHDRAWS_COUNT";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";

export const REFRESH_CREATE = "REFRESH_CREATE";
export const SET_CREATE_IS_LOADING = "SET_CREATE_IS_LOADING";
export const SET_CREATE_SUCCESS = "SET_CREATE_SUCCESS";
export const SET_CREATE_ERROR = "SET_CREATE_ERROR";

export const TOGGLE_HISTORY_DIALOG = "TOGGLE_HISTORY_DIALOG";
export const SET_CRYPTO_WITHDRAW_HISTORY = "SET_CRYPTO_WITHDRAW_HISTORY";
export const SET_HISTORY_IS_LOADING = "SET_HISTORY_IS_LOADING";
export const SET_HISTORY_ERROR = "SET_HISTORY_ERROR";

export const SET_IN_PROGRESS_IS_LOADING = "SET_IN_PROGRESS_IS_LOADING";
export const SET_IN_PROGRESS_SUCCESS = "SET_IN_PROGRESS_SUCCESS";
export const SET_IN_PROGRESS_ERROR = "SET_IN_PROGRESS_ERROR";

export const SET_CANCEL_IS_LOADING = "SET_CANCEL_IS_LOADING";
export const SET_CANCEL_SUCCESS = "SET_CANCEL_SUCCESS";
export const SET_CANCEL_ERROR = "SET_CANCEL_ERROR";

export const TOGGLE_APPROVE_DIALOG = "TOGGLE_APPROVE_DIALOG";
export const SET_APPROVE_IS_LOADING = "SET_APPROVE_IS_LOADING";
export const SET_APPROVE_SUCCESS = "SET_APPROVE_SUCCESS";
export const SET_APPROVE_ERROR = "SET_APPROVE_ERROR";

export const TOGGLE_REJECT_DIALOG = "TOGGLE_REJECT_DIALOG";
export const SET_REJECT_IS_LOADING = "SET_REJECT_IS_LOADING";
export const SET_REJECT_SUCCESS = "SET_REJECT_SUCCESS";
export const SET_REJECT_ERROR = "SET_REJECT_ERROR";

export const SET_CRYPTO_REFRESH = "SET_CRYPTO_REFRESH";
export const ADD_CRYPTO_WITHDRAW = "ADD_CRYPTO_WITHDRAW";
export const REPLACE_CRYPTO_WITHDRAW = "REPLACE_CRYPTO_WITHDRAW";

const state = {
  cryptoWithdraws: [],
  totalCount: 0,
  isLoading: false,
  error: "",
  refresh: false,
  history: {
    cryptoWithdrawHistory: [],
    id: "",
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  create: {
    isLoading: false,
    success: false,
    error: "",
  },
  inProgress: {
    isLoading: false,
    success: false,
    error: "",
  },
  cancel: {
    isLoading: false,
    success: false,
    error: "",
  },
  approve: {
    item: null,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  reject: {
    item: null,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
};

const actions = {
  [CREATE_CRYPTO_WITHDRAWS]: async ({ commit, dispatch }, withdraw) => {
    try {
      commit(SET_CREATE_IS_LOADING, true);

      await AdminApi.createCryptoWithdraw(withdraw);

      commit(SET_CREATE_SUCCESS, true);
      dispatch(FILTER_CRYPTO_WITHDRAWS, {});
    } catch (error) {
      commit(
        SET_CREATE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_CREATE_IS_LOADING, false);
    }
  },
  [FILTER_CRYPTO_WITHDRAWS]: async ({ commit }, queryItems) => {
    try {
      commit(SET_IS_LOADING, true);

      let result = await AdminApi.filterCryptoWithdraws(queryItems);

      commit(SET_CRYPTO_WITHDRAWS, result.rows);
      commit(SET_CRYPTO_WITHDRAWS_COUNT, result.count);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [HISTORY_CRYPTO_WITHDRAW]: async ({ commit }, id) => {
    try {
      commit(SET_HISTORY_IS_LOADING, true);

      let result = await AdminApi.historyCryptoWithdraw(id);

      commit(SET_CRYPTO_WITHDRAW_HISTORY, result);
    } catch (error) {
      commit(SET_HISTORY_ERROR, error.response.data);
    } finally {
      commit(SET_HISTORY_IS_LOADING, false);
    }
  },
  [IN_PROGRESS_CRYPTO_WITHDRAW]: async ({ commit, dispatch }, id) => {
    try {
      commit(SET_IN_PROGRESS_IS_LOADING, true);

      await AdminApi.inProgressCryptoWithdraw(id);

      commit(SET_IN_PROGRESS_SUCCESS, true);
      dispatch(FILTER_CRYPTO_WITHDRAWS, {});
    } catch (error) {
      commit(
        SET_IN_PROGRESS_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_IN_PROGRESS_IS_LOADING, false);
    }
  },
  [CANCEL_CRYPTO_WITHDRAW]: async ({ commit, dispatch }, id) => {
    try {
      commit(SET_CANCEL_IS_LOADING, true);

      await AdminApi.cancelCryptoWithdraw(id);

      commit(SET_CANCEL_SUCCESS, true);
      dispatch(FILTER_CRYPTO_WITHDRAWS, {});
    } catch (error) {
      commit(
        SET_CANCEL_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_CANCEL_IS_LOADING, false);
    }
  },
  [APPROVE_CRYPTO_WITHDRAW]: async ({ commit, dispatch }, { id, resultDescription, resultDescriptionId }) => {
    try {
      commit(SET_APPROVE_IS_LOADING, true);

      await AdminApi.approveCryptoWithdraw(id, { resultDescription, resultDescriptionId });

      commit(SET_APPROVE_SUCCESS, true);
      dispatch(FILTER_CRYPTO_WITHDRAWS, {});
    } catch (error) {
      commit(
        SET_APPROVE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_APPROVE_IS_LOADING, false);
    }
  },
  [REJECT_CRYPTO_WITHDRAW]: async ({ commit, dispatch }, { id, resultDescription, resultDescriptionId }) => {
    try {
      commit(SET_REJECT_IS_LOADING, true);

      await AdminApi.rejectCryptoWithdraw(id, { resultDescription, resultDescriptionId });

      commit(SET_REJECT_SUCCESS, true);
      dispatch(FILTER_CRYPTO_WITHDRAWS, {});
    } catch (error) {
      commit(
        SET_REJECT_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_REJECT_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_CRYPTO_WITHDRAWS]: (state, cryptoWithdraws) => (state.cryptoWithdraws = cryptoWithdraws),
  [SET_CRYPTO_WITHDRAWS_COUNT]: (state, totalCount) => (state.totalCount = totalCount),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),

  [TOGGLE_HISTORY_DIALOG]: (state, id = "") => {
    state.history.id = id;
    state.history.dialog = !state.history.dialog;
    state.history.isLoading = false;
    state.history.success = false;
    state.history.error = "";
  },
  [SET_CRYPTO_WITHDRAW_HISTORY]: (state, cryptoWithdrawHistory) => (state.history.cryptoWithdrawHistory = cryptoWithdrawHistory),
  [SET_HISTORY_IS_LOADING]: (state, isLoading) => (state.history.isLoading = isLoading),
  [SET_HISTORY_ERROR]: (state, error) => (state.history.error = error),

  [REFRESH_CREATE]: (state) => {
    (state.create.isLoading = false), (state.create.success = false), (state.create.error = "");
  },
  [SET_CREATE_IS_LOADING]: (state, isLoading) => (state.create.isLoading = isLoading),
  [SET_CREATE_SUCCESS]: (state, success) => (state.create.success = success),
  [SET_CREATE_ERROR]: (state, error) => (state.create.error = error),

  [SET_IN_PROGRESS_IS_LOADING]: (state, isLoading) => (state.inProgress.isLoading = isLoading),
  [SET_IN_PROGRESS_SUCCESS]: (state, success) => (state.inProgress.success = success),
  [SET_IN_PROGRESS_ERROR]: (state, error) => (state.inProgress.error = error),

  [SET_CANCEL_IS_LOADING]: (state, isLoading) => (state.cancel.isLoading = isLoading),
  [SET_CANCEL_SUCCESS]: (state, success) => (state.cancel.success = success),
  [SET_CANCEL_ERROR]: (state, error) => (state.cancel.error = error),

  [TOGGLE_APPROVE_DIALOG]: (state, item = null) => {
    state.approve.item = item;
    state.approve.dialog = !state.approve.dialog;
    state.approve.isLoading = false;
    state.approve.success = false;
    state.approve.error = "";
  },
  [SET_APPROVE_IS_LOADING]: (state, isLoading) => (state.approve.isLoading = isLoading),
  [SET_APPROVE_SUCCESS]: (state, success) => (state.approve.success = success),
  [SET_APPROVE_ERROR]: (state, error) => (state.approve.error = error),

  [TOGGLE_REJECT_DIALOG]: (state, item = null) => {
    state.reject.item = item;
    state.reject.dialog = !state.reject.dialog;
    state.reject.isLoading = false;
    state.reject.success = false;
    state.reject.error = "";
  },
  [SET_REJECT_IS_LOADING]: (state, isLoading) => (state.reject.isLoading = isLoading),
  [SET_REJECT_SUCCESS]: (state, success) => (state.reject.success = success),
  [SET_REJECT_ERROR]: (state, error) => (state.reject.error = error),

  [SET_CRYPTO_REFRESH]: (state, refresh) => (state.refresh = refresh),
  [ADD_CRYPTO_WITHDRAW]: (state, data) => {
    state.cryptoWithdraws.unshift(data);
    state.totalCount++;
  },
  [REPLACE_CRYPTO_WITHDRAW]: (state, data) => {
    const item = state.cryptoWithdraws.find((a) => a.id === data.id);
    if (item) {
      Object.assign(item, data);
    }

    state.cryptoWithdraws = state.cryptoWithdraws.sort((item, secondItem) => {
      if (item.withdrawStatus.sequence == secondItem.withdrawStatus.sequence) {
        return new Date(secondItem.createdAt) - new Date(item.createdAt);
      } else {
        return item.withdrawStatus.sequence - secondItem.withdrawStatus.sequence;
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
