export const SET_LAYOUT = "SET_LAYOUT";
export const SET_MINI = "SET_MINI";
export const SET_HAS_NEW_PAYMENT = "SET_HAS_NEW_PAYMENT";
export const SET_HAS_NEW_WITHDRAW = "SET_HAS_NEW_WITHDRAW";
export const RESET_NEW_PAYMENT = "RESET_NEW_PAYMENT";
export const RESET_NEW_WITHDRAW = "RESET_NEW_WITHDRAW";
export const TOGGLE_DIALOG = "TOGGLE_DIALOG";

const state = {
  layout: "",
  mini: false,
  hasNewPayment: false,
  paymentType: "",
  hasNewWithdraw: false,
  withdrawPaymentType: "",
  dialog: {
    status: false,
    width: "500",
    title: "",
    body: "",
    cb: null,
    params: "",
  },
};

const mutations = {
  [SET_LAYOUT]: (state, layout) => (state.layout = layout),
  [SET_MINI]: (state, mini) => (state.mini = mini),
  [SET_HAS_NEW_PAYMENT]: (state, { hasNewPayment, paymentType }) => {
    state.hasNewPayment = hasNewPayment;

    if (paymentType !== null) state.paymentType = paymentType;
  },
  [RESET_NEW_PAYMENT]: (state) => (state.hasNewPayment = state.paymentType = null),
  [SET_HAS_NEW_WITHDRAW]: (state, { hasNewWithdraw, paymentType }) => {
    state.hasNewWithdraw = hasNewWithdraw;

    if (paymentType !== null) state.withdrawPaymentType = paymentType;
  },
  [RESET_NEW_WITHDRAW]: (state) => (state.hasNewWithdraw = state.withdrawPaymentType = null),
  [TOGGLE_DIALOG]: (state, value) => {
    if (!state.dialog.status) {
      state.dialog = { ...state.dialog, ...value, status: true };
    } else {
      state.dialog = {
        status: false,
        width: "500",
        title: "",
        body: "",
        cb: null,
        params: "",
      };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
