<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <v-skeleton-loader class="mx-auto" type="card, list-item" v-if="loading && history.length == 0"></v-skeleton-loader>
    <v-card v-else>
      <v-card-title>
        <span class="headline">İşlem Geçmişi</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 450px;">
        <v-timeline align-top dense v-if="history.length > 0">
          <v-timeline-item color="teal lighten-3" small v-for="item in history" :key="item.id">
            <v-row class="pt-1">
              <v-col cols="4">
                <strong>{{ item.transactionAt | moment }}</strong>
              </v-col>
              <v-col>
                <v-chip label :color="item.withdrawStatus.color" :text-color="item.withdrawStatus.textColor" dark>{{ item.withdrawStatus.name }}</v-chip>
                <div class="text-caption">
                  {{ item.transaction.name }}
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="toggleDialog">{{ !success && !loading ? "Kapat" : "Kapat" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { TOGGLE_HISTORY_DIALOG, HISTORY_PAPARA_WITHDRAW } from "../../../../store/modules/withdraw/paparaWithdraw";
import moment from "moment";

export default {
  computed: {
    ...mapState("paparaWithdraw", {
      id: (state) => state.history.id,
      dialog: (state) => state.history.dialog,
      history: (state) => state.history.paparaWithdrawHistory,
      loading: (state) => state.history.isLoading,
      error: (state) => state.history.error,
      success: (state) => state.history.success,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.historyPaparaWithdraw(this.id);
      }
    },
  },
  methods: {
    ...mapActions("paparaWithdraw", {
      historyPaparaWithdraw: HISTORY_PAPARA_WITHDRAW,
    }),
    ...mapMutations("paparaWithdraw", {
      toggleDialog: TOGGLE_HISTORY_DIALOG,
    }),
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD.MM.YYYY - HH:mm:ss");
    },
  },
};
</script>
