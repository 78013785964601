<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" depressed v-on="on">
        <v-icon left>mdi-plus-thick</v-icon>Yeni</v-btn
      >
    </template>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Kripto Oluştur</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-show="error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
            >Kripto Oluşturuldu</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.name"
                  label="Kripto Adı"
                  required
                  :rules="nameRules"
                  maxlength="50"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.code"
                  label="Kripto Kodu"
                  required
                  :rules="codeRules"
                  maxlength="4"
                  counter
                  :disabled="success"
                  @input="upper"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="pl-10 pr-10"
            v-show="!success && !loading"
            @click="save"
            >Kaydet</v-btn
          >
          <v-btn
            outlined
            class="pl-4 pr-4 ml-2"
            color="grey"
            text
            @click="dialog = false"
            >{{ !success && !loading ? "İptal" : "Kapat" }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  CREATE_CRYPTO,
  TOGGLE_CREATE_DIALOG,
} from "../../../store/modules/crypto";

export default {
  data: () => ({
    dialog: false,
    item: {
      name: "",
      code: "",
    },
    nameRules: [(v) => !!v || "Kripto adını giriniz"],
    codeRules: [(v) => !!v || "Kripto kodunu giriniz"],
  }),
  computed: {
    ...mapState("crypto", {
      loading: (state) => state.create.isLoading,
      error: (state) => state.create.error,
      success: (state) => state.create.success,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
        this.clearCreateDialog();
        this.item = { name: "", code: "" };
      }
    },
  },
  methods: {
    ...mapActions("crypto", {
      createCrypto: CREATE_CRYPTO,
    }),
    ...mapMutations("crypto", {
      clearCreateDialog: TOGGLE_CREATE_DIALOG,
    }),
    upper() {
      this.item.code = this.item.code.toUpperCase();
    },
    save() {
      if (!this.$refs.form.validate()) return;

      this.createCrypto(this.item);
    },
  },
};
</script>
