import AdminApi from "../../../api/adminApi";

export const FILTER_BANK_WITHDRAWS = "FILTER_BANK_WITHDRAWS";
export const HISTORY_BANK_WITHDRAW = "HISTORY_BANK_WITHDRAW";
export const FILTER_BANK_WITHDRAW_ARCHIVES = "FILTER_BANK_WITHDRAW_ARCHIVES";
export const HISTORY_BANK_WITHDRAW_ARCHIVE = "HISTORY_BANK_WITHDRAW_ARCHIVE";
export const CREATE_BANK_WITHDRAWS = "CREATE_BANK_WITHDRAWS";
export const IN_PROGRESS_BANK_WITHDRAW = "IN_PROGRESS_BANK_WITHDRAW";
export const CANCEL_BANK_WITHDRAW = "CANCEL_BANK_WITHDRAW";
export const APPROVE_BANK_WITHDRAW = "APPROVE_BANK_WITHDRAW";
export const REJECT_BANK_WITHDRAW = "REJECT_BANK_WITHDRAW";

export const SET_BANK_WITHDRAWS = "FILTER_BANK_WITHDRAWS";
export const SET_BANK_WITHDRAWS_COUNT = "SET_BANK_WITHDRAWS_COUNT";
export const SET_BANK_WITHDRAWS_ARCHIVES = "SET_BANK_WITHDRAWS_ARCHIVES";
export const SET_BANK_WITHDRAWS_ARCHIVES_COUNT = "SET_BANK_WITHDRAWS_ARCHIVES_COUNT";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";

export const REFRESH_CREATE = "REFRESH_CREATE";
export const SET_CREATE_IS_LOADING = "SET_CREATE_IS_LOADING";
export const SET_CREATE_SUCCESS = "SET_CREATE_SUCCESS";
export const SET_CREATE_ERROR = "SET_CREATE_ERROR";

export const TOGGLE_HISTORY_DIALOG = "TOGGLE_HISTORY_DIALOG";
export const SET_BANK_WITHDRAW_HISTORY = "SET_BANK_WITHDRAW_HISTORY";
export const SET_HISTORY_IS_LOADING = "SET_HISTORY_IS_LOADING";
export const SET_HISTORY_ERROR = "SET_HISTORY_ERROR";

export const TOGGLE_HISTORY_ARCHIVE_DIALOG = "TOGGLE_HISTORY_ARCHIVE_DIALOG";
export const SET_BANK_WITHDRAW_HISTORY_ARCHIVE = "SET_BANK_WITHDRAW_HISTORY_ARCHIVE";
export const SET_HISTORY_ARCHIVE_IS_LOADING = "SET_HISTORY_ARCHIVE_IS_LOADING";
export const SET_HISTORY_ARCHIVE_ERROR = "SET_HISTORY_ARCHIVE_ERROR";

export const SET_IN_PROGRESS_IS_LOADING = "SET_IN_PROGRESS_IS_LOADING";
export const SET_IN_PROGRESS_SUCCESS = "SET_IN_PROGRESS_SUCCESS";
export const SET_IN_PROGRESS_ERROR = "SET_IN_PROGRESS_ERROR";

export const SET_CANCEL_IS_LOADING = "SET_CANCEL_IS_LOADING";
export const SET_CANCEL_SUCCESS = "SET_CANCEL_SUCCESS";
export const SET_CANCEL_ERROR = "SET_CANCEL_ERROR";

export const TOGGLE_APPROVE_DIALOG = "TOGGLE_APPROVE_DIALOG";
export const SET_APPROVE_IS_LOADING = "SET_APPROVE_IS_LOADING";
export const SET_APPROVE_SUCCESS = "SET_APPROVE_SUCCESS";
export const SET_APPROVE_ERROR = "SET_APPROVE_ERROR";

export const TOGGLE_REJECT_DIALOG = "TOGGLE_REJECT_DIALOG";
export const SET_REJECT_IS_LOADING = "SET_REJECT_IS_LOADING";
export const SET_REJECT_SUCCESS = "SET_REJECT_SUCCESS";
export const SET_REJECT_ERROR = "SET_REJECT_ERROR";

export const SET_BANK_REFRESH = "SET_BANK_REFRESH";
export const ADD_BANK_WITHDRAW = "ADD_BANK_WITHDRAW";
export const REPLACE_BANK_WITHDRAW = "REPLACE_BANK_WITHDRAW";

export const SET_FILTERS = "SET_FILTERS";

const state = {
  bankWithdraws: [],
  totalCount: 0,
  bankWithdrawArchives: [],
  archiveTotalCount: 0,
  isLoading: false,
  error: "",
  refresh: false,
  filters: { startDate: "", endDate: "", status: "", customerName: "", customerSurname: "", customerId: "", iban: "", transactionNumber: "", users: "" },
  history: {
    bankWithdrawHistory: [],
    id: "",
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  archiveHistory: {
    bankWithdrawHistory: [],
    id: "",
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  create: {
    isLoading: false,
    success: false,
    error: "",
  },
  inProgress: {
    isLoading: false,
    success: false,
    error: "",
  },
  cancel: {
    isLoading: false,
    success: false,
    error: "",
  },
  approve: {
    item: null,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  reject: {
    item: null,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
};

const actions = {
  [CREATE_BANK_WITHDRAWS]: async ({ commit }, withdraw) => {
    try {
      commit(SET_CREATE_IS_LOADING, true);

      await AdminApi.createBankWithdraw(withdraw);

      commit(SET_CREATE_SUCCESS, true);
      commit(SET_BANK_REFRESH, true);
    } catch (error) {
      commit(
        SET_CREATE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_CREATE_IS_LOADING, false);
    }
  },
  [FILTER_BANK_WITHDRAWS]: async ({ commit }, queryItems) => {
    try {
      commit(SET_IS_LOADING, true);
      commit(SET_FILTERS, queryItems);

      let result = await AdminApi.filterBankWithdraws(queryItems);

      commit(SET_BANK_WITHDRAWS, result.rows);
      commit(SET_BANK_WITHDRAWS_COUNT, result.count);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [HISTORY_BANK_WITHDRAW]: async ({ commit }, id) => {
    try {
      commit(SET_HISTORY_IS_LOADING, true);

      let result = await AdminApi.historyBankWithdraw(id);

      commit(SET_BANK_WITHDRAW_HISTORY, result);
    } catch (error) {
      commit(SET_HISTORY_ERROR, error.response.data);
    } finally {
      commit(SET_HISTORY_IS_LOADING, false);
    }
  },
  [FILTER_BANK_WITHDRAW_ARCHIVES]: async ({ commit }, queryItems) => {
    try {
      commit(SET_IS_LOADING, true);

      let result = await AdminApi.filterBankWithdrawsArchive(queryItems);

      commit(SET_BANK_WITHDRAWS_ARCHIVES, result.rows);
      commit(SET_BANK_WITHDRAWS_ARCHIVES_COUNT, result.count);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [HISTORY_BANK_WITHDRAW_ARCHIVE]: async ({ commit }, id) => {
    try {
      commit(SET_HISTORY_ARCHIVE_IS_LOADING, true);

      let result = await AdminApi.historyBankWithdrawArchive(id);

      commit(SET_BANK_WITHDRAW_HISTORY_ARCHIVE, result);
    } catch (error) {
      commit(SET_HISTORY_ARCHIVE_ERROR, error.response.data);
    } finally {
      commit(SET_HISTORY_ARCHIVE_IS_LOADING, false);
    }
  },
  [IN_PROGRESS_BANK_WITHDRAW]: async ({ commit }, id) => {
    try {
      commit(SET_IN_PROGRESS_IS_LOADING, true);

      await AdminApi.inProgressBankWithdraw(id);

      commit(SET_IN_PROGRESS_SUCCESS, true);
      commit(SET_BANK_REFRESH, true);
    } catch (error) {
      commit(
        SET_IN_PROGRESS_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_IN_PROGRESS_IS_LOADING, false);
    }
  },
  [CANCEL_BANK_WITHDRAW]: async ({ commit }, id) => {
    try {
      commit(SET_CANCEL_IS_LOADING, true);

      await AdminApi.cancelBankWithdraw(id);

      commit(SET_CANCEL_SUCCESS, true);
      commit(SET_BANK_REFRESH, true);
    } catch (error) {
      commit(
        SET_CANCEL_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_CANCEL_IS_LOADING, false);
    }
  },
  [APPROVE_BANK_WITHDRAW]: async ({ commit }, { id, resultDescription, resultDescriptionId }) => {
    try {
      commit(SET_APPROVE_IS_LOADING, true);

      await AdminApi.approveBankWithdraw(id, { resultDescription, resultDescriptionId });

      commit(SET_APPROVE_SUCCESS, true);
      commit(SET_BANK_REFRESH, true);
    } catch (error) {
      commit(
        SET_APPROVE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_APPROVE_IS_LOADING, false);
    }
  },
  [REJECT_BANK_WITHDRAW]: async ({ commit }, { id, resultDescription, resultDescriptionId }) => {
    try {
      commit(SET_REJECT_IS_LOADING, true);

      await AdminApi.rejectBankWithdraw(id, { resultDescription, resultDescriptionId });

      commit(SET_REJECT_SUCCESS, true);
      commit(SET_BANK_REFRESH, true);
    } catch (error) {
      commit(
        SET_REJECT_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_REJECT_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_BANK_WITHDRAWS]: (state, bankWithdraws) => (state.bankWithdraws = bankWithdraws),
  [SET_BANK_WITHDRAWS_COUNT]: (state, totalCount) => (state.totalCount = totalCount),
  [SET_BANK_WITHDRAWS_ARCHIVES]: (state, bankWithdrawArchives) => (state.bankWithdrawArchives = bankWithdrawArchives),
  [SET_BANK_WITHDRAWS_ARCHIVES_COUNT]: (state, archiveTotalCount) => (state.archiveTotalCount = archiveTotalCount),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),

  [TOGGLE_HISTORY_DIALOG]: (state, id = "") => {
    state.history.id = id;
    state.history.dialog = !state.history.dialog;
    state.history.isLoading = false;
    state.history.success = false;
    state.history.error = "";
  },
  [SET_BANK_WITHDRAW_HISTORY]: (state, bankWithdrawHistory) => (state.history.bankWithdrawHistory = bankWithdrawHistory),
  [SET_HISTORY_IS_LOADING]: (state, isLoading) => (state.history.isLoading = isLoading),
  [SET_HISTORY_ERROR]: (state, error) => (state.history.error = error),

  [TOGGLE_HISTORY_ARCHIVE_DIALOG]: (state, id = "") => {
    state.archiveHistory.id = id;
    state.archiveHistory.dialog = !state.archiveHistory.dialog;
    state.archiveHistory.isLoading = false;
    state.archiveHistory.success = false;
    state.archiveHistory.error = "";
  },
  [SET_BANK_WITHDRAW_HISTORY_ARCHIVE]: (state, bankWithdrawHistory) => (state.archiveHistory.bankWithdrawHistory = bankWithdrawHistory),
  [SET_HISTORY_IS_LOADING]: (state, isLoading) => (state.archiveHistory.isLoading = isLoading),
  [SET_HISTORY_ERROR]: (state, error) => (state.archiveHistory.error = error),

  [REFRESH_CREATE]: (state) => {
    (state.create.isLoading = false), (state.create.success = false), (state.create.error = "");
  },
  [SET_CREATE_IS_LOADING]: (state, isLoading) => (state.create.isLoading = isLoading),
  [SET_CREATE_SUCCESS]: (state, success) => (state.create.success = success),
  [SET_CREATE_ERROR]: (state, error) => (state.create.error = error),

  [SET_IN_PROGRESS_IS_LOADING]: (state, isLoading) => (state.inProgress.isLoading = isLoading),
  [SET_IN_PROGRESS_SUCCESS]: (state, success) => (state.inProgress.success = success),
  [SET_IN_PROGRESS_ERROR]: (state, error) => (state.inProgress.error = error),

  [SET_CANCEL_IS_LOADING]: (state, isLoading) => (state.cancel.isLoading = isLoading),
  [SET_CANCEL_SUCCESS]: (state, success) => (state.cancel.success = success),
  [SET_CANCEL_ERROR]: (state, error) => (state.cancel.error = error),

  [TOGGLE_APPROVE_DIALOG]: (state, item = null) => {
    state.approve.item = item;
    state.approve.dialog = !state.approve.dialog;
    state.approve.isLoading = false;
    state.approve.success = false;
    state.approve.error = "";
  },
  [SET_APPROVE_IS_LOADING]: (state, isLoading) => (state.approve.isLoading = isLoading),
  [SET_APPROVE_SUCCESS]: (state, success) => (state.approve.success = success),
  [SET_APPROVE_ERROR]: (state, error) => (state.approve.error = error),

  [TOGGLE_REJECT_DIALOG]: (state, item = null) => {
    state.reject.item = item;
    state.reject.dialog = !state.reject.dialog;
    state.reject.isLoading = false;
    state.reject.success = false;
    state.reject.error = "";
  },
  [SET_REJECT_IS_LOADING]: (state, isLoading) => (state.reject.isLoading = isLoading),
  [SET_REJECT_SUCCESS]: (state, success) => (state.reject.success = success),
  [SET_REJECT_ERROR]: (state, error) => (state.reject.error = error),

  [SET_BANK_REFRESH]: (state, refresh) => (state.refresh = refresh),
  [ADD_BANK_WITHDRAW]: (state, data) => {
    if (state.filters.page > 1) return;
    if (state.filters.status.length > 0 && !state.filters.status.includes(data.withdrawStatus.id)) return;
    if (state.filters.startDate && new Date(data.createdAt) <= new Date(state.filters.startDate)) return;
    if (state.filters.endDate && new Date(data.createdAt) >= new Date(state.filters.endDate)) return;
    if (state.filters.customerId && !data.customerId.toLowerCase().includes(state.filters.customerId.toLowerCase())) return;
    if (state.filters.customerName && !data.customerName.toLowerCase().includes(state.filters.customerName.toLowerCase())) return;
    if (state.filters.customerSurname && !data.customerSurname.toLowerCase().includes(state.filters.customerSurname.toLowerCase())) return;
    if (state.filters.transactionNumber && !data.transactionNumber.toLowerCase().includes(state.filters.transactionNumber.toLowerCase())) return;
    if (state.filters.iban && !data.iban.toLowerCase().includes(state.filters.iban.toLowerCase())) return;
    if (state.filters.users.length > 0 && !state.filters.users.includes(data.transactionBy) && !state.filters.users.includes(data.approvedBy) && !state.filters.users.includes(data.rejectedBy)) return;

    state.bankWithdraws.unshift(data);
    state.totalCount++;
  },
  [REPLACE_BANK_WITHDRAW]: (state, data) => {
    const item = state.bankWithdraws.find((a) => a.id === data.id);
    if (item) {
      if (state.filters.status.length > 0 && !state.filters.status.includes(data.withdrawStatus.id)) {
        state.bankWithdraws = state.bankWithdraws.filter((a) => a.id != data.id);
        return;
      }

      Object.assign(item, data);

      state.bankWithdraws = state.bankWithdraws.sort((item, secondItem) => {
        if (item.withdrawStatus.sequence == secondItem.withdrawStatus.sequence) {
          return new Date(secondItem.updatedAt) - new Date(item.updatedAt);
        } else {
          return item.withdrawStatus.sequence - secondItem.withdrawStatus.sequence;
        }
      });
    }
  },
  [SET_FILTERS]: (state, filters) => {
    Object.assign(state.filters, filters);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
