<template>
  <div>
    <v-row>
      <password />
      <twofa />
    </v-row>
  </div>
</template>

<script>
import Password from "./Password";
import Twofa from "./2fa";

export default {
  components: {
    password: Password,
    twofa: Twofa,
  },
};
</script>
