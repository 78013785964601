<template>
  <router-view> </router-view>
</template>

<script>
import { mapMutations } from "vuex";
import { SET_LAYOUT } from "../store/modules/global";
import config from "../config";

export default {
  created() {
    document.title = config.TITLE;

    this.setLayout("Payment");
  },
  methods: {
    ...mapMutations("global", {
      setLayout: SET_LAYOUT,
    }),
  },
};
</script>

<style>
.theme--light.v-application {
  background-color: #151414 !important;
}
.v-application .v-toolbar.cyellow {
  display: block;
}
.casibom.v-btn {
  color: black;
}
@media screen and (max-width: 600px) {
  body {
    padding-top: 54px;
  }
  #clogo {
    height: 30px !important;
  }
  .v-application .v-toolbar.cyellow {
    display: block;
  }
  .v-application .v-toolbar.cyellow.isHome {
    display: block;
  }
  .v-application .my-12 {
    margin-top: 30px !important;
  }
  body .theme--light.v-application {
    background-color: #151414;
  }
  .container {
    padding: 0;
  }
}
</style>
