import { getToken } from "../auth";

async function checkAdminRoute({ to, next, store }) {
  if (!getToken()) {
    return next();
  }

  let pages = store.state.user.user.pages;
  if (!pages || pages.length === 0) {
    const user = await store.dispatch("user/FETCH_USER");
    if (user) {
      pages = user.pages;
    }
  }

  let index = pages.findIndex((page) => {
    return page.name === to.name;
  });

  return index !== -1 ? next() : next("Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/404");
}

async function checkAdminRoutePaymentType({ to, next, store }) {
  if (!getToken()) {
    return next();
  }

  let pages = store.state.user.user.pages;
  if (!pages || pages.length === 0) {
    const user = await store.dispatch("user/FETCH_USER");
    if (user) {
      pages = user.pages;
    }
  }

  let pageIndex = pages.findIndex((page) => {
    return page.name === to.meta.parentName;
  });

  let paymentTypes = store.state.paymentType.paymentTypes;
  if (!paymentTypes || paymentTypes.length === 0) {
    paymentTypes = await store.dispatch("paymentType/FETCH_PAYMENT_TYPES");
  }

  let paymentTypeIndex = paymentTypes.findIndex((paymentType) => {
    return paymentType.name === to.meta.paymentType;
  });

  return pageIndex !== -1 && paymentTypeIndex !== -1 ? next() : next("Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/404");
}

export { checkAdminRoute, checkAdminRoutePaymentType };
