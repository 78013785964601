<template>
  <v-col cols="12" sm="12" xs="12" md="5">
    <v-card class="mx-auto pa-2" outlined color="#e0e0e0">
      <v-form ref="form">
        <div class="text-overline ml-2">Şifre Değiştirme</div>
        <v-card-text style="background:#fff;border-radius:4px">
          <v-alert
            v-show="error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
            >Kullanıcı Oluşturuldu</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="password.currentPassword"
                  label="Mevcut Şifre"
                  required
                  :rules="currentPasswordRules"
                  maxlength="12"
                  counter
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="password.newPassword"
                  label="Yeni Şifre"
                  required
                  :rules="newPasswordRules"
                  maxlength="12"
                  counter
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="password.newPasswordAgain"
                  label="Yeni Şifre Tekrar"
                  required
                  :rules="newPasswordRules"
                  maxlength="12"
                  counter
                  type="password"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" block v-if="!loading" @click="save"
            >Güncelle</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-col>
</template>

<script>
import AdminApi from "../../../api/adminApi";

export default {
  data() {
    return {
      password: {
        currentPassword: "",
        newPassword: "",
        newPasswordAgain: "",
      },
      loading: false,
      error: "",
      success: false,
      currentPasswordRules: [
        (v) => !!v || "Lütfen mevcut şifrenizi giriniz",
        (v) =>
          v.length >= 6 ||
          "Lütfen mevcut şifrenizi en az 6 karakter olarak giriniz",
        (v) =>
          v.length <= 12 ||
          "Lütfen mevcut şifrenizi en fazla 12 karakter olarak giriniz",
      ],
      newPasswordRules: [
        (v) => !!v || "Lütfen yeni şifrenizi giriniz",
        (v) =>
          v.length >= 6 ||
          "Lütfen yeni şifrenizi en az 6 karakter olarak giriniz",
        (v) =>
          v.length <= 12 ||
          "Lütfen yeni şifrenizi en fazla 12 karakter olarak giriniz",
      ],
    };
  },
  created() {
    this.clear();
  },
  methods: {
    async save() {
      this.clear();

      if (!this.$refs.form.validate()) return;

      if (this.password.newPassword !== this.password.newPasswordAgain) {
        this.error = "Yeni şifre eşleşmiyor";
        return;
      }

      this.loading = true;

      try {
        await AdminApi.changeUserPassword(this.password);
        this.success = true;
      } catch (error) {
        this.error = error.response.data;
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.error = "";
      this.success = false;
    },
  },
};
</script>
