<template>
  <div>
    <v-alert v-model="isPageError" dismissible text prominent type="error" icon="mdi-alert-circle">{{ pageError }}</v-alert>
    <v-skeleton-loader class="mx-auto" type="table-heading, table-tbody, table-tfoot" v-if="withdraws.length == 0 && loading"></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="withdraws"
      @update:options="changedOptions"
      :options.sync="options"
      :server-items-length="totalCount"
      class="elevation-0"
      :footer-props="footerSettings"
      v-else
    >
      <template v-slot:top>
        <v-container class="filter-area" fluid>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                v-model="filters.status"
                :items="withdrawStatus"
                clearable
                dense
                multiple
                outlined
                item-text="name"
                item-value="id"
                label="Durum Seçiniz"
                background-color="white"
                v-on:change="filter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field v-model="filters.transactionNumber" label="Transaction ID" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-datetime-picker label="Başlangıç Tarihi" v-model="filters.startDate" :text-field-props="{ appendIcon: 'mdi-calendar-today' }"
                ><template slot="dateIcon">
                  <v-icon>mdi-calendar-blank-multiple</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-time-four-outline</v-icon>
                </template></v-datetime-picker
              >
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-datetime-picker label="Bitiş Tarihi" v-model="filters.endDate" :text-field-props="{ appendIcon: 'mdi-calendar-today' }"
                ><template slot="dateIcon">
                  <v-icon>mdi-calendar-blank-multiple</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-time-four-outline</v-icon>
                </template></v-datetime-picker
              >
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-text-field v-model="filters.customerName" label="Müşteri Ad" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-text-field v-model="filters.customerSurname" label="Müşteri Soyad" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-text-field v-model="filters.iban" label="İban" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-text-field v-model="filters.customerId" label="User ID - Login ID" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12" sm="6" md="12" class="text-left">
              <downloadexcel
                class="v-btn theme--light v-size--default v-btn--is-elevated white"
                :fetch="exportToExcel"
                :before-generate="startDownload"
                :before-finish="finishDownload"
                :fields="excelFields"
                worksheet="Rapor"
                name="Havale_Cekimleri.xls"
                inline-block
                style="width: 140px"
                v-if="excelAuth"
              >
                <span class="v-btn__content" v-if="!startDownloadParam">
                  <v-icon color="success" class="mr-2" small>
                    mdi-microsoft-excel
                  </v-icon>
                  Rapor Al
                </span>
                <span class="v-btn__load`er" v-else
                  ><div
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="v-progress-circular v-progress-circular--visible v-progress-circular--indeterminate"
                    style="height: 23px; width: 23px;"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="21.904761904761905 21.904761904761905 43.80952380952381 43.80952380952381" style="transform: rotate(0deg);">
                      <circle
                        fill="transparent"
                        cx="43.80952380952381"
                        cy="43.80952380952381"
                        r="20"
                        stroke-width="3.8095238095238093"
                        stroke-dasharray="125.664"
                        stroke-dashoffset="125.66370614359172px"
                        class="v-progress-circular__overlay"
                      ></circle>
                    </svg>
                    <div class="v-progress-circular__info"></div></div
                ></span>
              </downloadexcel>
              <v-btn class="float-right" @click="reset" inline-block outlined> Reset</v-btn>
              <v-btn color="primary" class="mr-2 float-right" @click="filter" inline-block> Listele</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.amount="{ item }">
        <div class="text-right primary--text font-weight-bold">{{ item.amount }} <small class="text-muted">TL</small></div>
      </template>
      <template v-slot:item.paymentProvider="{}">
        Papara
      </template>
      <template v-slot:item.createdAt="{ item }">
        <div class="grey--text">
          {{ item.created }}
        </div>
        <small>{{ item.createdAt | moment }}</small>
      </template>
      <template v-slot:item.description="{ item }">
        <v-tooltip top v-if="item.withdrawDescription">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="grey" small>
                mdi-comment-text-multiple
              </v-icon>
            </v-btn>
          </template>
          <span>{{ item.description || item.withdrawDescription }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.completedAt="{ item }">
        <div>
          <small>{{ (item.approvedAt || item.rejectedAt) | moment }}</small>
        </div>
        <slot name='item.response="{ item }"'>
          <v-tooltip top v-if="item.withdrawResultDescription">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="black" small>
                  mdi-comment-text-multiple
                </v-icon>
              </v-btn>
            </template>
            <span>{{ item.resultDescription || item.withdrawResultDescription }}</span>
          </v-tooltip>
        </slot>
      </template>
      <template v-slot:item.completedBy="{ item }"> {{ item.approved ? item.approved : item.rejected ? item.rejected : item.transaction ? item.transaction : "" }} </template>
      <template v-slot:item.customerName="{ item }">
        <div style="width:120px">{{ item.customerName }} {{ item.customerSurname }}</div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip small :color="item.withdrawStatusColor" :text-color="item.withdrawStatusTextColor" dark>
          {{ item.withdrawStatusName }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }" class="text-center">
        <div style="width:200px;">
          <v-btn v-if="isAdmin()" class="ma-1" color="white" block small title="İşlem Geçmişi" @click="() => toggleHistoryDialog(item.id)">
            <v-icon size="18" class="mr-1">mdi-text-box-search-outline</v-icon>
            Geçmiş
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <bank-withdraw-history-archive-dialog />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import BankWithdrawHistoryArchiveDialog from "./BankWithdrawHistoryArchiveDialog.vue";
import { FILTER_BANK_WITHDRAW_ARCHIVES, TOGGLE_HISTORY_ARCHIVE_DIALOG } from "../../../../store/modules/withdraw/bankWithdraw";
import { GET_WITHDRAW_STATUS } from "../../../../store/modules/withdraw/withdraw";
import moment from "moment";
import AdminApi from "../../../../api/adminApi";
import downloadexcel from "vue-json-excel";

export default {
  data: () => ({
    isPageError: false,
    pageError: "",
    options: {
      itemsPerPage: 10,
      page: 1,
    },
    isHidden: null,
    filters: { startDate: "", endDate: "", status: "", customerName: "", customerSurname: "", customerId: "", iban: "", transactionNumber: "" },
    headers: [
      { text: "Talep Eden", sortable: false, value: "createdAt" },
      { text: "Transaction ID", sortable: false, value: "transactionNumber" },
      { text: "Ödeme Yöntemi", sortable: false, value: "paymentProvider" },
      { text: "User/Login ID", sortable: false, value: "customerId" },
      { text: "Müşteri Ad Soyad", sortable: false, value: "customerName" },
      { text: "Banka", sortable: false, value: "bankName" },
      { text: "İban", sortable: false, value: "iban" },
      { text: "Tutar", sortable: false, value: "amount", align: "right" },
      { text: "Talep Notu", sortable: false, value: "description", align: "center" },
      { text: "Durum", sortable: false, value: "status", align: "center" },
      { text: "İşlem Sonu / Açıklama", sortable: false, value: "completedAt" },
      { text: "İşlemi tamamlayan", sortable: false, value: "completedBy" },
      { text: "İşlem", sortable: false, value: "actions" },
    ],
    footerSettings: {
      itemsPerPageOptions: [10],
    },
    excelFields: {
      "Talep Tarihi": "createdAt",
      "Talep Eden": "created",
      "Transaction ID": "transactionNumber",
      "Ödeme Yöntemi": "paymentType",
      "User/Login ID": "customerId",
      "Müşteri Ad Soyad": "customerName",
      Banka: "bankName",
      İban: "iban",
      Tutar: "amount",
      "Talep Açıklaması": "description",
      Durum: "withdrawStatusName",
      "İşlem Açıklaması": "resultDescription",
      "İşlem Tarihi": "transactionAt",
      "İşleme Başlayan": "transaction",
      "Onaylama Tarihi": "approvedAt",
      Onaylayan: "approved",
      "Reddetme Tarihi": "rejectedAt",
      Reddeden: "rejected",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    startDownloadParam: false,
  }),
  components: {
    "bank-withdraw-history-archive-dialog": BankWithdrawHistoryArchiveDialog,
    downloadexcel,
  },
  computed: {
    ...mapState("bankWithdraw", {
      withdraws: (state) => state.bankWithdrawArchives,
      totalCount: (state) => state.archiveTotalCount,
      loading: (state) => state.isLoading,
      error: (state) => state.error,
      refresh: (state) => state.refresh,
    }),
    ...mapState("withdraw", {
      withdrawStatus: (state) => state.withdrawStatus,
    }),
    ...mapState("user", {
      user: (state) => state.user,
      excelAuth: (state) => state.user.role.isAdmin || state.user.auth.some((a) => a.code == "bwee"),
    }),
    status() {
      return this.filters.status;
    },
  },
  watch: {
    pageError(value) {
      this.isPageError = value != "";
    },
  },
  created() {
    // this.filter();
    this.getWithdrawStatus();
  },
  methods: {
    ...mapActions("bankWithdraw", {
      filterAccounts: FILTER_BANK_WITHDRAW_ARCHIVES,
    }),
    ...mapActions("withdraw", {
      getWithdrawStatus: GET_WITHDRAW_STATUS,
    }),
    ...mapMutations("bankWithdraw", {
      toggleHistoryDialog: TOGGLE_HISTORY_ARCHIVE_DIALOG,
    }),
    filter() {
      this.pageError = "";

      this.options = { itemsPerPage: 10, page: 1 };
      this.filterAccounts({ ...this.options, ...this.filters });
    },
    reset() {
      this.pageError = "";

      this.filters = { startDate: "", endDate: "", status: "", customerName: "", customerSurname: "", customerId: "", iban: "", transactionNumber: "" };
      this.options = { itemsPerPage: 10, page: 1 };

      this.filterAccounts({ ...this.options, ...this.filters });
    },
    changedOptions(data) {
      if (data.itemsPerPage != this.options.itemsPerPage || data.page != this.options.page) {
        this.pageError = "";

        this.filterAccounts({ ...data, ...this.filters });
      }
    },
    history(id) {
      this.historyBankWithdraw(id);
    },
    isAdmin() {
      return this.user.role.isAdmin || this.user.role.isCasibomRole;
    },
    toDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
    async exportToExcel() {
      this.pageError = "";

      try {
        let result = await AdminApi.exportBankWithdrawsArchive({
          ...this.options,
          ...this.filters,
          itemsPerPage: -1,
          page: -1,
        });

        if (result.rows.length == 0) {
          this.pageError = "Excel'e aktarılacak veri bulunamadı filtre alanını kontrol ediniz";
          this.startDownloadParam = false;
          return;
        }

        return result.rows.map((item) => {
          return {
            ...item,
            paymentType: "Havale",
            customerName: `${item.customerName} ${item.customerSurname}`,
            createdAt: this.toDate(item.createdAt),
            createdBy: item.created.name,
            transactionAt: item.transaction ? this.toDate(item.transactionAt) : "",
            transactionBy: item.transaction ? item.transaction.name : "",
            approvedAt: item.approved ? this.toDate(item.approvedAt) : "",
            approvedBy: item.approved,
            rejectedAt: item.rejected ? this.toDate(item.rejectedAt) : "",
            rejectedBy: item.rejected,
            description: item.description ? item.description : item.withdrawDescription,
            resultDescription: item.resultDescription ? item.resultDescription : item.withdrawResultDescription,
          };
        });
      } catch (error) {
        this.pageError = error;
        this.startDownloadParam = false;
      }
    },
    startDownload() {
      this.startDownloadParam = true;
    },
    finishDownload() {
      setTimeout(() => (this.startDownloadParam = false), 1200);
    },
  },
  filters: {
    moment: function(date) {
      if (!date) return;

      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
  },
};
</script>
