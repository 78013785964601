<template>
  <div class="interstitial-wrapper">
    <div class="icon icon-generic"></div>
    <h1>This site can’t be reached</h1>
    <div class="hostName">
      Check if there is a typo in
      <span jscontent="hostName" jstcache="23">rbmpy.com</span>.
    </div>
    <div class="error-code">DNS_PROBE_FINISHED_NXDOMAIN</div>
    <a href="javascript:void(0)" class="reload-btn">Reload</a>
  </div>
</template>

<script>
export default {
  created() {
    document.title = "This site can’t be reached";
  },
};
</script>
<style>
.icon-generic {
  content: -webkit-image-set(
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABIAQMAAABvIyEEAAAABlBMVEUAAABTU1OoaSf/AAAAAXRSTlMAQObYZgAAAENJREFUeF7tzbEJACEQRNGBLeAasBCza2lLEGx0CxFGG9hBMDDxRy/72O9FMnIFapGylsu1fgoBdkXfUHLrQgdfrlJN1BdYBjQQm3UAAAAASUVORK5CYII=)
      1x,
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACQAQMAAADdiHD7AAAABlBMVEUAAABTU1OoaSf/AAAAAXRSTlMAQObYZgAAAFJJREFUeF7t0cENgDAMQ9FwYgxG6WjpaIzCCAxQxVggFuDiCvlLOeRdHR9yzjncHVoq3npu+wQUrUuJHylSTmBaespJyJQoObUeyxDQb3bEm5Au81c0pSCD8HYAAAAASUVORK5CYII=)
      2x
  );
  color: #9aa0a6;
  margin-bottom: 40px;
}
.error-code {
  display: block;
  font-size: 0.8em;
  margin: 0 0 50px 0;
}
.hostName {
  margin-bottom: 10px;
}
.interstitial-wrapper {
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.6em;
  margin: 14vh auto 0;
  max-width: 600px;
  width: 100%;
  color: #9aa0a6;
}
.interstitial-wrapper h1 {
  font-size: 1.5em;
  margin-bottom: 18px;
  word-wrap: break-word;
}
.reload-btn {
  background-color: #8ab4f8;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  color: black !important;
  cursor: pointer;
  float: right;
  font-size: 0.875em;
  margin: 0;
  padding: 4px 16px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  text-decoration: none;
}
</style>
