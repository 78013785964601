<template>
  <v-dialog v-model="dialog" scrollable max-width="700px" persistent>
    <v-skeleton-loader class="mx-auto" type="card, list-item" v-if="loading && history.length == 0"></v-skeleton-loader>
    <v-card v-else>
      <v-card-title>
        <span class="headline">İşlem Geçmişi</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 450px;">
        <v-data-table :headers="headers" :items="history" class="elevation-1" hide-default-footer disable-pagination no-data-text="Hata alan işlem bulunmamaktadır">
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | moment }}
          </template>
          <template v-slot:item.result="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-chip class="ma-2" text-color="white" :color="item.fundistIsError ? 'red' : 'green'" v-on="on">
                  {{ item.fundistIsError ? "İşlem Hata Aldı" : "İşlem Başarıyla Tamamlandı" }}
                </v-chip>
              </template>
              <span>{{ item.fundistResultJson }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.created="{ item }">
            <div class="grey--text">
              {{ item.created.name }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn color="primary" outlined small elevation="2" class="mx-2" @click="() => copyContent(item.fundistResultJson)">
              <v-icon size="18">mdi-floppy</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="toggleDialog">{{ !success && !loading ? "Kapat" : "Kapat" }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" timeout="2000">
      Sonuç kopyalandı
      <template v-slot:action="{ attrs }">
        <v-btn color="cyellow" text v-bind="attrs" @click="snackbar = false">
          Kapat
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { TOGGLE_HISTORY_DIALOG, GET_HISTORY } from "../../../../store/modules/payment/bankPayment";
import moment from "moment";

export default {
  data: () => ({
    snackbar: false,
    headers: [
      { text: "Tarih-Saat", sortable: false, value: "createdAt" },
      { text: "İşlem Bilgileri", sortable: false, value: "created" },
      { text: "Sonuç", sortable: false, value: "result" },
      { text: "", sortable: false, value: "actions" },
    ],
  }),
  computed: {
    ...mapState("bankPayment", {
      id: (state) => state.history.id,
      dialog: (state) => state.history.dialog,
      history: (state) => state.history.history,
      loading: (state) => state.history.isLoading,
      error: (state) => state.history.error,
      success: (state) => state.history.success,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.getHistory(this.id);
      }
    },
  },
  methods: {
    ...mapActions("bankPayment", {
      getHistory: GET_HISTORY,
    }),
    ...mapMutations("bankPayment", {
      toggleDialog: TOGGLE_HISTORY_DIALOG,
    }),
    async copyContent(result) {
      await navigator.clipboard.writeText(JSON.stringify(result));

      this.snackbar = true;
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD.MM.YYYY - HH:mm:ss");
    },
  },
};
</script>
