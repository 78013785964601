<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form" :lazy-validation="false">
        <v-card-title>
          <span class="headline">İşlemi Reddet</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">
          <span v-html="error" />
        </v-alert>
        <v-alert v-show="success" text prominent type="success" icon="mdi-cloud-check">İşlem Reddedildi</v-alert>
        <v-card-text>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container v-if="!success && selectedItem">
            <p>Aşağıdaki ödemeyi reddetmek istediğinize emin misiniz?</p>
            <v-simple-table style="border:1px solid #ccc">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="text-left">Müşteri</td>
                    <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                    <td class="font-weight-bold">{{ selectedItem.customerName }} {{ selectedItem.customerSurname }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Banka/IBAN</td>
                    <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                    <td class="font-weight-bold">
                      <div class="green--text font-weight-bold pt-1 pb-1">{{ selectedItem.bank ? selectedItem.bank.name : "" }}</div>
                      <div class="font-weight-normal pb-1" style="width:240px;">{{ selectedItem.iban | iban }}</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Tutar</td>
                    <td class="text-left"><v-icon size="medium">mdi-arrow-right</v-icon></td>
                    <td class="green--text font-weight-bold" style="font-size:16px">{{ selectedItem.amount }} <small>TL</small></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <br />
            <v-form>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-select
                    v-model="item.resultDescriptionId"
                    :items="resultDescriptions"
                    clearable
                    dense
                    outlined
                    item-text="name"
                    item-value="id"
                    label="Ret Açıklaması"
                    background-color="white"
                    :disabled="success"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    v-if="manuelDescription"
                    v-model="item.resultDescription"
                    label="İşlem sonuç açıklaması"
                    hint="İşlem sonucuyla ilgili notunuz varsa buraya girebilirsiniz"
                    rows="2"
                    row-height="16"
                    required
                    :disabled="success"
                    outlined
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-show="!success" color="error" class="pl-10 pr-10" @click="reject">Reddet</v-btn>
          <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="toggleDialog">{{ success ? "Kapat" : "İptal" }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { REJECT_BANK_WITHDRAW, TOGGLE_REJECT_DIALOG } from "../../../../store/modules/withdraw/bankWithdraw";
import { GET_WITHDRAW_RESULT_DESCRIPTION } from "../../../../store/modules/withdraw/withdraw";
import IBAN from "iban";

export default {
  data: () => ({
    manuelDescription: "",
    item: { resultDescriptionId: "", resultDescription: "" },
  }),
  computed: {
    ...mapState("bankWithdraw", {
      selectedItem: (state) => state.reject.item,
      dialog: (state) => state.reject.dialog,
      loading: (state) => state.reject.isLoading,
      success: (state) => state.reject.success,
      error: (state) => state.reject.error,
    }),
    ...mapState("withdraw", {
      resultDescriptions: (state) => state.withdrawResultDescriptions,
    }),
    resultDescriptionId() {
      return this.item.resultDescriptionId;
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();

        this.getWithdrawResultDescriptions({ paymentTypeName: "Havale", isSuccess: false });
        this.item = { resultDescriptionId: null, resultDescription: "" };
      }
    },
    success(newVal) {
      if (newVal) {
        this.item = { resultDescriptionId: "", resultDescription: "" };
      }
    },
    resultDescriptionId(newVal) {
      if (!newVal) {
        this.manuelDescription = false;
        return;
      }

      this.manuelDescription = this.resultDescriptions.find((a) => a.id == newVal).other;
    },
  },
  methods: {
    ...mapActions("bankWithdraw", {
      rejectWithdraw: REJECT_BANK_WITHDRAW,
    }),
    ...mapMutations("bankWithdraw", {
      toggleDialog: TOGGLE_REJECT_DIALOG,
    }),
    ...mapActions("withdraw", {
      getWithdrawResultDescriptions: GET_WITHDRAW_RESULT_DESCRIPTION,
    }),
    reject() {
      if (!this.$refs.form.validate()) return;

      this.rejectWithdraw({ id: this.selectedItem.id, resultDescriptionId: this.item.resultDescriptionId, resultDescription: this.item.resultDescription });
    },
  },
  filters: {
    iban: function(iban) {
      return IBAN.printFormat(`TR${iban}`, " ");
    },
  },
};
</script>
