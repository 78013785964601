import PaymentApi from "../../../api/paymentApi";
import AdminApi from "../../../api/adminApi";

export const PAYMENT_REQUEST = "PAYMENT_REQUEST";
export const BANK_SELECT = "BANK_SELECT";
export const CRYPTO_SELECT = "CRYPTO_SELECT";
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const GET_PAYMENT_COUNTS = "GET_PAYMENT_COUNTS";

export const SET_COMPONENT = "SET_COMPONENT";
export const SET_DATA = "SET_DATA";
export const SET_IS_LOADING = "IS_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_PAYMENT_COUNTS = "SET_PAYMENT_COUNTS";
export const ADD_PAYMENT_COUNTS = "ADD_PAYMENT_COUNTS";
export const CHANGE_PAYMENT_COUNTS = "CHANGE_PAYMENT_COUNTS";

const state = {
  component: "",
  data: {},
  isLoading: false,
  error: "",
  paymentCounts: [],
};

const actions = {
  [PAYMENT_REQUEST]: async ({ commit }, { tokenId }) => {
    try {
      commit(SET_IS_LOADING, true);

      let data = await new PaymentApi(tokenId).paymentRequest();
      commit(SET_COMPONENT, `${data.paymentType}-${data.step}`);
      commit(SET_DATA, data);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [BANK_SELECT]: async ({ commit }, { tokenId, bankId }) => {
    try {
      commit(SET_IS_LOADING, true);

      let data = await new PaymentApi(tokenId).selectBank(bankId);
      commit(SET_COMPONENT, `${data.paymentType}-${data.step}`);
      commit(SET_DATA, data);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [CRYPTO_SELECT]: async ({ commit }, { tokenId, cryptoId }) => {
    try {
      commit(SET_IS_LOADING, true);

      let data = await new PaymentApi(tokenId).selectCrypto(cryptoId);
      commit(SET_COMPONENT, `${data.paymentType}-${data.step}`);
      commit(SET_DATA, data);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [CREATE_PAYMENT]: async ({ commit }, { tokenId }) => {
    try {
      commit(SET_IS_LOADING, true);

      let data = await new PaymentApi(tokenId).createPayment();
      commit(SET_COMPONENT, `${data.paymentType}-${data.step}`);
      commit(SET_DATA, data);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [GET_PAYMENT_COUNTS]: async ({ commit }) => {
    let paymentCounts = await AdminApi.getPaymentCounts();

    commit(SET_PAYMENT_COUNTS, paymentCounts);
  },
};

const mutations = {
  [SET_COMPONENT]: (state, component) => (state.component = component),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => {
    state.error = error;
    if (error) state.component = "error";
  },
  [SET_DATA]: (state, data) => (state.data = data),
  [SET_PAYMENT_COUNTS]: (state, paymentCounts) => (state.paymentCounts = paymentCounts),
  [ADD_PAYMENT_COUNTS]: (state, paymentType) => {
    const item = state.paymentCounts.find((a) => a.paymentType === paymentType);
    if (item) {
      var status = item.counts.find((a) => a.status === "Onay Bekliyor");
      var approveStatus = item.counts.find((a) => a.status === "Onaylandı");
      var rejectStatus = item.counts.find((a) => a.status === "Reddedildi");

      Object.assign(item, {
        paymentType,
        counts: [
          { status: "Onay Bekliyor", count: parseInt(status.count) + 1 },
          { status: "Onaylandı", count: parseInt(approveStatus.count) },
          { status: "Reddedildi", count: parseInt(rejectStatus.count) },
        ],
      });
    }
  },
  [CHANGE_PAYMENT_COUNTS]: (state, data) => {
    const item = state.paymentCounts.find((a) => a.paymentType === data.paymentType);
    if (item) {
      var status = item.counts.find((a) => a.status === "Onay Bekliyor");
      var approveStatus = item.counts.find((a) => a.status === "Onaylandı");
      var rejectStatus = item.counts.find((a) => a.status === "Reddedildi");

      Object.assign(item, {
        paymentType: data.paymentType,
        counts: [
          { status: "Onay Bekliyor", count: parseInt(status.count - 1) >= 0 ? parseInt(status.count - 1) : 0 },
          { status: "Onaylandı", count: parseInt(data.status === "Onaylandı" ? parseInt(approveStatus.count) + 1 : parseInt(approveStatus.count)) },
          { status: "Reddedildi", count: parseInt(data.status === "Reddedildi" ? parseInt(rejectStatus.count) + 1 : parseInt(rejectStatus.count)) },
        ],
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
