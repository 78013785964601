import AdminApi from "../../api/adminApi";

export const FETCH_BANKS = "FETCH_BANKS";
export const CREATE_BANK = "CREATE_BANK";
export const CHANGE_BANK_STATUS = "CHANGE_BANK_STATUS";

export const SET_BANKS = "SET_BANKS";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";

export const TOGGLE_CREATE_DIALOG = "TOGGLE_CREATE_DIALOG";
export const SET_CREATE_IS_LOADING = "SET_CREATE_IS_LOADING";
export const SET_CREATE_SUCCESS = "SET_CREATE_SUCCESS";
export const SET_CREATE_ERROR = "SET_CREATE_ERROR";

export const SET_STATUS_IS_LOADING = "SET_STATUS_IS_LOADING";
export const SET_STATUS_ERROR = "SET_STATUS_ERROR";

const state = {
  banks: [],
  isLoading: false,
  error: "",
  create: {
    isLoading: false,
    success: false,
    error: "",
  },
  status: {
    isLoading: false,
    error: "",
  },
};

const actions = {
  [FETCH_BANKS]: async ({ commit }) => {
    try {
      commit(SET_IS_LOADING, true);

      let banks = await AdminApi.getBanks();
      commit(SET_BANKS, banks);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [CREATE_BANK]: async ({ commit, dispatch }, bank) => {
    try {
      commit(SET_CREATE_IS_LOADING, true);

      await AdminApi.createBank(bank);

      commit(SET_CREATE_SUCCESS, true);
      dispatch(FETCH_BANKS);
    } catch (error) {
      commit(
        SET_CREATE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_CREATE_IS_LOADING, false);
    }
  },
  [CHANGE_BANK_STATUS]: async ({ commit, dispatch }, id) => {
    try {
      commit(SET_STATUS_IS_LOADING, true);

      await AdminApi.changeBankStatus(id);

      dispatch(FETCH_BANKS);
    } catch (error) {
      commit(
        SET_STATUS_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_STATUS_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_BANKS]: (state, banks) => (state.banks = banks),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),

  [TOGGLE_CREATE_DIALOG]: (state) => {
    state.create.isLoading = false;
    state.create.success = false;
    state.create.error = "";
  },
  [SET_CREATE_IS_LOADING]: (state, isLoading) => (state.create.isLoading = isLoading),
  [SET_CREATE_SUCCESS]: (state) => (state.create.success = true),
  [SET_CREATE_ERROR]: (state, error) => (state.create.error = error),

  [SET_STATUS_IS_LOADING]: (state, isLoading) => (state.status.isLoading = isLoading),
  [SET_STATUS_ERROR]: (state, error) => (state.status.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
