<template>
  <component v-bind:is="component" />
</template>

<script>
import { mapState, mapActions } from "vuex";
import { PAYMENT_REQUEST } from "../../store/modules/payment/payment";

import BankStep1 from "./bank/BankStep1";
import BankStep2 from "./bank/BankStep2";
import BankStep3 from "./bank/BankStep3";
import BankStep4 from "./bank/BankStep4";
import BankStep5 from "./bank/BankStep5";
import BankStep6 from "./bank/BankStep6";

import CryptoStep1 from "./crypto/CryptoStep1";
import CryptoStep2 from "./crypto/CryptoStep2";
import CryptoStep3 from "./crypto/CryptoStep3";
import CryptoStep4 from "./crypto/CryptoStep4";
import CryptoStep5 from "./crypto/CryptoStep5";
import CryptoStep6 from "./crypto/CryptoStep6";

import PaparaStep2 from "./papara/PaparaStep2";
import PaparaStep3 from "./papara/PaparaStep3";
import PaparaStep4 from "./papara/PaparaStep4";
import PaparaStep5 from "./papara/PaparaStep5";
import PaparaStep6 from "./papara/PaparaStep6";

import ErrorPage from "./Error";

import Socket from "../../socket/payment/index";

history.pushState(null, null, location.href);
window.onpopstate = function() {
  history.go(1);
};

export default {
  computed: {
    ...mapState("payment", {
      component: (state) => state.component,
    }),
  },
  components: {
    "Havale-1": BankStep1,
    "Havale-2": BankStep2,
    "Havale-3": BankStep3,
    "Havale-4": BankStep4,
    "Havale-5": BankStep5,
    "Havale-6": BankStep6,
    "Kripto-1": CryptoStep1,
    "Kripto-2": CryptoStep2,
    "Kripto-3": CryptoStep3,
    "Kripto-4": CryptoStep4,
    "Kripto-5": CryptoStep5,
    "Kripto-6": CryptoStep6,
    "Papara-2": PaparaStep2,
    "Papara-3": PaparaStep3,
    "Papara-4": PaparaStep4,
    "Papara-5": PaparaStep5,
    "Papara-6": PaparaStep6,
    error: ErrorPage,
  },
  created() {
    if (this.$route.params.tokenId) {
      this.paymentRequest({ tokenId: this.$route.params.tokenId });

      new Socket(this.$route.params.tokenId, this);
    }
  },
  methods: {
    ...mapActions("payment", {
      paymentRequest: PAYMENT_REQUEST,
    }),
  },
};
</script>
