import AdminApi from "../../api/adminApi";

export const FETCH_CACHE = "FETCH_CACHE";
export const DELETE_CACHE = "DELETE_CACHE";

export const SET_CACHE = "SET_CACHE";
export const SET_QUERY = "SET_QUERY";
export const SET_IS_LOADING = "SET_IS_LOADING";

const state = {
  cacheList: [],
  isLoading: false,
  query: {},
};

const actions = {
  [FETCH_CACHE]: async ({ commit }, queryItems) => {
    try {
      commit(SET_IS_LOADING, true);

      let cacheList = await AdminApi.filterCache(queryItems);
      commit(SET_CACHE, cacheList);
      commit(SET_QUERY, queryItems);
    } catch (error) {
      console.error(error);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [DELETE_CACHE]: async ({ dispatch, state }, key) => {
    try {
      await AdminApi.deleteCache(key);
      dispatch(FETCH_CACHE, state.query);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  [SET_CACHE]: (state, cacheList) => (state.cacheList = cacheList),
  [SET_QUERY]: (state, query) => (state.query = query),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
