import AdminApi from "../../api/adminApi";

export const FETCH_PAYMENT_STATUSES = "FETCH_PAYMENT_STATUSES";

export const SET_PAYMENT_STATUSES = "SET_PAYMENT_STATUSES";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";

const state = {
  paymentStatuses: [],
  isLoading: false,
  error: "",
};

const actions = {
  [FETCH_PAYMENT_STATUSES]: async ({ commit, state }) => {
    try {
      if (state.paymentStatuses.length > 0) return;

      commit(SET_IS_LOADING, true);

      let paymentTypes = await AdminApi.getPaymentStatuses();
      commit(SET_PAYMENT_STATUSES, paymentTypes);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_PAYMENT_STATUSES]: (state, paymentStatuses) => (state.paymentStatuses = paymentStatuses),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
