<template>
  <v-col cols="12" sm="12" xs="12" md="7">
    <v-card class="mx-auto pa-2" outlined color="#e0e0e0">
      <div class="text-overline ml-2">İki Aşamalı Doğrulama (2FA) </div>
      <v-card-text style="background:#fff;border-radius:4px">
        <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-switch v-model="twofa" :loading="isLoading" @click="changeStatus()" class="my-1" color="green" inset label="Panele girişte ekstra güvenlik için bu ayarı lütfen aktif ediniz"></v-switch>
              <v-alert border="bottom" elevation="2">
                <v-row align="center">
                  <v-col sm="12" md="1" class="text-center"><img src="https://play-lh.googleusercontent.com/NntMALIH4odanPPYSqUOXsX8zy_giiK2olJiqkcxwFIOOspVrhMi9Miv6LYdRnKIg-3R" style="width:40px" /></v-col>
                  <v-col sm="12" md="11"><strong>Google Authenticator</strong> uygulamasını telefonunuza indirin<br> Uygulamayı açın ve  
                  (+) butonuna basarak QR kodunu tarayın</v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col v-if="twofa && qr" cols="12" sm="12" md="12">
              <v-card max-width="320" border="bottom">
                <v-card-title>
                  <v-icon size="large" class="mr-2" color="primary">mdi-camera</v-icon>
                  QR kodunu tara</v-card-title>
                <v-card-subtitle>
                  <br>
                  <v-img :src="qr" max-height="150" max-width="150" />
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-title>
                  <v-icon size="large" class="mr-2" color="primary">mdi-keyboard</v-icon>
                  (yada) Kurulum anahtarı gir</v-card-title>
                <v-card-subtitle>{{ secret }}</v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { UPDATE_USER_2FA } from "../../../store/modules/user";

export default {
  computed: {
    ...mapState("user", {
      twofa: (state) => state.user.twofa,
      qr: (state) => state.user.qr,
      secret: (state) => state.user.secret,
      error: (state) => state.twofa.error,
      isLoading: (state) => state.twofa.isLoading,
    }),
  },
  methods: {
    ...mapActions("user", {
      changeStatus: UPDATE_USER_2FA,
    }),
  },
};
</script>
