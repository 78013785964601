const charcterSet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

exports.generatePassword = (length) => {
  let password = "";

  for (let i = 0; i < length; i++) {
    password += charcterSet.charAt(Math.floor(Math.random() * charcterSet.length));
  }

  return password;
};
