<template>
  <div>
    <v-row>
      <v-col class="gray-border">
        <v-tabs v-model="tab">
          <v-tab v-for="item in paymentTypes" :key="item.name">
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in paymentTypes" :key="item.name">
            <component v-bind:is="item.name"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <approve-dialog />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PaparaAccounts from "./papara/PaparaAccounts";
import BankAccounts from "./bank/BankAccounts";
import CryptoAccounts from "./crypto/CryptoAccounts";
import { FETCH_PAYMENT_TYPES } from "../../../store/modules/paymentType";
import { FILTER_PAPARA_ACCOUNTS } from "../../../store/modules/account/paparaAccount";
import { FILTER_BANK_ACCOUNTS } from "../../../store/modules/account/bankAccount";
import { FILTER_CRYPTO_ACCOUNTS } from "../../../store/modules/account/cryptoAccount";
import { FETCH_CRYPTOS } from "../../../store/modules/crypto";
import { FETCH_BANKS } from "../../../store/modules/bank";
import Dialog from "../../../components/Dialog.vue";

export default {
  data() {
    return {
      tab: null,
      items: [
        { name: "Papara", component: "papara-accounts" },
        { name: "Banka Havalesi", component: "bank-accounts" },
        { name: "Kripto Paralar", component: "crypto-accounts" },
      ],
    };
  },
  computed: {
    ...mapState("paymentType", {
      paymentTypes: (state) => state.paymentTypes,
    }),
  },
  components: {
    Papara: PaparaAccounts,
    Havale: BankAccounts,
    Kripto: CryptoAccounts,
    "approve-dialog": Dialog,
  },
  watch: {
    tab(newVal) {
      switch (this.paymentTypes[newVal].name) {
        case "Papara":
          this.filterPaparaAccounts({});
          break;
        case "Havale":
          this.fetchBanks();
          this.filterBankAccounts({});
          break;
        case "Kripto":
          this.fetchCryptos();
          this.filterCryptoAccounts({});
          break;
      }
    },
  },
  created() {
    this.fetchPaymentTypes();
  },
  methods: {
    ...mapActions("paymentType", {
      fetchPaymentTypes: FETCH_PAYMENT_TYPES,
    }),
    ...mapActions("paparaAccount", {
      filterPaparaAccounts: FILTER_PAPARA_ACCOUNTS,
    }),
    ...mapActions("bankAccount", {
      filterBankAccounts: FILTER_BANK_ACCOUNTS,
    }),
    ...mapActions("cryptoAccount", {
      filterCryptoAccounts: FILTER_CRYPTO_ACCOUNTS,
    }),
    ...mapActions("bank", {
      fetchBanks: FETCH_BANKS,
    }),
    ...mapActions("crypto", {
      fetchCryptos: FETCH_CRYPTOS,
    }),
  },
};
</script>
