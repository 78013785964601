<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Ban Bilgileri Güncelleme</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-show="error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="success"
            color="primary"
            type="success"
            icon="mdi-cloud-check"
            >Bilgiler Güncellendi</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            >
            </v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.remainingBalance"
                  :rules="remainingBalanceRules"
                  label="Kalan Bakiye"
                  type="number"
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.deductionAmount"
                  :rules="deductionAmountRules"
                  label="Kesinti Miktarı"
                  type="number"
                  :disabled="success"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="pl-10 pr-10"
            v-show="!success && !loading"
            @click="save"
            >Kaydet</v-btn
          >
          <v-btn
            outlined
            class="pl-4 pr-4 ml-2"
            color="grey"
            text
            @click="toggleDialog"
            >Kapat</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  UPDATE_PAPARA_ACCOUNT_BAN_INFO,
  TOGGLE_BAN_DIALOG,
} from "../../../../store/modules/account/paparaAccount";

export default {
  data: () => ({
    id: "",
    item: {
      remainingBalance: 0,
      deductionAmount: 0,
    },
    remainingBalanceRules: [
      (v) => v >= 0 || "Lütfen Kalan Bakiyeyi minimum 0 olarak giriniz",
    ],
    deductionAmountRules: [
      (v) => v >= 0 || "Lütfen Kesinti miktarını minimum 0 olarak giriniz",
    ],
  }),
  computed: {
    ...mapState("paparaAccount", {
      updateItem: (state) => state.update.item,
      dialog: (state) => state.ban.dialog,
      loading: (state) => state.update.isLoading,
      error: (state) => state.update.error,
      success: (state) => state.update.success,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
      }
    },
    updateItem(newVal) {
      this.id = newVal.id;
      this.item = {
        remainingBalance: newVal.remainingBalance ?? 0,
        deductionAmount: newVal.deductionAmount ?? 0,
      };
    },
  },
  methods: {
    ...mapActions("paparaAccount", {
      updatePaparaAccount: UPDATE_PAPARA_ACCOUNT_BAN_INFO,
    }),
    ...mapMutations("paparaAccount", {
      toggleUpdateDialog: TOGGLE_BAN_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.updatePaparaAccount({ id: this.id, ban: this.item });
    },
    toggleDialog() {
      this.toggleUpdateDialog();
    },
  },
};
</script>
