<template>
  <div>
    <v-alert type="info" icon="mdi-information-outline">Arşiv ekranlarında Listele butonuna basarak kayıtları görüntüleyebilirsiniz.</v-alert>
    <v-row>
      <v-col class="gray-border">
        <v-tabs v-model="tab">
          <v-tab v-for="item in paymentTypes" :key="item.name">
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in paymentTypes" :key="item.name">
            <component v-bind:is="item.name" :selectedstatus="status"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PaparaPayments from "./papara/PaparaPaymentArchives";
import BankPayments from "./bank/BankPaymentArchives";
import CryptoPayments from "./crypto/CryptoPaymentArchives";
import { mapState, mapActions } from "vuex";
import { FETCH_PAYMENT_TYPES } from "../../../store/modules/paymentType";
import { FETCH_PAYMENT_STATUSES } from "../../../store/modules/paymentStatus";
import { FILTER_PAPARA_PAYMENT_ARCHIVES } from "../../../store/modules/payment/paparaPayment";
import { FILTER_BANK_PAYMENT_ARCHIVES } from "../../../store/modules/payment/bankPayment";
import { FILTER_CRYPTO_PAYMENT_ARCHIVES } from "../../../store/modules/payment/cryptoPayment";

export default {
  data() {
    return {
      tab: null,
      items: [
        { name: "Papara", component: "papara-payments" },
        { name: "Banka Havalesi", component: "bank-payments" },
        { name: "Kripto Paralar", component: "crypto-payments" },
      ],
      status: "",
    };
  },
  computed: {
    ...mapState("paymentType", {
      paymentTypes: (state) => state.paymentTypes,
    }),
  },
  components: {
    Papara: PaparaPayments,
    Havale: BankPayments,
    Kripto: CryptoPayments,
  },
  watch: {
    async tab() {
      if (this.paymentTypes.length === 0) {
        await this.fetchPaymentTypes();
      }

      // switch (this.paymentTypes[newVal].name) {
      //   case "Papara":
      //     this.filterPaparaPayments({ status: this.status, itemsPerPage: 10 });
      //     break;
      //   case "Havale":
      //     this.filterBankPayments({ status: this.status, itemsPerPage: 10 });
      //     break;
      //   case "Kripto":
      //     this.filterCryptoPayments({ status: this.status, itemsPerPage: 10 });
      //     break;
      // }
    },
  },
  created() {
    this.fetchPaymentStatuses();
    this.fetchPaymentTypes();

    if (this.$route.query.status) {
      this.status = [this.$route.query.status];
    }

    if (this.$route.query.index) {
      this.tab = parseInt(this.$route.query.index, 10);
    }
  },
  methods: {
    ...mapActions("paymentType", {
      fetchPaymentTypes: FETCH_PAYMENT_TYPES,
    }),
    ...mapActions("paymentStatus", {
      fetchPaymentStatuses: FETCH_PAYMENT_STATUSES,
    }),
    ...mapActions("paparaPayment", {
      filterPaparaPayments: FILTER_PAPARA_PAYMENT_ARCHIVES,
    }),
    ...mapActions("bankPayment", {
      filterBankPayments: FILTER_BANK_PAYMENT_ARCHIVES,
    }),
    ...mapActions("cryptoPayment", {
      filterCryptoPayments: FILTER_CRYPTO_PAYMENT_ARCHIVES,
    }),
  },
};
</script>
