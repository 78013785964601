<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" depressed v-on="on">
        <v-icon left>mdi-plus-thick</v-icon>Yeni</v-btn
      >
    </template>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Banka Oluştur</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-show="error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
            >Banka Oluşturuldu</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.name"
                  label="Banka Adı"
                  required
                  :rules="nameRules"
                  maxlength="50"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                ><v-switch
                  color="blue"
                  label="FAST olarak tanımla *"
                  v-model="item.isFast"
                  :rules="isFastRules"
                  :disabled="success"
                ></v-switch>
                <div class="pl-10">
                  * Seçildiğinde bu banka için kullanıcıya Fast ile yatırım
                  uyarısı gösterilir.
                </div></v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="pl-10 pr-10"
            v-show="!success && !loading"
            @click="save"
            >Kaydet</v-btn
          >
          <v-btn
            outlined
            class="pl-4 pr-4 ml-2"
            color="grey"
            text
            @click="dialog = false"
            >{{ !success && !loading ? "İptal" : "Kapat" }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { CREATE_BANK, TOGGLE_CREATE_DIALOG } from "../../../store/modules/bank";

export default {
  data: () => ({
    dialog: false,
    item: {
      name: "",
      isFast: false,
    },
    nameRules: [(v) => !!v || "Banka adını giriniz"],
    isFastRules: [
      (v) => v !== null || v !== undefined || "Fast bilgisini giriniz",
    ],
  }),
  computed: {
    ...mapState("bank", {
      loading: (state) => state.create.isLoading,
      error: (state) => state.create.error,
      success: (state) => state.create.success,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
        this.clearCreateDialog();
        this.item = { name: "", isFast: false };
      }
    },
  },
  methods: {
    ...mapActions("bank", {
      createBank: CREATE_BANK,
    }),
    ...mapMutations("bank", {
      clearCreateDialog: TOGGLE_CREATE_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.createBank(this.item);
    },
  },
};
</script>
