<template>
  <v-alert dense text color="info" icon="mdi-bell-ring" border="left" transition="scale-transition" style="display: inline-block">
    Yeni <strong>{{ this.paymentType }}</strong> çekim talebiniz var!
    <v-btn x-small class="ml-3" v-on:click="redirect">Kontrol et</v-btn>
  </v-alert>
</template>

<script>
import { mapMutations } from "vuex";
import { RESET_NEW_WITHDRAW } from "../store/modules/global";

export default {
  props: ["paymentType"],
  methods: {
    ...mapMutations("global", {
      resetNewWithdraw: RESET_NEW_WITHDRAW,
    }),
    redirect() {
      this.resetNewWithdraw();

      switch (this.paymentType) {
        case "Papara":
          this.$router.push({ path: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/withdraws/papara" });
          break;
        case "Havale":
          this.$router.push({ path: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/withdraws/bank" });
          break;
        case "Kripto":
          this.$router.push({ path: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/withdraws/crypto" });
          break;
        default:
          this.$router.push({ path: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/withdraws" });
          break;
      }
    },
  },
};
</script>
