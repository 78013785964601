import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import config from "../config";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#444",
        oldPrimary: "#009688",
        secondary: "#7ccc63",
        accent: "#8c9eff",
        error: "#b71c1c",
        red: "#e74c3d",
        textcolor: "#757575",
        lightgray: "#f0f0f0",
        darkgray: "#333333",
        waitingText: "#fff",
        waiting: "#fb8c00",
        successText: "#d4edda",
        success: "#4caf50",
        dangerText: "#f8d7da",
        danger: "#ff5252",
        papara: "#512da8",
        paparaSecondary: "#df4656",
        casibom: config.MENU_COLOR,
        cyellow: config.APP_COLOR,
      },
    },
  },
});
