<template>
  <div>
    <v-skeleton-loader
      v-if="!cryptos"
      dark
      v-bind="attrs"
      type="article, actions"
      class="mx-auto my-12"
      max-width="600"
    ></v-skeleton-loader>
    <v-card class="mx-auto my-12" dark max-width="600" v-if="cryptos">
      <v-subheader class="font-weight-bold">
        Kripto Paralar
      </v-subheader>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <div class="grey--text">
          Lütfen ödeme yapmak istediğiniz birimi seçiniz:
        </div>
        <v-spacer class="pa-1"></v-spacer>
        <v-row justify="space-around">
          <v-col
            v-for="(crypto, index) in cryptos"
            :key="index"
            md="4"
            sm="4"
            xs="6"
          >
            <v-btn
              block
              elevation="2"
              class="cyellow"
              small
              light
              @click="select(crypto.id)"
            >
              {{ crypto.name }}
              <small class="ml-1">({{ crypto.code }})</small>
            </v-btn>
          </v-col>
        </v-row>
        <redirect-to-site />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { CRYPTO_SELECT } from "../../../store/modules/payment/payment";
import RedirectToSite from "../Redirect";

export default {
  components: {
    "redirect-to-site": RedirectToSite,
  },
  data: () => ({
    attrs: {
      class: "mb-12",
      boilerplate: false,
      elevation: 2,
    },
  }),
  computed: {
    ...mapState("payment", {
      cryptos: (state) => state.data.cryptos,
    }),
  },
  methods: {
    ...mapActions("payment", {
      cryptoSelect: CRYPTO_SELECT,
    }),
    select(cryptoId) {
      this.cryptoSelect({ tokenId: this.$route.params.tokenId, cryptoId });
    },
  },
};
</script>
