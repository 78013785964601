<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Not Güncelleme</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 200px;">
        <v-form ref="form">
          <v-alert
            v-show="error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="success"
            color="primary"
            type="success"
            icon="mdi-cloud-check"
            >Not Güncellendi</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            >
            </v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="4" md="9">
                <v-textarea
                  v-model="item.note"
                  label="Not"
                  auto-grow
                  rows="1"
                  maxlength="200"
                  counter
                  :disabled="success"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="2" md="3">
                <v-btn color="grey darken-1" text @click="item.note = ''"
                  >Temizle</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="pl-10 pr-10"
          v-show="!success && !loading"
          @click="save"
          >Kaydet</v-btn
        >
        <v-btn
          outlined
          class="pl-4 pr-4 ml-2"
          color="grey"
          text
          @click="toggleDialog"
          >Kapat</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  UPDATE_PAPARA_ACCOUNT_NOTE,
  TOGGLE_NOTE_DIALOG,
} from "../../../../store/modules/account/paparaAccount";

export default {
  data: () => ({
    id: "",
    item: {
      note: "",
    },
  }),
  computed: {
    ...mapState("paparaAccount", {
      updateItem: (state) => state.update.item,
      dialog: (state) => state.note.dialog,
      loading: (state) => state.update.isLoading,
      error: (state) => state.update.error,
      success: (state) => state.update.success,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
      }
    },
    updateItem(newVal) {
      this.id = newVal.id;
      this.item = {
        note: newVal.note,
      };
    },
  },
  methods: {
    ...mapActions("paparaAccount", {
      updatePaparaAccount: UPDATE_PAPARA_ACCOUNT_NOTE,
    }),
    ...mapMutations("paparaAccount", {
      toggleUpdateDialog: TOGGLE_NOTE_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.updatePaparaAccount({ id: this.id, note: this.item });
    },
    toggleDialog() {
      this.toggleUpdateDialog();
    },
  },
};
</script>
