<template>
  <div>
    <!-- mobile header -->
    <v-app-bar class="mobileHeader" app color="cyellow">
      <v-btn icon large @click.stop="drawer = !drawer" small class="mr-2">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <strong> {{ user && user.name }}</strong>
      <span class="mr-2 ml-2"> • </span>
      <span>{{ user && user.role && user.role.name }}</span>
    </v-app-bar>
    <!-- mobile header -->

    <v-navigation-drawer fixed left light color="cyellow" v-model="drawer" :mini-variant.sync="mini" :permanent="$vuetify.breakpoint.mdAndUp">
      <template v-slot:prepend>
        <v-list-item class="px-1">
          <v-list-item-avatar style="border-radius: 0;">
            <img :src="getLogoUrl()" style="width:34px;height:34px; margin-left:5px" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user && user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user && user.role && user.role.name }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-btn class="btnMini" icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list nav dense>
        <div v-for="item in pages" :key="item.title">
          <v-list id="withdraw-menu" dense v-if="item.name == 'Payments'">
            <v-list-group :value="false" append-icon="" :prepend-icon="item.icon" color="black">
              <template v-slot:activator>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </template>
              <v-list-item link :to="getPaymentUrl(item.name)" v-for="item in paymentTypes" :key="item.name">
                <v-list-item-title>{{ item.name }} <v-icon color="gray" size="medium" class="float-right">mdi-chevron-right</v-icon></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
          <slot name="leftNavOther" v-else-if="item.name == 'Withdraws' && authControl('Withdraws')">
            <v-list id="withdraw-menu" dense>
              <v-list-group :value="false" append-icon="" prepend-icon="mdi-account-cash" color="black">
                <template v-slot:activator>
                  <v-list-item-title>
                    Çekimler
                  </v-list-item-title>
                </template>
                <v-list-item link :to="getWithdrawUrl(item.name)" v-for="item in paymentTypes" :key="item.name">
                  <v-list-item-title>{{ item.name }} <v-icon color="gray" size="medium" class="float-right">mdi-chevron-right</v-icon></v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list>
          </slot>
          <slot name="leftNavOther" v-if="item.name == 'Archive' && authControl('Archive')">
            <v-list id="withdraw-menu" dense>
              <v-list-group :value="false" append-icon="" prepend-icon="mdi-archive" color="black">
                <template v-slot:activator>
                  <v-list-item-title>
                    Arşiv
                  </v-list-item-title>
                </template>
                <v-list-item link to="/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/archive/payments" v-if="authControl('PaymentArchives')">
                  <v-list-item-title>Yatırım <v-icon color="gray" size="medium" class="float-right">mdi-chevron-right</v-icon></v-list-item-title>
                </v-list-item>
                <v-list-item link to="/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/archive/withdraws" v-if="authControl('WithdrawArchives')">
                  <v-list-item-title>Çekim <v-icon color="gray" size="medium" class="float-right">mdi-chevron-right</v-icon></v-list-item-title>
                </v-list-item>
                <v-list-item link to="/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/archive/jobs" v-if="authControl('Jobs')">
                  <v-list-item-title>Görevler <v-icon color="gray" size="medium" class="float-right">mdi-chevron-right</v-icon></v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list>
          </slot>
          <v-divider v-else-if="item.name == 'Cache'"></v-divider>
          <v-list-item link :to="item.to" exact v-if="item.visible">
            <v-list-item-icon>
              <v-icon :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <template v-slot:append>
        <v-divider />
        <v-btn block text color="black" v-on:click="doLogout">
          <v-icon class="mr-2">mdi-logout-variant</v-icon>
          <span v-if="!mini">Çıkış Yap</span>
        </v-btn>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { logout } from "../auth";
import { mapState, mapActions, mapMutations } from "vuex";
import { FETCH_USER } from "../store/modules/user";
import { SET_MINI } from "../store/modules/global";
import { FETCH_PAYMENT_TYPES } from "../store/modules/paymentType";
import sound from "../assets/sounds/coin.mp3";
import config from "../config";

var audio = new Audio(sound);

export default {
  data() {
    return {
      pages: [],
      drawer: null,
      mini: false,
      interval: null,
      items: [
        { title: "Dashboard", icon: "mdi-folder-home", color: "gray darken-2", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J", default: true, visible: true },
        { title: "Yatırımlar", name: "Payments", icon: "mdi-cash-multiple", color: "gray darken-2", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/payments", visible: false },
        { title: "Hesaplar", name: "Accounts", icon: "mdi-account-supervisor-circle", color: "gray darken-2", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/accounts", visible: true },
        { title: "Kullanıcılar", name: "Users", icon: "mdi-account-details-outline", color: "gray darken-2", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/users", visible: true },
        { title: "Ayarlar", icon: "mdi-tune-variant", color: "gray darken-2", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/settings", default: true, visible: true },
        { title: "Yatırım", name: "PaymentArchives", icon: "mdi-archive-search", color: "gray darken-2", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/archive/payments", visible: false },
        { title: "Çekim", name: "WithdrawArchives", icon: "mdi-archive-search", color: "gray darken-2", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/archive/whitdraws", visible: false },
        { title: "Görev", name: "Jobs", icon: "mdi-archive-search", color: "gray darken-2", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/archive/jobs", visible: false },
        { title: "Çekimler", name: "Withdraws", icon: "mdi-account-cash", color: "gray darken-2", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/withdraws", visible: false },
        { title: "Arşiv", name: "Archive", icon: "mdi-account-cash", color: "gray darken-2", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/archive", visible: false },
        { title: "Cache", name: "Cache", icon: "mdi-floppy", color: "gray darken-2", to: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/cache", visible: true },
      ],
      mapping: new Map([
        ["Papara", "papara"],
        ["Havale", "bank"],
        ["Kripto", "crypto"],
      ]),
    };
  },
  computed: {
    ...mapState("user", {
      user: (state) => state.user,
    }),
    ...mapState("paymentType", {
      paymentTypes: (state) => state.paymentTypes,
    }),
    ...mapState("global", {
      hasNewPayment: (state) => state.hasNewPayment,
      paymentType: (state) => state.paymentType,
    }),
  },
  watch: {
    user(newVal) {
      if (newVal) {
        let names = newVal.pages.map((page) => page.name);
        this.pages = this.items.filter((item) => item.default || names.includes(item.name));
      }
    },
    mini(newVal) {
      this.setMini(newVal);
    },
    paymentType(newVal, oldVal) {
      if (newVal === null) {
        this.resetTitle();
      } else if (newVal !== oldVal) {
        this.playSound();
        this.changeTitle();
      }
    },
  },
  created() {
    this.fetchPaymentTypes();
    this.setMini(false);
    this.fetchUser();
  },
  methods: {
    ...mapActions("user", {
      fetchUser: FETCH_USER,
    }),
    ...mapActions("paymentType", {
      fetchPaymentTypes: FETCH_PAYMENT_TYPES,
    }),
    ...mapMutations("global", {
      setMini: SET_MINI,
    }),
    doLogout() {
      logout();
      this.resetTitle();
      window.location.href = "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/login";
    },
    playSound() {
      audio.play();
    },
    changeTitle() {
      let type = this.paymentType;
      this.interval = setInterval(() => {
        document.title = document.title === config.TITLE ? `${type} (1)` : config.TITLE;
      }, 1500);
    },
    resetTitle() {
      if (this.interval !== null) {
        clearInterval(this.interval);
        this.interval = null;
        document.title = config.TITLE;
      }
    },
    getWithdrawUrl(paymentType) {
      const page = this.items.find((item) => item.name == "Withdraws");
      return `${page.to}/${this.mapping.get(paymentType)}`;
    },
    getPaymentUrl(paymentType) {
      const page = this.items.find((item) => item.name == "Payments");
      return `${page.to}/${this.mapping.get(paymentType)}`;
    },
    authControl(name) {
      return !!this.pages.find((a) => a.name == name);
    },
    getLogoUrl() {
      return `${config.ASSET_URL}${config.MENU_LOGO}`;
    },
  },
};
</script>

<style scoped>
#withdraw-menu .v-list-item__icon {
  display: none !important;
}
#withdraw-menu {
  padding: 0;
  margin: 4px 0;
}
.v-list .v-list-group--active {
  color: black;
  font-weight: bold;
  border-radius: 4px;
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.v-list .v-list-group--active a.v-list-item {
  margin: 0 5px 5px 5px;
}
</style>
