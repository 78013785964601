<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn outlined depressed v-on="on" block>Kripto Listesi</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Kripto Listesi</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 450px;">
        <v-data-table :headers="headers" :items="cryptos" hide-default-footer disable-pagination :search="search" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-row>
                <v-col cols="10" sm="8" md="8">
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                </v-col>
              </v-row>
              <new-crypto-dialog />
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed class="mx-1" v-on="on">
                  <v-switch v-model="item.isActive" :loading="statusIsLoading" @click="changeStatus(item.id)" class="my-1" color="green" hide-details></v-switch>
                </v-btn>
              </template>
              <span>{{ item.isActive ? "Kriptoyu Pasif Et" : "Kriptoyu Aktif Et" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="dialog = false">Kapat</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { FETCH_CRYPTOS, CHANGE_CRYPTO_STATUS } from "../../../store/modules/crypto";
import NewCryptoDialog from "./NewCryptoDialog";

export default {
  data: () => ({
    dialog: false,
    search: "",
    headers: [
      {
        text: "Kripto",
        sortable: false,
        value: "name",
      },
      {
        text: "Kod",
        sortable: false,
        value: "code",
      },
      {
        text: "Durum",
        sortable: false,
        value: "status",
      },
      {
        text: "İşlemler",
        sortable: false,
        value: "actions",
      },
    ],
  }),
  components: {
    "new-crypto-dialog": NewCryptoDialog,
  },
  computed: {
    ...mapState("crypto", {
      cryptos: (state) =>
        state.cryptos.map((item) => ({
          ...item,
          status: item.isActive ? "Aktif" : "Pasif",
        })),
      isLoading: (state) => state.isLoading,
      error: (state) => state.error || state.status.error,
      statusIsLoading: (state) => state.status.isLoading,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) this.fetchCryptos();
    },
  },
  methods: {
    ...mapActions("crypto", {
      fetchCryptos: FETCH_CRYPTOS,
      changeStatus: CHANGE_CRYPTO_STATUS,
    }),
  },
};
</script>
