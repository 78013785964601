<template>
  <v-container fluid class="bg-img">
    <v-row class="mb-6" justify="center" align="center">
      <v-col lg="4" md="5">
        <v-card class="pa-4 rounded" outlined tile elevation="2">
          <v-progress-linear v-show="isLoading" indeterminate color="yellow darken-2"></v-progress-linear>
          <v-alert v-if="errorMessage" text prominent type="error" icon="mdi-alert-circle">{{ errorMessage }}</v-alert>
          <v-toolbar color="lightgray" flat>
            <v-toolbar-title>
              <v-icon color="primary" class="mr-4">mdi-badge-account-outline</v-icon>
              <span>Lütfen giriş yapınız</span>
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-text-field v-model="email" :rules="emailRules" label="E-Posta" required v-on:keyup.enter="login"></v-text-field>
              <v-text-field v-model="password" :rules="passwordRules" label="Parola" type="password" required v-on:keyup.enter="login"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" v-on:click="login" type="button">
              GİRİŞ YAP
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
body {
  padding: 0;
}
</style>
<script>
import AdminApi from "../../api/adminApi";

export default {
  data: () => ({
    email: "",
    password: "",
    emailRules: [(v) => !!v || "Lütfen e-posta adresini giriniz", (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Lütfen geçerli bir e*posta adresi giriniz"],
    passwordRules: [(v) => !!v || "Lütfen parolanızı giriniz"],
    isLoading: false,
    errorMessage: "",
  }),
  methods: {
    async login() {
      this.errorMessage = "";

      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        this.isLoading = true;

        const result = await AdminApi.login({
          email: this.email,
          password: this.password,
        });

        if (result.refreshToken) {
          window.location.href = "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J";
        } else {
          window.location.href = "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/auth";
        }
      } catch (error) {
        this.errorMessage = error.response.data;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
