<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" depressed v-on="on"> <v-icon left>mdi-plus-thick</v-icon>Yeni Arşiv Görevi</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Job Oluştur</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 550px;">
        <v-form ref="form">
          <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
          <v-alert v-show="success" text prominent type="success" icon="mdi-cloud-check">Görev Oluşturuldu</v-alert>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-datetime-picker label="Başlangıç Tarihi" v-model="item.startDate" :rules="startDateRules" :text-field-props="{ appendIcon: 'mdi-calendar-today' }" :disabled="success">
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar-blank-multiple</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock-time-four-outline</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-datetime-picker label="Bitiş Tarihi" v-model="item.endDate" :rules="endDateRules" :text-field-props="{ appendIcon: 'mdi-calendar-today' }" :disabled="success">
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar-blank-multiple</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock-time-four-outline</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="item.cron" label="Cron (Second Minute Hour Day Month *)" required :rules="cronRules" :disabled="success"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete v-model="item.timezone" :items="timezones" clearable dense outlined label="Timezone Seçiniz" :rules="timezoneRules" :disabled="success"></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete v-model="item.type" :items="types" clearable dense outlined label="Tip Seçiniz" :rules="typeRules" :disabled="success"></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="item.paymentTypeId"
                  :items="paymentTypes"
                  clearable
                  dense
                  outlined
                  item-text="name"
                  item-value="id"
                  label="Ödeme Yöntemi Seçiniz"
                  :rules="paymentTypeIdRules"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" class="pl-10 pr-10" v-show="!success && !loading" @click="save">Kaydet</v-btn>
        <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="dialog = false">{{ !success && !loading ? "İptal" : "Kapat" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { CREATE_JOB, TOGGLE_CREATE_DIALOG } from "../../../store/modules/job";
import moment from "moment";
import { FETCH_PAYMENT_TYPES } from "../../../store/modules/paymentType";

export default {
  data: () => ({
    dialog: false,
    item: { startDate: "", endDate: "", cron: "", timezone: "", type: "", paymentTypeId: "" },
    startDateRules: [(v) => !!v || "Lütfen başlangıç tarihini giriniz", (v) => moment(v).isValid() || "Lütfen başlangıç tarihini giriniz"],
    endDateRules: [(v) => !!v || "Lütfen bitiş tarihini giriniz", (v) => moment(v).isValid() || "Lütfen bitiş tarihini giriniz"],
    cronRules: [(v) => !!v || "Lütfen cron bilgisini giriniz"],
    timezoneRules: [(v) => !!v || "Lütfen timezone bilgisini seçiniz"],
    typeRules: [(v) => !!v || "Lütfen tip seçiniz"],
    paymentTypeIdRules: [(v) => !!v || "Lütfen ödeme yöntemi seçiniz"],
    timezones: ["Europe/London", "Europe/Istanbul"],
    types: ["Deposit", "Withdraw"],
  }),
  computed: {
    ...mapState("job", {
      loading: (state) => state.create.isLoading,
      error: (state) => state.create.error,
      success: (state) => state.create.success,
    }),
    ...mapState("paymentType", {
      paymentTypes: (state) => state.paymentTypes,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
        this.clearCreateDialog();
        this.item = { startDate: "", endDate: "", cron: "", timezone: "", type: "", paymentTypeId: "" };
      }
    },
  },
  methods: {
    ...mapActions("job", {
      createJob: CREATE_JOB,
    }),
    ...mapActions("paymentType", {
      fetchPaymentTypes: FETCH_PAYMENT_TYPES,
    }),
    ...mapMutations("job", {
      clearCreateDialog: TOGGLE_CREATE_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.createJob(this.item);
    },
  },
};
</script>
