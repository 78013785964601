import AdminApi from "../../../api/adminApi";

export const FILTER_BANK_PAYMENTS = "FILTER_BANK_PAYMENTS";
export const FILTER_BANK_PAYMENT_ARCHIVES = "FILTER_BANK_PAYMENT_ARCHIVES";
export const FILTER_BANK_PAYMENTS_BY_STATUS = "FILTER_BANK_PAYMENTS_BY_STATUS";
export const FILTER_BANK_LAST_PAYMENTS = "FILTER_BANK_LAST_PAYMENTS";
export const APPROVE_BANK_PAYMENT = "APPROVE_BANK_PAYMENT";
export const REJECT_BANK_PAYMENT = "REJECT_BANK_PAYMENT";
export const GET_STATUS_BY_COUNT = "GET_STATUS_BY_COUNT";

export const SET_BANK_PAYMENTS = "SET_BANK_PAYMENTS";
export const SET_BANK_PAYMENT_ARCHIVES = "SET_BANK_PAYMENT_ARCHIVES";
export const SET_BANK_PAYMENTS_COUNT = "SET_BANK_PAYMENTS_COUNT";
export const SET_BANK_PAYMENT_ARCHIVES_COUNT = "SET_BANK_PAYMENT_ARCHIVES_COUNT";
export const SET_BANK_PAYMENTS_COUNT_BY_STATUS = "SET_BANK_PAYMENTS_COUNT_BY_STATUS";
export const SET_BANK_LAST_PAYMENTS = "SET_BANK_LAST_PAYMENTS";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";

export const TOGGLE_APPROVE_DIALOG = "TOGGLE_APPROVE_DIALOG";
export const SET_APPROVE_IS_LOADING = "SET_APPROVE_IS_LOADING";
export const SET_APPROVE_SUCCESS = "SET_APPROVE_SUCCESS";
export const SET_APPROVE_ERROR = "SET_APPROVE_ERROR";

export const TOGGLE_REJECT_DIALOG = "TOGGLE_REJECT_DIALOG";
export const SET_REJECT_IS_LOADING = "SET_REJECT_IS_LOADING";
export const SET_REJECT_SUCCESS = "SET_REJECT_SUCCESS";
export const SET_REJECT_ERROR = "SET_REJECT_ERROR";

export const SET_STATUS_COUNTS = "SET_STATUS_COUNTS";
export const SET_STATUS_IS_LOADING = "SET_STATUS_IS_LOADING";
export const SET_STATUS_ERROR = "SET_STATUS_ERROR";

export const SET_BANK_REFRESH = "SET_BANK_REFRESH";
export const ADD_BANK_PAYMENT = "ADD_BANK_PAYMENT";
export const REPLACE_BANK_PAYMENT = "REPLACE_BANK_PAYMENT";

export const SET_BANK_LAST_PAYMENTS_IS_LOADING = "SET_BANK_LAST_PAYMENTS_IS_LOADING";
export const SET_BANK_LAST_PAYMENTS_ERROR = "SET_BANK_LAST_PAYMENTS_ERROR";

export const SET_FILTERS = "SET_FILTERS";

export const GET_HISTORY = "GET_HISTORY";
export const GET_ARCHIVE_HISTORY = "GET_ARCHIVE_HISTORY";

export const TOGGLE_HISTORY_DIALOG = "TOGGLE_HISTORY_DIALOG";
export const SET_HISTORY = "SET_HISTORY";
export const SET_HISTORY_IS_LOADING = "SET_HISTORY_IS_LOADING";
export const SET_HISTORY_ERROR = "SET_HISTORY_ERROR";

export const TOGGLE_ARCHIVE_HISTORY_DIALOG = "TOGGLE_ARCHIVE_HISTORY_DIALOG";
export const SET_ARCHIVE_HISTORY = "SET_ARCHIVE_HISTORY";
export const SET_ARCHIVE_HISTORY_IS_LOADING = "SET_ARCHIVE_HISTORY_IS_LOADING";
export const SET_ARCHIVE_HISTORY_ERROR = "SET_ARCHIVE_HISTORY_ERROR";

const state = {
  bankPayments: [],
  bankPaymentArchives: [],
  totalCount: 0,
  archiveTotalCount: 0,
  countList: [],
  isLoading: false,
  error: "",
  refresh: false,
  filters: { startDate: "", endDate: "", status: [], name: "", surname: "", loginId: "", accounts: [] },
  approve: {
    item: null,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  reject: {
    item: null,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  status: {
    count: [],
    isLoading: false,
    error: "",
  },
  lastPayments: {
    payments: [],
    isLoading: false,
    error: "",
  },
  history: {
    history: [],
    id: "",
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  archiveHistory: {
    history: [],
    id: "",
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
};

const actions = {
  [FILTER_BANK_PAYMENTS]: async ({ commit }, queryItems) => {
    try {
      commit(SET_IS_LOADING, true);
      commit(SET_FILTERS, queryItems);

      let result = await AdminApi.filterBankPayments(queryItems);

      commit(SET_BANK_PAYMENTS, result.rows);
      commit(SET_BANK_PAYMENTS_COUNT, result.count);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [FILTER_BANK_PAYMENT_ARCHIVES]: async ({ commit }, queryItems) => {
    try {
      commit(SET_IS_LOADING, true);

      let result = await AdminApi.filterBankPaymentArchives(queryItems);

      commit(SET_BANK_PAYMENT_ARCHIVES, result.rows);
      commit(SET_BANK_PAYMENT_ARCHIVES_COUNT, result.count);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [FILTER_BANK_PAYMENTS_BY_STATUS]: async ({ commit }, queryItems) => {
    try {
      let countList = await AdminApi.filterBankPaymentsByStatus(queryItems);

      commit(SET_BANK_PAYMENTS_COUNT_BY_STATUS, countList);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    }
  },
  [FILTER_BANK_LAST_PAYMENTS]: async ({ commit }, queryItems) => {
    try {
      commit(SET_BANK_LAST_PAYMENTS, []);
      commit(SET_BANK_LAST_PAYMENTS_ERROR, "");
      commit(SET_BANK_LAST_PAYMENTS_IS_LOADING, true);

      let list = await AdminApi.filterBankLastPayments(queryItems);

      commit(SET_BANK_LAST_PAYMENTS, list);
    } catch (error) {
      commit(SET_BANK_LAST_PAYMENTS_ERROR, error.response.data);
    } finally {
      commit(SET_BANK_LAST_PAYMENTS_IS_LOADING, false);
    }
  },
  [APPROVE_BANK_PAYMENT]: async ({ commit }, id) => {
    try {
      commit(SET_APPROVE_IS_LOADING, true);

      await AdminApi.approveBankPayment(id);

      commit(SET_APPROVE_SUCCESS, true);
      commit(TOGGLE_APPROVE_DIALOG, null);
      commit(SET_BANK_REFRESH, true);
    } catch (error) {
      commit(
        SET_APPROVE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_APPROVE_IS_LOADING, false);
    }
  },
  [REJECT_BANK_PAYMENT]: async ({ commit }, { id, descriptionId }) => {
    try {
      commit(SET_REJECT_IS_LOADING, true);

      await AdminApi.rejectBankPayment(id, descriptionId);

      commit(SET_REJECT_SUCCESS, true);
      commit(TOGGLE_REJECT_DIALOG, null);
      commit(SET_BANK_REFRESH, true);
    } catch (error) {
      commit(
        SET_REJECT_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_REJECT_IS_LOADING, false);
    }
  },
  [GET_STATUS_BY_COUNT]: async ({ commit }) => {
    try {
      commit(SET_STATUS_IS_LOADING, true);

      let statusCounts = await AdminApi.getBankStatusByCount();

      commit(SET_STATUS_COUNTS, statusCounts);
    } catch (error) {
      commit(SET_STATUS_ERROR, error.response.data);
    } finally {
      commit(SET_STATUS_IS_LOADING, false);
    }
  },
  [GET_HISTORY]: async ({ commit }, id) => {
    try {
      commit(SET_HISTORY_IS_LOADING, true);

      let result = await AdminApi.getBankPaymentHistory(id);

      commit(SET_HISTORY, result);
    } catch (error) {
      commit(SET_HISTORY_ERROR, error.response.data);
    } finally {
      commit(SET_HISTORY_IS_LOADING, false);
    }
  },
  [GET_ARCHIVE_HISTORY]: async ({ commit }, id) => {
    try {
      commit(SET_ARCHIVE_HISTORY_IS_LOADING, true);

      let result = await AdminApi.getBankPaymentArchiveHistory(id);

      commit(SET_ARCHIVE_HISTORY, result);
    } catch (error) {
      commit(SET_ARCHIVE_HISTORY_ERROR, error.response.data);
    } finally {
      commit(SET_ARCHIVE_HISTORY_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_BANK_PAYMENTS]: (state, bankPayments) => (state.bankPayments = bankPayments),
  [SET_BANK_PAYMENT_ARCHIVES]: (state, bankPayments) => (state.bankPaymentArchives = bankPayments),
  [SET_BANK_PAYMENTS_COUNT]: (state, totalCount) => (state.totalCount = totalCount),
  [SET_BANK_PAYMENT_ARCHIVES_COUNT]: (state, totalCount) => (state.archiveTotalCount = totalCount),
  [SET_BANK_PAYMENTS_COUNT_BY_STATUS]: (state, countList) => (state.countList = countList),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),

  [TOGGLE_APPROVE_DIALOG]: (state, item = null) => {
    state.approve.item = item;
    state.approve.dialog = !state.approve.dialog;
    state.approve.isLoading = false;
    state.approve.success = false;
    state.approve.error = "";
    state.lastPayments.payments = [];
    state.lastPayments.error = "";
  },
  [SET_APPROVE_IS_LOADING]: (state, isLoading) => (state.approve.isLoading = isLoading),
  [SET_APPROVE_SUCCESS]: (state, success) => (state.approve.success = success),
  [SET_APPROVE_ERROR]: (state, error) => (state.approve.error = error),

  [TOGGLE_REJECT_DIALOG]: (state, item = null) => {
    state.reject.item = item;
    state.reject.dialog = !state.reject.dialog;
    state.reject.isLoading = false;
    state.reject.success = false;
    state.reject.error = "";
    state.lastPayments.payments = [];
    state.lastPayments.error = "";
  },
  [SET_REJECT_IS_LOADING]: (state, isLoading) => (state.reject.isLoading = isLoading),
  [SET_REJECT_SUCCESS]: (state, success) => (state.reject.success = success),
  [SET_REJECT_ERROR]: (state, error) => (state.reject.error = error),

  [SET_STATUS_COUNTS]: (state, statusCounts) => (state.status.count = statusCounts),
  [SET_STATUS_IS_LOADING]: (state, isLoading) => (state.status.isLoading = isLoading),
  [SET_STATUS_ERROR]: (state, error) => (state.status.error = error),

  [SET_BANK_REFRESH]: (state, refresh) => (state.refresh = refresh),
  [ADD_BANK_PAYMENT]: (state, data) => {
    if (state.filters.page > 1) return;
    if (state.filters.status.length > 0 && !state.filters.status.includes(data.paymentStatus.id)) return;
    if (state.filters.startDate && new Date(data.createdAt) <= new Date(state.filters.startDate)) return;
    if (state.filters.endDate && new Date(data.createdAt) >= new Date(state.filters.endDate)) return;
    if (state.filters.name && !data.customer.name.toLowerCase().includes(state.filters.name.toLowerCase())) return;
    if (state.filters.surname && !data.customer.surname.toLowerCase().includes(state.filters.surname.toLowerCase())) return;
    if (state.filters.accounts.length > 0 && !state.filters.accounts.includes(data.bankAccount.id)) return;

    if (state.filters.loginId) {
      const loginId = state.filters.loginId.split("-")[0];
      if (loginId && loginId !== data.customer.loginId) return;

      const transactionId = state.filters.loginId.split("-")[1];
      if (transactionId && transactionId !== data.transactionId) return;
    }

    state.bankPayments.unshift(data);
    state.totalCount++;
  },
  [REPLACE_BANK_PAYMENT]: (state, data) => {
    const item = state.bankPayments.find((a) => a.id === data.id);
    if (item) {
      if (state.filters.status.length > 0 && !state.filters.status.includes(data.paymentStatus.id)) {
        state.bankPayments = state.bankPayments.filter((a) => a.id != data.id);
        return;
      }

      Object.assign(item, data);

      state.bankPayments = state.bankPayments.sort((item, secondItem) => {
        if (item.paymentStatus.name == secondItem.paymentStatus.name) {
          return new Date(secondItem.updatedAt) - new Date(item.updatedAt);
        } else {
          return item.paymentStatus.sequence - secondItem.paymentStatus.sequence;
        }
      });
    }
  },
  [SET_BANK_LAST_PAYMENTS]: (state, payments) => (state.lastPayments.payments = payments),
  [SET_BANK_LAST_PAYMENTS_IS_LOADING]: (state, isLoading) => (state.lastPayments.isLoading = isLoading),
  [SET_BANK_LAST_PAYMENTS_ERROR]: (state, error) => (state.lastPayments.error = error),
  [SET_FILTERS]: (state, filters) => {
    Object.assign(state.filters, filters);
  },

  [TOGGLE_HISTORY_DIALOG]: (state, id = "") => {
    state.history.id = id;
    state.history.history = [];
    state.history.dialog = !state.history.dialog;
    state.history.isLoading = false;
    state.history.success = false;
    state.history.error = "";
  },
  [SET_HISTORY]: (state, history) => (state.history.history = history),
  [SET_HISTORY_IS_LOADING]: (state, isLoading) => (state.history.isLoading = isLoading),
  [SET_HISTORY_ERROR]: (state, error) => (state.history.error = error),

  [TOGGLE_ARCHIVE_HISTORY_DIALOG]: (state, id = "") => {
    state.archiveHistory.id = id;
    state.archiveHistory.history = [];
    state.archiveHistory.dialog = !state.archiveHistory.dialog;
    state.archiveHistory.isLoading = false;
    state.archiveHistory.success = false;
    state.archiveHistory.error = "";
  },
  [SET_ARCHIVE_HISTORY]: (state, history) => (state.archiveHistory.history = history),
  [SET_ARCHIVE_HISTORY_IS_LOADING]: (state, isLoading) => (state.archiveHistory.isLoading = isLoading),
  [SET_ARCHIVE_HISTORY_ERROR]: (state, error) => (state.archiveHistory.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
