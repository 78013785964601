<template>
  <v-main>
    <LeftNav v-if="!isLogin()" />
    <div v-bind:class="contentClassName()">
      <Notify v-if="showNotify" :paymentType="paymentType" class="mr-4 alert-notify" />
      <WithdrawNotify v-if="showWithdrawNotify" :paymentType="withdrawPaymentType" class="mr-4 alert-notify" />
      <router-view> </router-view>
    </div>
  </v-main>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { SET_LAYOUT } from "../store/modules/global";
import LeftNav from "../components/LeftNav";
import Notify from "../views/admin/Notify.vue";
import WithdrawNotify from "../components/WithdrawNotify.vue";
import config from "../config";

export default {
  data() {
    return {
      showNotify: false,
      showWithdrawNotify: false,
    };
  },
  components: {
    LeftNav,
    Notify,
    WithdrawNotify,
  },
  created() {
    document.title = config.TITLE;

    this.setLayout("Admin");
    require("../socket/admin");
  },
  computed: {
    ...mapState("global", {
      contentClass: (state) => (state.mini ? "min" : "max"),
      paymentType: (state) => state.paymentType,
      withdrawPaymentType: (state) => state.withdrawPaymentType,
    }),
  },
  watch: {
    paymentType(newVal, oldVal) {
      if (newVal === null) {
        this.changeNotify(false);
      } else if (newVal !== oldVal) {
        this.changeNotify(true);
      }
    },
    withdrawPaymentType(newVal, oldVal) {
      if (newVal === null) {
        this.changeWithdrawNotify(false);
      } else if (newVal !== oldVal) {
        this.changeWithdrawNotify(true);
      }
    },
  },
  methods: {
    ...mapMutations("global", {
      setLayout: SET_LAYOUT,
    }),
    isLogin() {
      return this.$route.name === "Login" || this.$route.name === "Auth";
    },
    contentClassName() {
      return this.isLogin() ? "" : this.contentClass;
    },
    changeNotify(status) {
      this.showNotify = status;
    },
    changeWithdrawNotify(status) {
      this.showWithdrawNotify = status;
    },
  },
};
</script>

<style>
.v-main {
  padding: 0 !important;
}
.v-navigation-drawer {
  max-width: 180px !important;
}
.max {
  padding-left: 180px;
}
.min {
  padding-left: 60px;
}
.isAdmin {
  background-color: #fff;
}
.v-card__title .headline {
  font-size: 20px !important;
  font-weight: 400;
}
body .theme--light.v-application {
  background-color: #fff;
}
.theme--light.v-select .v-select__selection--comma,
.theme--light.v-input input,
.theme--light.v-input textarea {
  font-weight: bold;
}
.v-text-field {
  padding-top: 0 !important;
}
.v-tab.v-tab--active {
  background-color: #fff;
  color: #000 !important;
  border-top: 2px solid #444 !important;
  border-radius: 4px 4px 0 0;
}
.v-slide-group__content {
  border-bottom: 2px solid #f5f5f5;
  background-color: #e0e0e0;
  margin-bottom: -2px;
}
.v-tabs-slider-wrapper {
  display: none;
}
.filter-area {
  background-image: linear-gradient(white, #e7f2f1);
  height: auto;
  border: 2px solid #fff;
}
.filter-area .v-text-field__details,
.filter-area .v-text-field__details .v-messages {
  min-height: 1px;
}
.v-input--selection-controls {
  margin-top: 0px !important;
}
.v-btn:not(.v-btn--round).v-size--small {
  padding: 0 8px !important;
}
.whitetext {
  color: white;
}
.filter-area .col-12 {
  padding-bottom: 0;
}
.v-card__title {
  font-size: 1.15rem !important;
}
.mobileHeader {
  display: none !important;
}
.container {
  padding-top: 8px;
  padding-bottom: 8px;
}
.gray-border {
  border: 1px solid #e0e0e0;
  padding: 0;
  margin: 10px;
  border-radius: 4px;
}
.v-tooltip__content {
  background: #333 !important;
}
@media screen and (max-width: 600px) {
  .v-main {
    padding: 30px 6px 6px 6px !important;
  }
  .max {
    padding-left: 0px;
  }
  .min {
    padding-left: 0px;
  }
  .v-navigation-drawer {
    max-width: 340px !important;
  }
  .btnMini {
    display: none;
  }
  .mobileHeader {
    display: block !important;
  }
  .filter-area {
    padding: 10px !important;
    height: auto;
  }
  .alert-notify.mr-4 {
    display: block !important;
    margin-right: 0 !important;
  }
}
</style>
