<template>
  <v-card class="mx-auto my-12" dark max-width="600">
    <v-subheader class="font-weight-bold cyellow--text">
      Papara
    </v-subheader>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-alert dark color="#272727" light center class="text-center"
        ><div class="text-center">
          <v-icon size="36" class="mb-3">
            mdi-alert-circle
          </v-icon>
        </div>
        <strong>Değerli Üyemiz, <br />Sistemimizde henüz sonuçlanmamış bir yatırım talebiniz bulunmaktadır.</strong><br /><br />Yeni bir yatırım yapabilmek için son
        talebinizin <u>sonuçlanmasını bekleyiniz</u>.
        <br />
        <v-btn light color="cyellow" block class="mt-5 mb-3" center :href="`/${payment.tokenId}`"
          >KONTROL ET <v-icon class="ml-2" small>mdi-arrow-right</v-icon></v-btn
        >
        <small>Bu butona tıklayarak son durumunu görebilirsiniz.</small>
      </v-alert>
      <redirect-to-site />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import RedirectToSite from "../Redirect";
export default {
  components: {
    "redirect-to-site": RedirectToSite,
  },
  computed: {
    ...mapState("payment", {
      payment: (state) => state.data,
    }),
  },
};
</script>
