<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="success" v-on="on">
        <v-icon left>mdi-plus-thick</v-icon>Yeni Hesap</v-btn
      >
    </template>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-col>
            <span class="headline">Hesap Oluştur</span>
          </v-col>
          <v-col cols="4" sm="6" md="4" v-if="isAdmin">
            <crypto-dialog />
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <v-form ref="form">
          <v-alert
            v-show="error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="success"
            color="primary"
            type="success"
            icon="mdi-cloud-check"
            >Hesap Oluşturuldu</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="item.cryptoId"
                  :rules="cryptoIdRules"
                  :items="cryptos"
                  item-text="name"
                  item-value="id"
                  dense
                  label="Kripto Seçiniz"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.number"
                  label="Cüzdan No"
                  required
                  :rules="numberRules"
                  maxlength="100"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.destinationTag"
                  label="Etiket"
                  maxlength="200"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="pl-10 pr-10"
          v-show="!success && !loading"
          @click="save"
          >Kaydet</v-btn
        >
        <v-btn
          outlined
          class="pl-4 pr-4 ml-2"
          color="grey"
          text
          @click="dialog = false"
          >{{ !success && !loading ? "İptal" : "Kapat" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  CREATE_CRYPTO_ACCOUNT,
  TOGGLE_CREATE_DIALOG,
} from "../../../../store/modules/account/cryptoAccount";
import { FETCH_CRYPTOS } from "../../../../store/modules/crypto";
import CryptoDialog from "../../crypto/CryptoDialog";

export default {
  data: () => ({
    dialog: false,
    item: {
      cryptoId: "",
      number: "",
      destinationTag: "",
    },
    cryptoIdRules: [(v) => !!v || "Lütfen kripto seçiniz"],
    numberRules: [(v) => !!v || "Lütfen cüzdan numarasını giriniz"],
  }),
  computed: {
    ...mapState("cryptoAccount", {
      loading: (state) => state.create.isLoading,
      error: (state) => state.create.error,
      success: (state) => state.create.success,
    }),
    ...mapState("crypto", {
      cryptos: (state) => state.cryptos.filter((item) => item.isActive),
    }),
    ...mapState("user", {
      isAdmin: (state) => state.user.role.isAdmin,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.fetchCryptos();
        if (this.$refs.form) this.$refs.form.reset();
        this.clearCreateDialog();
        this.item = { cryptoId: "", number: "", destinationTag: "" };
      }
    },
  },
  components: {
    "crypto-dialog": CryptoDialog,
  },
  methods: {
    ...mapActions("cryptoAccount", {
      createCryptoAccount: CREATE_CRYPTO_ACCOUNT,
    }),
    ...mapMutations("cryptoAccount", {
      clearCreateDialog: TOGGLE_CREATE_DIALOG,
    }),
    ...mapActions("crypto", {
      fetchCryptos: FETCH_CRYPTOS,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.createCryptoAccount(this.item);
    },
  },
};
</script>
