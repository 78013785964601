import AdminApi from "../../../api/adminApi";

export const FILTER_CRYPTO_PAYMENTS = "FILTER_CRYPTO_PAYMENTS";
export const FILTER_CRYPTO_PAYMENT_ARCHIVES = "FILTER_CRYPTO_PAYMENT_ARCHIVES";
export const APPROVE_CRYPTO_PAYMENT = "APPROVE_CRYPTO_PAYMENT";
export const REJECT_CRYPTO_PAYMENT = "REJECT_CRYPTO_PAYMENT";
export const GET_STATUS_BY_COUNT = "GET_STATUS_BY_COUNT";

export const SET_CRYPTO_PAYMENTS = "SET_CRYPTO_PAYMENTS";
export const SET_CRYPTO_PAYMENT_ARCHIVES = "SET_CRYPTO_PAYMENT_ARCHIVES";
export const SET_CRYPTO_PAYMENTS_COUNT = "SET_CRYPTO_PAYMENTS_COUNT";
export const SET_CRYPTO_PAYMENT_ARCHIVES_COUNT = "SET_CRYPTO_PAYMENT_ARCHIVES_COUNT";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";

export const TOGGLE_APPROVE_DIALOG = "TOGGLE_APPROVE_DIALOG";
export const SET_APPROVE_IS_LOADING = "SET_APPROVE_IS_LOADING";
export const SET_APPROVE_SUCCESS = "SET_APPROVE_SUCCESS";
export const SET_APPROVE_ERROR = "SET_APPROVE_ERROR";

export const TOGGLE_REJECT_DIALOG = "TOGGLE_REJECT_DIALOG";
export const SET_REJECT_IS_LOADING = "SET_REJECT_IS_LOADING";
export const SET_REJECT_SUCCESS = "SET_REJECT_SUCCESS";
export const SET_REJECT_ERROR = "SET_REJECT_ERROR";

export const SET_STATUS_COUNTS = "SET_STATUS_COUNTS";
export const SET_STATUS_IS_LOADING = "SET_STATUS_IS_LOADING";
export const SET_STATUS_ERROR = "SET_STATUS_ERROR";

export const SET_CRYPTO_REFRESH = "SET_CRYPTO_REFRESH";
export const ADD_CRYPTO_PAYMENT = "ADD_CRYPTO_PAYMENT";
export const REPLACE_CRYPTO_PAYMENT = "REPLACE_CRYPTO_PAYMENT";

export const GET_HISTORY = "GET_HISTORY";
export const GET_ARCHIVE_HISTORY = "GET_ARCHIVE_HISTORY";

export const TOGGLE_HISTORY_DIALOG = "TOGGLE_HISTORY_DIALOG";
export const SET_HISTORY = "SET_HISTORY";
export const SET_HISTORY_IS_LOADING = "SET_HISTORY_IS_LOADING";
export const SET_HISTORY_ERROR = "SET_HISTORY_ERROR";

export const TOGGLE_ARCHIVE_HISTORY_DIALOG = "TOGGLE_ARCHIVE_HISTORY_DIALOG";
export const SET_ARCHIVE_HISTORY = "SET_ARCHIVE_HISTORY";
export const SET_ARCHIVE_HISTORY_IS_LOADING = "SET_ARCHIVE_HISTORY_IS_LOADING";
export const SET_ARCHIVE_HISTORY_ERROR = "SET_ARCHIVE_HISTORY_ERROR";

const state = {
  cryptoPayments: [],
  cryptoPaymentArchives: [],
  totalCount: 0,
  archiveTotalCount: 0,
  isLoading: false,
  error: "",
  refresh: false,
  approve: {
    item: null,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  reject: {
    item: null,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  status: {
    count: [],
    isLoading: false,
    error: "",
  },
  history: {
    history: [],
    id: "",
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  archiveHistory: {
    history: [],
    id: "",
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
};

const actions = {
  [FILTER_CRYPTO_PAYMENTS]: async ({ commit }, queryItems) => {
    try {
      commit(SET_IS_LOADING, true);

      let result = await AdminApi.filterCryptoPayments(queryItems);

      commit(SET_CRYPTO_PAYMENTS, result.rows);
      commit(SET_CRYPTO_PAYMENTS_COUNT, result.count);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [FILTER_CRYPTO_PAYMENT_ARCHIVES]: async ({ commit }, queryItems) => {
    try {
      commit(SET_IS_LOADING, true);

      let result = await AdminApi.filterCryptoPaymentArchives(queryItems);

      commit(SET_CRYPTO_PAYMENT_ARCHIVES, result.rows);
      commit(SET_CRYPTO_PAYMENT_ARCHIVES_COUNT, result.count);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [APPROVE_CRYPTO_PAYMENT]: async ({ commit, dispatch }, id) => {
    try {
      commit(SET_APPROVE_IS_LOADING, true);

      await AdminApi.approveCryptoPayment(id);

      commit(SET_APPROVE_SUCCESS, true);
      commit(TOGGLE_APPROVE_DIALOG, null);
      dispatch(FILTER_CRYPTO_PAYMENTS, {});
    } catch (error) {
      commit(
        SET_APPROVE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_APPROVE_IS_LOADING, false);
    }
  },
  [REJECT_CRYPTO_PAYMENT]: async ({ commit, dispatch }, { id, descriptionId }) => {
    try {
      commit(SET_REJECT_IS_LOADING, true);

      await AdminApi.rejectCryptoPayment(id, descriptionId);

      commit(SET_REJECT_SUCCESS, true);
      commit(TOGGLE_REJECT_DIALOG, null);
      dispatch(FILTER_CRYPTO_PAYMENTS, {});
    } catch (error) {
      commit(
        SET_REJECT_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_REJECT_IS_LOADING, false);
    }
  },
  [GET_STATUS_BY_COUNT]: async ({ commit }) => {
    try {
      commit(SET_STATUS_IS_LOADING, true);

      let statusCounts = await AdminApi.getCryptoStatusByCount();

      commit(SET_STATUS_COUNTS, statusCounts);
    } catch (error) {
      commit(SET_STATUS_ERROR, error.response.data);
    } finally {
      commit(SET_STATUS_IS_LOADING, false);
    }
  },
  [GET_HISTORY]: async ({ commit }, id) => {
    try {
      commit(SET_HISTORY_IS_LOADING, true);

      let result = await AdminApi.getCryptoPaymentHistory(id);

      commit(SET_HISTORY, result);
    } catch (error) {
      commit(SET_HISTORY_ERROR, error.response.data);
    } finally {
      commit(SET_HISTORY_IS_LOADING, false);
    }
  },
  [GET_ARCHIVE_HISTORY]: async ({ commit }, id) => {
    try {
      commit(SET_ARCHIVE_HISTORY_IS_LOADING, true);

      let result = await AdminApi.getCryptoPaymentArchiveHistory(id);

      commit(SET_ARCHIVE_HISTORY, result);
    } catch (error) {
      commit(SET_ARCHIVE_HISTORY_ERROR, error.response.data);
    } finally {
      commit(SET_ARCHIVE_HISTORY_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_CRYPTO_PAYMENTS]: (state, cryptoPayments) => (state.cryptoPayments = cryptoPayments),
  [SET_CRYPTO_PAYMENT_ARCHIVES]: (state, cryptoPayments) => (state.cryptoPaymentArchives = cryptoPayments),
  [SET_CRYPTO_PAYMENTS_COUNT]: (state, totalCount) => (state.totalCount = totalCount),
  [SET_CRYPTO_PAYMENT_ARCHIVES_COUNT]: (state, totalCount) => (state.archiveTotalCount = totalCount),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),

  [TOGGLE_APPROVE_DIALOG]: (state, item = null) => {
    state.approve.item = item;
    state.approve.dialog = !state.approve.dialog;
    state.approve.isLoading = false;
    state.approve.success = false;
    state.approve.error = "";
  },
  [SET_APPROVE_IS_LOADING]: (state, isLoading) => (state.approve.isLoading = isLoading),
  [SET_APPROVE_SUCCESS]: (state, success) => (state.approve.success = success),
  [SET_APPROVE_ERROR]: (state, error) => (state.approve.error = error),

  [TOGGLE_REJECT_DIALOG]: (state, item = null) => {
    state.reject.item = item;
    state.reject.dialog = !state.reject.dialog;
    state.reject.isLoading = false;
    state.reject.success = false;
    state.reject.error = "";
  },
  [SET_REJECT_IS_LOADING]: (state, isLoading) => (state.reject.isLoading = isLoading),
  [SET_REJECT_SUCCESS]: (state, success) => (state.reject.success = success),
  [SET_REJECT_ERROR]: (state, error) => (state.reject.error = error),

  [SET_STATUS_COUNTS]: (state, statusCounts) => (state.status.count = statusCounts),
  [SET_STATUS_IS_LOADING]: (state, isLoading) => (state.status.isLoading = isLoading),
  [SET_STATUS_ERROR]: (state, error) => (state.status.error = error),

  [SET_CRYPTO_REFRESH]: (state, refresh) => (state.refresh = refresh),
  [ADD_CRYPTO_PAYMENT]: (state, data) => {
    state.cryptoPayments.unshift(data);
    state.totalCount++;
  },
  [REPLACE_CRYPTO_PAYMENT]: (state, data) => {
    const item = state.cryptoPayments.find((a) => a.id === data.id);
    if (item) {
      Object.assign(item, data);
    }

    state.cryptoPayments = state.cryptoPayments.sort((item, secondItem) => {
      if (item.paymentStatus.name == secondItem.paymentStatus.name) {
        return new Date(secondItem.createdAt) - new Date(item.createdAt);
      } else {
        return item.paymentStatus.sequence - secondItem.paymentStatus.sequence;
      }
    });
  },

  [TOGGLE_HISTORY_DIALOG]: (state, id = "") => {
    state.history.id = id;
    state.history.history = [];
    state.history.dialog = !state.history.dialog;
    state.history.isLoading = false;
    state.history.success = false;
    state.history.error = "";
  },
  [SET_HISTORY]: (state, history) => (state.history.history = history),
  [SET_HISTORY_IS_LOADING]: (state, isLoading) => (state.history.isLoading = isLoading),
  [SET_HISTORY_ERROR]: (state, error) => (state.history.error = error),

  [TOGGLE_ARCHIVE_HISTORY_DIALOG]: (state, id = "") => {
    state.archiveHistory.id = id;
    state.archiveHistory.history = [];
    state.archiveHistory.dialog = !state.archiveHistory.dialog;
    state.archiveHistory.isLoading = false;
    state.archiveHistory.success = false;
    state.archiveHistory.error = "";
  },
  [SET_ARCHIVE_HISTORY]: (state, history) => (state.archiveHistory.history = history),
  [SET_ARCHIVE_HISTORY_IS_LOADING]: (state, isLoading) => (state.archiveHistory.isLoading = isLoading),
  [SET_ARCHIVE_HISTORY_ERROR]: (state, error) => (state.archiveHistory.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
