<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <template v-if="isAdmin()" v-slot:activator="{ on }">
      <v-btn color="success" v-on="on" class="mr-2"> <v-icon left>mdi-plus-thick</v-icon> Yeni İşlem</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Yeni İşlem Talebi</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 520px;">
        <v-form ref="form">
          <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
          <v-alert v-show="success" color="primary" type="success" icon="mdi-cloud-check">İşlem Talebi Oluşturuldu</v-alert>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="item.customerId"
                  v-on:paste="paste"
                  label="User / Login ID"
                  required
                  :rules="userIdRules"
                  maxlength="14"
                  counter
                  :disabled="success"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field v-model="item.customerName" v-on:paste="paste" label="Ad" type="text" required :rules="nameRules" :disabled="success" outlined dense></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field v-model="item.customerSurname" label="Soyad" type="text" required :rules="surnameRules" :disabled="success" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="item.cryptoId"
                  :rules="cryptoIdRules"
                  :items="cryptos"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  label="Kripto Seçiniz"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="item.number" label="Cüzdan No" required :rules="numberRules" maxlength="100" counter :disabled="success" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="item.tag" label="Etiket" maxlength="200" counter :disabled="success" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field v-model="item.amount" label="Tutar" type="text" required :rules="amountRules" :disabled="success" outlined append-icon="₺" dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-select
                  v-model="item.descriptionId"
                  :items="descriptions"
                  :disabled="success"
                  clearable
                  dense
                  outlined
                  item-text="name"
                  item-value="id"
                  label="İşlem Notu"
                  background-color="white"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-textarea
                  v-if="manuelDescription"
                  v-model="item.description"
                  label="İşlem Açıklaması"
                  hint="İşlem talebiyle ilgili notunuz varsa buraya girebilirsiniz"
                  rows="2"
                  row-height="16"
                  required
                  :disabled="success"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" class="pl-10 pr-10" v-show="!success && !loading" @click="save">Kaydet</v-btn>
        <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="dialog = false">{{ !success && !loading ? "İptal" : "Kapat" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { CREATE_CRYPTO_WITHDRAWS, REFRESH_CREATE } from "../../../../store/modules/withdraw/cryptoWithdraw";
import { GET_WITHDRAW_DESCRIPTION } from "../../../../store/modules/withdraw/withdraw";
import { FETCH_CRYPTOS } from "../../../../store/modules/crypto";

export default {
  data: () => ({
    dialog: false,
    manuelDescription: false,
    item: {
      customerId: "",
      customerName: "",
      customerSurname: "",
      number: "",
      tag: "",
      amount: "",
      descriptionId: null,
      description: "",
    },
    nameRules: [(v) => !!v || "Lütfen adı giriniz"],
    surnameRules: [(v) => !!v || "Lütfen soyadı giriniz"],
    cryptoIdRules: [(v) => !!v || "Lütfen cryptoa seçiniz"],
    numberRules: [(v) => !!v || "Lütfen cüzdan numarasını giriniz"],
    userIdRules: [(v) => !!v || "Lütfen UserID giriniz"],
    amountRules: [(v) => !!v || "Lütfen tutar giriniz"],
  }),
  computed: {
    ...mapState("cryptoWithdraw", {
      loading: (state) => state.create.isLoading,
      success: (state) => state.create.success,
      error: (state) => state.create.error,
    }),
    ...mapState("withdraw", {
      descriptions: (state) => state.withdrawDescriptions,
    }),
    ...mapState("crypto", {
      cryptos: (state) => state.cryptos.filter((item) => item.isActive),
    }),
    ...mapState("user", {
      user: (state) => state.user,
    }),
    descriptionId() {
      return this.item.descriptionId;
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.fetchCryptos();
        this.getWithdrawDescriptions("Kripto");
        this.refreshCreate();

        if (this.$refs.form) this.$refs.form.reset();

        this.item = { customerId: "", customerName: "", customerSurname: "", cryptoId: "", number: "", tag: "", amount: "", descriptionId: null, description: "" };
      }
    },
    success(newVal) {
      if (newVal) {
        this.dialog = false;
      }
    },
    descriptionId(newVal) {
      if (!newVal) {
        this.manuelDescription = false;
        return;
      }

      this.manuelDescription = this.descriptions.find((a) => a.id == newVal).other;
    },
  },
  methods: {
    ...mapActions("cryptoWithdraw", {
      createCryptoWithdraw: CREATE_CRYPTO_WITHDRAWS,
      refreshCreate: REFRESH_CREATE,
    }),
    ...mapActions("withdraw", {
      getWithdrawDescriptions: GET_WITHDRAW_DESCRIPTION,
    }),
    ...mapActions("crypto", {
      fetchCryptos: FETCH_CRYPTOS,
    }),
    ...mapMutations("cryptoWithdraw", {
      refreshCreate: REFRESH_CREATE,
    }),
    isAdmin() {
      return this.user.role.isAdmin || this.user.role.isSupervisor || this.user.role.isCasibomRole;
    },
    save() {
      if (!this.$refs.form.validate()) return;

      this.createCryptoWithdraw(this.item);
    },
    paste(event) {
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      if (!paste) return;

      let pasteArray = paste.split(/[\s_]+/);
      if (pasteArray.length <= 1) return;

      let letterArray = [];
      let numberArray = [];

      pasteArray.forEach((item) => {
        if (isNaN(item) && typeof item === "string") {
          letterArray.push(item);
        } else if (!isNaN(item)) {
          numberArray.push(item);
        }
      });

      this.item.customerId = numberArray.join("_");
      this.item.customerSurname = letterArray[letterArray.length - 1];
      letterArray.splice(letterArray.length - 1, 1);

      event.preventDefault();
      this.item.customerName = letterArray.join(" ");
    },
  },
};
</script>

<style scoped>
.v-text-field__details {
  background-color: blue !important;
}
</style>
