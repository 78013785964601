<template>
  <div>
    <v-skeleton-loader class="mx-auto" type="table-heading, table-tbody, table-tfoot" v-if="isLoading"></v-skeleton-loader>
    <v-data-table :headers="headers" :items="jobs" :options.sync="options" :server-items-length="totalCount" class="elevation-1" :footer-props="footerSettings" v-else>
      <template v-slot:top>
        <v-container class="filter-area" fluid>
          <v-row>
            <v-col cols="12" xs="6" sm="2" md="2">
              <v-datetime-picker label="Başlangıç Tarihi" v-model="filters.startDate" :text-field-props="{ appendIcon: 'mdi-calendar-today' }">
                <template slot="dateIcon">
                  <v-icon>mdi-calendar-blank-multiple</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-time-four-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" xs="6" sm="2" md="2">
              <v-datetime-picker label="Bitiş Tarihi" v-model="filters.endDate" :text-field-props="{ appendIcon: 'mdi-calendar-today' }"
                ><template slot="dateIcon">
                  <v-icon>mdi-calendar-blank-multiple</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-time-four-outline</v-icon>
                </template></v-datetime-picker
              >
            </v-col>
            <v-col cols="12" xs="6" sm="2" md="2">
              <v-autocomplete v-model="filters.type" :items="types" clearable dense multiple outlined label="Tip Seçiniz" v-on:change="filter"></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="6" sm="2" md="2">
              <v-autocomplete
                v-model="filters.paymentTypeId"
                :items="paymentTypes"
                clearable
                dense
                multiple
                outlined
                item-text="name"
                item-value="id"
                label="Ödeme Yöntemi Seçiniz"
                v-on:change="filter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="6" sm="2" md="2">
              <v-autocomplete
                v-model="filters.isProcess"
                :items="process"
                clearable
                dense
                multiple
                outlined
                item-text="name"
                item-value="value"
                label="İşlem Bilgisi Seçiniz"
                v-on:change="filter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="6" sm="2" md="2">
              <v-autocomplete
                v-model="filters.isError"
                :items="errorList"
                clearable
                dense
                multiple
                outlined
                item-text="name"
                item-value="value"
                label="Hata Durumu Seçiniz"
                v-on:change="filter"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12" xs="6" sm="6" md="6" class="text-left">
              <new-job-dialog />
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="6" class="text-right">
              <v-btn color="primary" inline-block @click="filter">Listele</v-btn>
              <v-btn @click="reset" inline-block outlined class="ml-2">Reset</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.startDate="{ item }"> {{ item.startDate | date }} </template>
      <template v-slot:item.endDate="{ item }">{{ item.endDate | date }} </template>
      <template v-slot:item.processStart="{ item }"> {{ item.processStart | moment }} </template>
      <template v-slot:item.processEnd="{ item }">{{ item.processEnd | moment }} </template>
      <template v-slot:item.createdAt="{ item }"> {{ item.createdAt | moment }} </template>
      <template v-slot:item.isError="{ item }">
        <v-tooltip bottom :disabled="!item.isError">
          <template v-slot:activator="{ on }">
            <v-chip small :color="item.isError ? 'danger' : 'green'" dark v-on="on">
              {{ item.isError ? "Hata Var" : "Hata Yok" }}
            </v-chip>
          </template>
          <span>{{ item.errorMessage }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.isProcess="{ item }">
        <img :src="require(`@/assets/img/loading-gif.gif`)" style="height: 60%;" v-if="item.isProcess && !item.processEnd" />
        <v-chip small :color="item.processEnd ? 'green' : item.isActive ? 'primary' : 'red'" dark v-else>
          {{ item.processEnd ? "İşlem Tamamlandı" : item.isActive ? "İşleme Alınmayı Bekliyor" : "İptal Edildi" }}
        </v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip small :color="item.status == 'Aktif' ? 'green' : 'primary'" dark>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="!item.isProcess && item.isActive" style="width:200px;" class="text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="ma-1" color="error" small @click="() => cancelJob(item.id)" v-on="on">
                <v-icon size="18">mdi-cancel</v-icon>
              </v-btn>
            </template>
            <span>Görevi İptal Et</span>
          </v-tooltip>
        </div>
        <div v-if="item.isProcess && item.isActive" style="width:200px;" class="text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="ma-1" color="primary" small @click="() => toggleDialog(item.id)" v-on="on">
                <v-icon size="18">mdi-file</v-icon>
              </v-btn>
            </template>
            <span>Detay</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <job-detail-dialog />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { FILTER_JOBS, CANCEL_JOB, SET_JOB_REFRESH, TOGGLE_DETAIL_DIALOG } from "../../../store/modules/job";
import { FETCH_PAYMENT_TYPES } from "../../../store/modules/paymentType";
import NewJobDialog from "./NewJobDialog";
import JobDetailDialog from "./JobDetailDialog";
import moment from "moment";

export default {
  data: () => ({
    options: {
      itemsPerPage: 10,
      page: 1,
    },
    filters: {
      startDate: "",
      endDate: "",
      type: "",
      isError: undefined,
      paymentTypeId: "",
      isProcess: undefined,
    },
    types: ["Deposit", "Withdraw"],
    errorList: [
      { name: "Hata Durumu Seçiniz", value: "" },
      { name: "Hata Var", value: true },
      { name: "Hata Yok", value: false },
    ],
    process: [
      { name: "İşlem Durumu Seçiniz", value: "" },
      { name: "İşleme Alındı", value: true },
      { name: "İşleme Alınmadı", value: false },
    ],
    headers: [
      { text: "Başlangıç Tarihi", sortable: false, align: "center", value: "startDate" },
      { text: "Bitiş Tarihi", sortable: false, value: "endDate" },
      { text: "Cron", sortable: false, value: "cron" },
      { text: "Timezone", sortable: false, align: "center", value: "timezone" },
      { text: "Tip", sortable: false, align: "center", value: "type" },
      { text: "Ödeme Yöntemi", sortable: false, align: "center", value: "paymentType.name" },
      { text: "İşlem Durumu", sortable: false, align: "center", value: "isProcess" },
      { text: "İşlem Başlangıcı", sortable: false, value: "processStart" },
      { text: "İşlem Bitişi", sortable: false, value: "processEnd" },
      { text: "Hata Durumu", sortable: false, value: "isError" },
      { text: "Durum", sortable: false, value: "status" },
      { text: "Oluşturma", sortable: false, value: "createdAt" },
      { text: "İşlemler", sortable: false, align: "left", value: "actions" },
    ],
    footerSettings: {
      itemsPerPageOptions: [10],
    },
  }),
  components: {
    "new-job-dialog": NewJobDialog,
    "job-detail-dialog": JobDetailDialog,
  },
  computed: {
    ...mapState("job", {
      jobs: (state) =>
        state.jobs.map((item) => ({
          ...item,
          status: item.isActive ? "Aktif" : "Pasif",
        })),
      totalCount: (state) => state.totalCount,
      isLoading: (state) => state.isLoading,
      error: (state) => state.error || state.cancel.error,
      cancelIsLoading: (state) => state.cancel.isLoading,
      refresh: (state) => state.refresh,
    }),
    ...mapState("paymentType", {
      paymentTypes: (state) => state.paymentTypes,
    }),
  },
  watch: {
    options(oldVal, newVal) {
      if (oldVal.itemsPerPage !== newVal.itemsPerPage || oldVal.page !== newVal.page) this.filter();
    },
    refresh(value) {
      if (value) {
        this.setRefresh(false);
        this.filter();
      }
    },
  },
  created() {
    this.filter();
    this.fetchPaymentTypes();
  },
  methods: {
    ...mapActions("job", {
      filterJobs: FILTER_JOBS,
      cancelJob: CANCEL_JOB,
    }),
    ...mapMutations("job", {
      setRefresh: SET_JOB_REFRESH,
      toggleDialog: TOGGLE_DETAIL_DIALOG,
    }),
    ...mapActions("paymentType", {
      fetchPaymentTypes: FETCH_PAYMENT_TYPES,
    }),
    filter() {
      this.filterJobs({ ...this.options, ...this.filters });
    },
    reset() {
      this.filters = {
        startDate: "",
        endDate: "",
        type: "",
        isError: undefined,
        paymentTypeId: "",
        isProcess: undefined,
      };

      this.filter();
    },
  },
  filters: {
    moment: function(date) {
      if (!date) {
        return;
      }

      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
    date: function(date) {
      if (!date) {
        return "";
      }

      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>
