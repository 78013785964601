<template>
  <div>
    <v-skeleton-loader class="mx-auto" type="table-heading, table-tbody, table-tfoot" v-if="isLoading"></v-skeleton-loader>
    <v-data-table :headers="headers" :items="users" :options.sync="options" :server-items-length="totalCount" class="elevation-1" :footer-props="footerSettings" v-else>
      <template v-slot:top>
        <v-container class="filter-area" fluid>
          <v-row>
            <v-col cols="12" xs="6" sm="6" md="2">
              <v-text-field v-model="filters.name" label="İsim" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-text-field v-model="filters.email" label="Email" outlined dense background-color="white" v-on:keyup.enter="filter"></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-autocomplete
                v-model="filters.roleId"
                :items="roles"
                item-text="name"
                item-value="id"
                label="Yetki"
                dense
                outlined
                multiple
                background-color="white"
                v-on:change="filter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="2">
              <v-autocomplete
                v-model="filters.status"
                :items="status"
                item-text="name"
                item-value="value"
                label="Durumu"
                dense
                outlined
                multiple
                background-color="white"
                v-on:change="filter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="2">
              <v-autocomplete
                v-model="filters.login"
                :items="loginStatus"
                item-text="name"
                item-value="value"
                label="Login Durumu"
                dense
                outlined
                background-color="white"
                v-on:change="filter"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12" xs="6" sm="6" md="6" class="text-left">
              <new-user-dialog />
              <downloadexcel
                class="v-btn v-btn--contained v-size--default white ml-2"
                :fetch="exportToExcel"
                :fields="excelFields"
                worksheet="Rapor"
                name="Kullanicilar.xls"
                inline-block
                v-if="excelAuth"
              >
                <v-icon color="success" class="mr-2" small>
                  mdi-microsoft-excel
                </v-icon>
                Rapor Al
              </downloadexcel>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="6" class="text-right">
              <v-btn color="primary" inline-block @click="filter">Listele</v-btn>
              <v-btn @click="reset" inline-block outlined class="ml-2">Reset</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <edit-user-dialog />
      </template>
      <template v-slot:item.login="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon size="18" :color="item.isLogin ? 'green' : 'red'" v-on="on">mdi-circle</v-icon>
          </template>
          <span>{{ item.lastLoginDate | moment }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.name="{ item }">
        {{ item.name }}
        <div class="grey--text">{{ item.email }}</div>
      </template>
      <template v-slot:item.pages="{ item }">
        <v-chip v-for="page in item.role.pages" :key="page.name" class="ma-1" color="primary" text-color="white" small>
          {{ page.name }}
        </v-chip>
      </template>
      <template v-slot:item.paymentTypes="{ item }">
        <v-chip v-for="paymentType in item.role.paymentTypes" :key="paymentType.name" class="ma-1" color="success" text-color="white" small>
          {{ paymentType.name }}
        </v-chip>
      </template>
      <template v-slot:item.twofa="{ item }">
        <v-chip
          text-color="white"
          :color="item.twofa ? 'green' : 'red'"
          @click="() => reset2faDialog(item)"
          v-if="item.twofa && (user.role.isAdmin || (user.role.isSupervisor && (user.id == item.id || (!item.role.isAdmin && !item.role.isSupervisor))))"
        >
          {{ item.twofa ? "Açık" : "Kapalı" }}
        </v-chip>
        <v-chip text-color="white" :color="item.twofa ? 'green' : 'red'" v-else>
          {{ item.twofa ? "Açık" : "Kapalı" }}
        </v-chip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :text-color="item.isBanned || item.isActive ? 'white' : 'black'" :color="item.isBanned ? 'red' : item.isActive ? 'green' : 'depressed'" v-if="statusControl(item)">
          <v-switch v-model="item.isActive" :loading="statusIsLoading" @click="changeStatus(item.id)" color="white" hide-details></v-switch>
          {{ item.status }}</v-chip
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom v-if="!item.role.isAdmin && (user.role.isAdmin || !item.role.isSupervisor)">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" outlined small elevation="2" class="mx-2" @click="() => toggleDialog(item)" v-on="on">
              <v-icon size="18">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Kullanıcıyı Güncelle</span>
        </v-tooltip>
        <v-tooltip bottom v-if="user.role.isAdmin">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" outlined small elevation="2" class="mx-2" @click="() => copyId(item.id)" v-on="on">
              <v-icon size="18">mdi-floppy</v-icon>
            </v-btn>
          </template>
          <span>ID</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog.status" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5">
          UYARI!
        </v-card-title>
        <v-card-text
          ><strong>{{ dialog.item.name }} - {{ dialog.item.email }}</strong> kişisine ait iki aşamalı doğrulamayı kapatmak üzeresiniz. Bu işlem geri alınamaz. <strong>Emin misiniz?</strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" dark @click="reset2fa({ userId: dialog.item.id })">
            Sıfırla
          </v-btn>
          <v-btn color="theme-dark" @click="dialog.status = false">
            İptal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="2000">
      ID kopyalandı
      <template v-slot:action="{ attrs }">
        <v-btn color="cyellow" text v-bind="attrs" @click="snackbar = false">
          Kapat
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { FILTER_USERS, CHANGE_USER_STATUS, TOGGLE_UPDATE_DIALOG, SET_USER_REFRESH, RESET_USER_2FA } from "../../../store/modules/user";
import { FETCH_ROLES } from "../../../store/modules/role";
import NewUserDialog from "./NewUserDialog";
import EditUserDialog from "./editUserDialog";
import moment from "moment";
import downloadexcel from "vue-json-excel";
import AdminApi from "../../../api/adminApi";

export default {
  data: () => ({
    snackbar: false,
    dialog: {
      status: false,
      item: {},
    },
    options: {
      itemsPerPage: 10,
      page: 1,
    },
    filters: {
      name: "",
      email: "",
      roleId: "",
      login: "",
      status: undefined,
    },
    status: [
      { name: "Aktif", value: true },
      { name: "Pasif", value: false },
    ],
    loginStatus: [
      { name: "Seçiniz", value: "" },
      { name: "Aktif", value: true },
      { name: "Pasif", value: false },
    ],
    headers: [
      { text: "Login", sortable: false, align: "center", value: "login" },
      { text: "İsim", sortable: false, value: "name" },
      { text: "Yetki", sortable: false, value: "role.name" },
      { text: "Sayfalar", sortable: false, align: "center", value: "pages" },
      { text: "Ödeme Yöntemleri", sortable: false, align: "center", value: "paymentTypes" },
      { text: "Durum", sortable: false, align: "center", value: "status" },
      { text: "2FA", sortable: false, align: "center", value: "twofa" },
      { text: "Oluşturma", sortable: false, value: "createdAt" },
      { text: "İşlemler", sortable: false, value: "actions" },
    ],
    footerSettings: {
      itemsPerPageOptions: [10],
    },
    excelFields: {
      İsim: "name",
      Email: "email",
      Yetki: "role.name",
      Sayfalar: "pages",
      "Ödeme Yöntemleri": "paymentTypes",
      Durum: "status",
      Oluşturma: "createdAt",
      "2FA": "twofa",
      "Login Durumu": "isLogin",
      "Son Login Tarihi": "lastLoginDate",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),
  components: {
    "new-user-dialog": NewUserDialog,
    "edit-user-dialog": EditUserDialog,
    downloadexcel,
  },
  computed: {
    ...mapState("user", {
      users: (state) =>
        state.users.map((item) => ({
          ...item,
          status: item.isActive ? "Aktif" : "Pasif",
        })),
      totalCount: (state) => state.totalCount,
      isLoading: (state) => state.isLoading,
      error: (state) => state.error || state.status.error,
      statusIsLoading: (state) => state.status.isLoading,
      refresh: (state) => state.refresh,
      isAdmin: (state) => state.user.role.isAdmin,
      user: (state) => state.user,
      excelAuth: (state) => state.user.role.isAdmin || state.user.auth.some((a) => a.code == "uee"),
    }),
    ...mapState("role", {
      roles: (state) => state.roles,
    }),
  },
  watch: {
    options(oldVal, newVal) {
      if (oldVal.itemsPerPage !== newVal.itemsPerPage || oldVal.page !== newVal.page) this.filter();
    },
    refresh(value) {
      if (value) {
        this.setRefresh(false);
        this.filter();
      }
    },
  },
  created() {
    this.filter();
    this.fetchRoles();
  },
  methods: {
    ...mapActions("user", {
      fetchUsers: FILTER_USERS,
      changeStatus: CHANGE_USER_STATUS,
      resetTwoFa: RESET_USER_2FA,
    }),
    ...mapActions("role", {
      fetchRoles: FETCH_ROLES,
    }),
    ...mapMutations("user", {
      toggleDialog: TOGGLE_UPDATE_DIALOG,
      setRefresh: SET_USER_REFRESH,
    }),
    filter() {
      this.fetchUsers({ ...this.options, ...this.filters });
    },
    reset() {
      this.filters = {
        name: "",
        email: "",
        status: "",
        roleId: "",
        login: "",
      };

      this.filter();
    },
    statusControl(item) {
      return item.id !== this.user.id && !item.role.isAdmin && (this.user.role.isAdmin || !item.role.isSupervisor);
    },
    reset2faDialog(item) {
      this.dialog.item = item;
      this.dialog.status = true;
    },
    reset2fa(id) {
      this.resetTwoFa(id);
      this.dialog.item = {};
      this.dialog.status = false;
    },
    async exportToExcel() {
      let result = await AdminApi.exportUsers({
        ...this.options,
        ...this.filters,
        itemsPerPage: -1,
        page: -1,
      });

      return result.rows.map((item) => {
        return {
          ...item,
          pages: item.role.pages.map((item) => item.name).join(","),
          paymentTypes: item.role.paymentTypes.map((item) => item.name).join(","),
          twofa: item.twofa ? "Açık" : "Kapalı",
          isLogin: item.isLogin ? "Aktif" : "Pasif",
          status: item.isActive ? "Aktif" : "Pasif",
        };
      });
    },
    copyId(id) {
      var input = document.createElement("input");
      input.value = id;
      document.body.appendChild(input);
      input.select();

      document.execCommand("copy");
      document.body.removeChild(input);

      this.snackbar = true;
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
  },
};
</script>
