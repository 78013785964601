<template>
  <div>
    <v-card class="mx-auto my-12" dark max-width="600">
      <v-subheader class="font-weight-normal">
        <v-row>
          <v-col md="12">
            <v-btn elevation="0" small @click="change" class="mr-4">
              <v-icon small color="cyellow">mdi-arrow-left</v-icon>
            </v-btn>
            <strong class="cyellow--text"
              >{{ payment.account.crypto.name }} •
              {{ payment.account.crypto.code }}</strong
            >
          </v-col>
        </v-row>
      </v-subheader>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-alert left dense text border="left" class="mb-0">
          Sayın
          <strong
            >{{ payment.customer.name }} {{ payment.customer.surname }}</strong
          >,<br />Lütfen
          <v-chip label color="#1E1E1E" small
            ><strong class="cyellow--text" style="font-size:16px"
              >{{ payment.amount }} <small>TL</small></strong
            ></v-chip
          >
          yatırımınızı aşağıdaki cüzdana gönderin, gönderimden sonra
          <strong>YATIRDIM</strong>'a basın
        </v-alert>
        <v-spacer class="mt-4"></v-spacer>
        <div class="grey--text">
          <v-row no-gutters>
            <v-col :cols="9">Cüzdan No</v-col>
            <v-col :cols="3" class="text-right">
              <v-btn small color="darkgray" @click="copyAccount">
                Kopyala
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-card-title class="pa-0">
          <v-row no-gutters>
            <v-col :cols="12" class="whitetext">
              <span ref="number">{{ payment.account.number }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-spacer class="mt-4"></v-spacer>
        <div class="grey--text" v-if="payment.account.destinationTag">
          <v-row no-gutters>
            <v-col :cols="9">Etiket</v-col>
            <v-col :cols="3" class="text-right">
              <v-btn small color="darkgray" @click="copyTag">
                Kopyala
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-card-title class="pa-0" v-if="payment.account.destinationTag">
          <v-row no-gutters>
            <v-col :cols="12" class="pr-2 whitetext">
              <span ref="destinationTag">{{
                payment.account.destinationTag
              }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-spacer class="mb-4" v-if="payment.account.destinationTag"></v-spacer>
        <div class="grey--text">
          <v-row no-gutters>
            <v-col :cols="9"
              >Yatırım Tutarı
              <span v-if="payment.coinValue">/ Kripto Karşılığı</span></v-col
            >
            <v-col :cols="3" class="text-right">
              <v-btn small color="darkgray" @click="copyCurrency">
                Kopyala
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-card-title class="pa-0">
          <v-row no-gutters>
            <v-col :cols="12" class="whitetext">
              {{ payment.amount }}<small class="ml-1">TL</small>
              <span v-if="payment.coinValue"
                ><v-icon small color="cyellow" class="ml-1 mr-1"
                  >mdi-swap-horizontal</v-icon
                >
                <span ref="currency">{{ payment.coinValue }}</span>
                <small class="ml-1">{{
                  payment.account.crypto.code
                }}</small></span
              >
            </v-col>
          </v-row>
        </v-card-title>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-actions class="pl-4 pr-4 pt-4">
        <v-btn
          block
          elevation="2"
          class="casibom font-weight-bold"
          :disabled="disabled"
          :loading="disabled"
          @click="delay"
        >
          Yatırdım
        </v-btn>
      </v-card-actions>
      <div class="text-center pb-4">
        <small class="text--gray"
          >* Lütfen bu butona yatırım işleminden sonra basınız.</small
        >
      </div>
      <redirect-to-site class="ml-4" />
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ label }} kopyalandı

      <template v-slot:action="{ attrs }">
        <v-btn color="cyellow" text v-bind="attrs" @click="snackbar = false">
          Kapat
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  CREATE_PAYMENT,
  PAYMENT_REQUEST,
} from "../../../store/modules/payment/payment";
import RedirectToSite from "../Redirect";

export default {
  data: () => ({
    snackbar: false,
    timeout: 2000,
    disabled: false,
    delaytime: null,
    loader: null,
    loading: false,
  }),
  components: {
    "redirect-to-site": RedirectToSite,
  },
  computed: {
    ...mapState("payment", {
      payment: (state) => state.data,
    }),
  },
  methods: {
    ...mapActions("payment", {
      createPayment: CREATE_PAYMENT,
      paymentRequest: PAYMENT_REQUEST,
    }),
    delay() {
      this.disabled = true;
      this.loader = "loading";
      this.delaytime = setTimeout(() => {
        this.disabled = false;
        this.loader = null;
      }, 5000);
      this.create();
    },
    create() {
      this.createPayment({ tokenId: this.$route.params.tokenId });
    },
    change() {
      this.paymentRequest({ tokenId: this.$route.params.tokenId });
    },
    copyAccount() {
      const element = this.$refs.number;
      let range;

      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }

      document.execCommand("copy");
      this.snackbar = true;
      this.label = "Cüzdan No";
    },
    copyTag() {
      const element = this.$refs.destinationTag;
      let range;

      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }

      document.execCommand("copy");
      this.snackbar = true;
      this.label = "Etiket";
    },
    copyCurrency() {
      const element = this.$refs.currency;
      let range;

      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }

      document.execCommand("copy");
      this.snackbar = true;
      this.label = "Kripto Karşılığı";
    },
  },
  beforeDestroy() {
    clearTimeout(this.delaytime);
  },
};
</script>
