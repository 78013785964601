import AdminApi from "../../api/adminApi";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USERS = "FETCH_USERS";
export const FILTER_USERS = "FILTER_USERS";
export const FETCH_BANK_USERS = "FETCH_BANK_USERS";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const UPDATE_USER_2FA = "UPDATE_USER_2FA";
export const RESET_USER_2FA = "RESET_USER_2FA";

export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const SET_USERS_COUNT = "SET_USERS_COUNT";
export const SET_BANK_USERS = "SET_BANK_USERS";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_USER_REFRESH = "SET_USER_REFRESH";

export const TOGGLE_CREATE_DIALOG = "TOGGLE_CREATE_DIALOG";
export const SET_CREATE_IS_LOADING = "SET_CREATE_IS_LOADING";
export const SET_CREATE_SUCCESS = "SET_CREATE_SUCCESS";
export const SET_CREATE_ERROR = "SET_CREATE_ERROR";

export const TOGGLE_UPDATE_DIALOG = "TOGGLE_UPDATE_DIALOG";
export const SET_UPDATE_IS_LOADING = "SET_UPDATE_IS_LOADING";
export const SET_UPDATE_SUCCESS = "SET_UPDATE_SUCCESS";
export const SET_UPDATE_ERROR = "SET_UPDATE_ERROR";

export const SET_STATUS_IS_LOADING = "SET_STATUS_IS_LOADING";
export const SET_STATUS_ERROR = "SET_STATUS_ERROR";

export const SET_PASSWORD_IS_LOADING = "SET_PASSWORD_IS_LOADING";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR";

export const SET_2FA_IS_LOADING = "SET_2FA_IS_LOADING";
export const SET_2FA_ERROR = "SET_2FA_ERROR";

const state = {
  user: {},
  users: [],
  totalCount: 0,
  refresh: false,
  bankUsers: [],
  isLoading: false,
  error: "",
  create: {
    isLoading: false,
    success: false,
    error: "",
  },
  update: {
    item: {},
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  status: {
    isLoading: false,
    error: "",
  },
  password: {
    isLoading: false,
    success: false,
    error: "",
  },
  twofa: {
    isLoading: false,
    error: "",
  },
};

const actions = {
  [FETCH_USER]: async ({ commit }) => {
    try {
      let user = await AdminApi.getUserInfo();
      commit(SET_USER, user);
      return user;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  [FETCH_USERS]: async ({ commit }) => {
    try {
      commit(SET_IS_LOADING, true);

      let users = await AdminApi.getUsers();
      commit(SET_USERS, users);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [FILTER_USERS]: async ({ commit }, queryItems) => {
    try {
      commit(SET_IS_LOADING, true);

      let result = await AdminApi.filterUsers(queryItems);
      commit(SET_USERS, result.rows);
      commit(SET_USERS_COUNT, result.count);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [FETCH_BANK_USERS]: async ({ commit }) => {
    try {
      let user = await AdminApi.getBankUsers();
      commit(SET_BANK_USERS, user);
    } catch (error) {
      console.error(error.response.data);
    }
  },
  [CREATE_USER]: async ({ commit }, user) => {
    try {
      commit(SET_CREATE_IS_LOADING, true);

      await AdminApi.createUser(user);

      commit(SET_CREATE_SUCCESS, true);
      commit(SET_USER_REFRESH, true);
    } catch (error) {
      commit(
        SET_CREATE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_CREATE_IS_LOADING, false);
    }
  },
  [UPDATE_USER]: async ({ commit }, { id, user }) => {
    try {
      commit(SET_UPDATE_IS_LOADING, true);

      await AdminApi.updateUser(id, user);

      commit(SET_UPDATE_SUCCESS, true);
      commit(SET_USER_REFRESH, true);
    } catch (error) {
      commit(
        SET_UPDATE_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_UPDATE_IS_LOADING, false);
    }
  },
  [CHANGE_USER_STATUS]: async ({ commit }, id) => {
    try {
      commit(SET_STATUS_IS_LOADING, true);

      await AdminApi.changeUserStatus(id);

      commit(SET_USER_REFRESH, true);
    } catch (error) {
      commit(
        SET_STATUS_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_STATUS_IS_LOADING, false);
    }
  },
  [CHANGE_USER_PASSWORD]: async ({ commit }, password) => {
    try {
      commit(SET_PASSWORD_IS_LOADING, true);

      await AdminApi.changeUserPassword(password);

      commit(SET_PASSWORD_SUCCESS);
    } catch (error) {
      commit(
        SET_PASSWORD_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_PASSWORD_IS_LOADING, false);
    }
  },
  [UPDATE_USER_2FA]: async ({ commit, dispatch }) => {
    try {
      commit(SET_2FA_IS_LOADING, true);

      await AdminApi.updateUser2fa();

      dispatch(FETCH_USER);
    } catch (error) {
      commit(
        SET_2FA_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_2FA_IS_LOADING, false);
    }
  },
  [RESET_USER_2FA]: async ({ commit }, userId) => {
    try {
      commit(SET_2FA_IS_LOADING, true);

      await AdminApi.resetUser2fa(userId);

      commit(SET_USER_REFRESH, true);
    } catch (error) {
      commit(
        SET_2FA_ERROR,
        error.response.status !== 400 && error.response.status !== 422 && error.response.status !== 404 ? `Bir hata meydana geldi. Hata Kodu: ${error.response.status}` : error.response.data
      );
    } finally {
      commit(SET_2FA_IS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_USER]: (state, user) => (state.user = user),
  [SET_USERS]: (state, users) => (state.users = users),
  [SET_USER_REFRESH]: (state, refresh) => (state.refresh = refresh),
  [SET_USERS_COUNT]: (state, totalCount) => (state.totalCount = totalCount),
  [SET_BANK_USERS]: (state, users) => (state.bankUsers = users),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),

  [TOGGLE_CREATE_DIALOG]: (state) => {
    state.create.isLoading = false;
    state.create.success = false;
    state.create.error = "";
  },
  [SET_CREATE_IS_LOADING]: (state, isLoading) => (state.create.isLoading = isLoading),
  [SET_CREATE_SUCCESS]: (state) => (state.create.success = true),
  [SET_CREATE_ERROR]: (state, error) => (state.create.error = error),

  [TOGGLE_UPDATE_DIALOG]: (state, item = {}) => {
    state.update.item = item;
    state.update.dialog = !state.update.dialog;
    state.update.isLoading = false;
    state.update.success = false;
    state.update.error = "";
  },
  [SET_UPDATE_IS_LOADING]: (state, isLoading) => (state.update.isLoading = isLoading),
  [SET_UPDATE_SUCCESS]: (state) => (state.update.success = true),
  [SET_UPDATE_ERROR]: (state, error) => (state.update.error = error),

  [SET_STATUS_IS_LOADING]: (state, isLoading) => (state.status.isLoading = isLoading),
  [SET_STATUS_ERROR]: (state, error) => (state.status.error = error),

  [SET_PASSWORD_IS_LOADING]: (state, isLoading) => (state.password.isLoading = isLoading),
  [SET_PASSWORD_SUCCESS]: (state) => (state.password.success = true),
  [SET_PASSWORD_ERROR]: (state, error) => (state.password.error = error),

  [SET_2FA_IS_LOADING]: (state, isLoading) => (state.twofa.isLoading = isLoading),
  [SET_2FA_ERROR]: (state, error) => (state.twofa.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
