import AdminApi from "../../../api/adminApi";

export const GET_WITHDRAW_STATUS = "GET_WITHDRAW_STATUS";
export const GET_WITHDRAW_DESCRIPTION = "GET_WITHDRAW_DESCRIPTION";
export const GET_WITHDRAW_RESULT_DESCRIPTION = "GET_WITHDRAW_RESULT_DESCRIPTION";
export const GET_WITHDRAW_COUNTS = "GET_WITHDRAW_COUNTS";

export const SET_WITHDRAW_STATUS = "SET_WITHDRAW_STATUS";
export const SET_WITHDRAW_DESCRIPTION = "SET_WITHDRAW_DESCRIPTION";
export const SET_WITHDRAW_RESULT_DESCRIPTION = "SET_WITHDRAW_RESULT_DESCRIPTION";
export const SET_WITHDRAW_COUNTS = "SET_WITHDRAW_COUNTS";

export const ADD_WITHDRAW_COUNTS = "ADD_WITHDRAW_COUNTS";
export const CHANGE_WITHDRAW_COUNTS = "CHANGE_WITHDRAW_COUNTS";

const state = {
  withdrawStatus: [],
  withdrawDescriptions: [],
  withdrawResultDescriptions: [],
  withdrawCounts: [],
};

const actions = {
  [GET_WITHDRAW_STATUS]: async ({ commit }) => {
    try {
      if (state.withdrawStatus.length > 0) return;

      let data = await AdminApi.getWithdrawStatus();
      commit(SET_WITHDRAW_STATUS, data);
    } catch (error) {
      console.error(error);
    }
  },
  [GET_WITHDRAW_DESCRIPTION]: async ({ commit }, paymentTypeName) => {
    try {
      let data = await AdminApi.getWithdrawDescriptions(paymentTypeName);
      commit(SET_WITHDRAW_DESCRIPTION, data);
    } catch (error) {
      console.error(error);
    }
  },
  [GET_WITHDRAW_RESULT_DESCRIPTION]: async ({ commit }, { paymentTypeName, isSuccess }) => {
    try {
      let data = await AdminApi.getWithdrawResultDescriptions(paymentTypeName, isSuccess);
      commit(SET_WITHDRAW_RESULT_DESCRIPTION, data);
    } catch (error) {
      console.error(error);
    }
  },
  [GET_WITHDRAW_COUNTS]: async ({ commit }) => {
    let withdrawCounts = await AdminApi.getWithdrawCounts();

    commit(SET_WITHDRAW_COUNTS, withdrawCounts);
  },
};

const mutations = {
  [SET_WITHDRAW_STATUS]: (state, data) => (state.withdrawStatus = data),
  [SET_WITHDRAW_DESCRIPTION]: (state, data) => (state.withdrawDescriptions = data),
  [SET_WITHDRAW_RESULT_DESCRIPTION]: (state, data) => (state.withdrawResultDescriptions = data),
  [SET_WITHDRAW_COUNTS]: (state, withdrawCounts) => (state.withdrawCounts = withdrawCounts),
  [ADD_WITHDRAW_COUNTS]: (state, paymentType) => {
    const item = state.withdrawCounts.find((a) => a.paymentType === paymentType);
    if (item) {
      var newStatus = item.counts.find((a) => a.status === "Yeni");
      var cancelStatus = item.counts.find((a) => a.status === "İşlemden Vazgeçildi");
      var inProgressStatus = item.counts.find((a) => a.status === "İşlemde");
      var approveStatus = item.counts.find((a) => a.status === "Tamamlandı");
      var rejectStatus = item.counts.find((a) => a.status === "Reddedildi");

      Object.assign(item, {
        paymentType,
        counts: [
          { status: "Yeni", count: parseInt(newStatus.count) + 1 },
          { status: "İşlemden Vazgeçildi", count: parseInt(cancelStatus.count) },
          { status: "İşlemde", count: parseInt(inProgressStatus.count) },
          { status: "Tamamlandı", count: parseInt(approveStatus.count) },
          { status: "Reddedildi", count: parseInt(rejectStatus.count) },
        ],
      });
    }
  },
  [CHANGE_WITHDRAW_COUNTS]: (state, data) => {
    const item = state.withdrawCounts.find((a) => a.paymentType === data.paymentType);
    if (item) {
      var newStatus = item.counts.find((a) => a.status === "Yeni");
      var cancelStatus = item.counts.find((a) => a.status === "İşlemden Vazgeçildi");
      var inProgressStatus = item.counts.find((a) => a.status === "İşlemde");
      var approveStatus = item.counts.find((a) => a.status === "Tamamlandı");
      var rejectStatus = item.counts.find((a) => a.status === "Reddedildi");

      switch (data.status) {
        case "İşlemden Vazgeçildi":
          Object.assign(item, {
            paymentType: data.paymentType,
            counts: [
              { status: "Yeni", count: parseInt(newStatus.count) },
              { status: "İşlemden Vazgeçildi", count: parseInt(cancelStatus.count) + 1 },
              { status: "İşlemde", count: parseInt(inProgressStatus.count) - 1 },
              { status: "Tamamlandı", count: parseInt(approveStatus.count) },
              { status: "Reddedildi", count: parseInt(rejectStatus.count) },
            ],
          });
          break;
        case "İşlemde":
          Object.assign(item, {
            paymentType: data.paymentType,
            counts: [
              { status: "Yeni", count: parseInt(newStatus.count) > 0 ? parseInt(newStatus.count) - 1 : 0 },
              { status: "İşlemden Vazgeçildi", count: parseInt(cancelStatus.count) + 1 },
              { status: "İşlemde", count: parseInt(inProgressStatus.count) > 0 ? parseInt(inProgressStatus.count) - 1 : 0 },
              { status: "Tamamlandı", count: parseInt(approveStatus.count) },
              { status: "Reddedildi", count: parseInt(rejectStatus.count) },
            ],
          });
          break;
        case "Tamamlandı":
          Object.assign(item, {
            paymentType: data.paymentType,
            counts: [
              { status: "Yeni", count: parseInt(newStatus.count) },
              { status: "İşlemden Vazgeçildi", count: parseInt(cancelStatus.count) },
              { status: "İşlemde", count: parseInt(inProgressStatus.count) - 1 },
              { status: "Tamamlandı", count: parseInt(approveStatus.count) + 1 },
              { status: "Reddedildi", count: parseInt(rejectStatus.count) },
            ],
          });
          break;
        case "Reddedildi":
          Object.assign(item, {
            paymentType: data.paymentType,
            counts: [
              { status: "Yeni", count: parseInt(newStatus.count) },
              { status: "İşlemden Vazgeçildi", count: parseInt(cancelStatus.count) },
              { status: "İşlemde", count: parseInt(inProgressStatus.count) - 1 },
              { status: "Tamamlandı", count: parseInt(approveStatus.count) },
              { status: "Reddedildi", count: parseInt(rejectStatus.count) + 1 },
            ],
          });
          break;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
