<template>
  <div>
    <v-skeleton-loader
      v-if="!payment"
      dark
      v-bind="attrs"
      type="article, actions"
      class="mx-auto my-12"
      max-width="600"
    ></v-skeleton-loader>
    <v-card class="mx-auto my-12" dark max-width="600" v-if="payment">
      <v-subheader class="font-weight-bold cyellow--text">
        Papara
      </v-subheader>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-card
          class="mx-auto"
          outlined
          elevation="4"
          style="border-top:4px solid #4caf4f"
        >
          <v-card-title class="pa-02">
            <v-icon color="success" class="mr-3">
              mdi-timeline-check
            </v-icon>
            <span style="color:#4caf4f">Yatırımınız Onaylandı</span>
          </v-card-title>
          <v-card-text class="px-4 pb-4"
            >Lütfen bakiyenizi kontrol ediniz. Bol şanslar, iyi eğlenceler
            dileriz.</v-card-text
          >
        </v-card>
        <redirect-to-site />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RedirectToSite from "../Redirect";
export default {
  components: {
    "redirect-to-site": RedirectToSite,
  },
  computed: {
    ...mapState("payment", {
      payment: (state) => state.data,
    }),
  },
};
</script>
