<template>
  <div>
    <v-skeleton-loader
      v-if="!payment"
      dark
      v-bind="attrs"
      type="article, actions"
      class="mx-auto my-12"
      max-width="600"
    ></v-skeleton-loader>
    <v-card class="mx-auto my-12" dark max-width="600" v-if="payment">
      <v-subheader class="font-weight-bold cyellow--text">
        Kripto Paralar
      </v-subheader>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-card
          class="mx-auto"
          outlined
          elevation="4"
          style="border-top:4px solid #ff5252"
        >
          <v-card-title class="pa-02">
            <v-icon color="danger" class="mr-3">
              mdi-block-helper
            </v-icon>
            <span style="color:#ff5252">Yatırımınız Reddedildi</span>
          </v-card-title>
          <v-card-text class="px-4 pb-4">
            <v-card elevation="2" outlined class="pa-2 mb-4 danger"
              ><div>
                Red Nedeni:<br />
                <strong>{{ payment.description }}</strong>
              </div>
            </v-card>
            <p class="text-center">
              Yatırımınızı kontrol edip tekrar yatırım yapmayı deneyebilirsiniz.
            </p>
          </v-card-text>
        </v-card>
        <redirect-to-site />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RedirectToSite from "../Redirect";

export default {
  components: {
    "redirect-to-site": RedirectToSite,
  },
  computed: {
    ...mapState("payment", {
      payment: (state) => state.data,
    }),
  },
};
</script>
