<template>
  <v-card class="mx-auto my-12" dark max-width="600">
    <v-subheader class="font-weight-normal"> Hata Kodu • <strong class="ml-1">404</strong> </v-subheader>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-card class="mx-auto" outlined elevation="4" style="border-top:4px solid #ff5252">
        <v-card-title class="pa-02">
          <v-icon color="danger" class="mr-3">
            mdi-alert-circle
          </v-icon>
          <span style="color:#ff5252">Üzgünüz isteğinizle ilgili bir sorun oluştu</span>
        </v-card-title>
        <v-card-text class="px-4">İşlem tamamlanamadı, lütfen daha sonra tekrar deneyin </v-card-text>
        <v-divider></v-divider>
        <div style="color:#444;text-align:right;padding:10px">Ref. {{ url }}</div>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    url: "",
  }),
  created() {
    this.url = `${this.$router.history._startLocation}`;
  },
};
</script>
