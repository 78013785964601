<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-col>
            <span class="headline">Hesabı Güncelle</span>
          </v-col>
          <v-col cols="4" sm="6" md="4" v-if="isAdmin">
            <bank-dialog />
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 450px;">
        <v-form ref="form">
          <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
          <v-alert v-show="success" color="primary" type="success" icon="mdi-cloud-check">Hesap Güncellendi</v-alert>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="item.bankIds"
                  :rules="bankIdRules"
                  :items="banks"
                  item-text="name"
                  item-value="id"
                  :readonly="!isAdmin && !isSupervisor"
                  multiple
                  dense
                  label="Banka Seçiniz"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="item.name" label="Hesap Adı" required :readonly="!isAdmin && !isSupervisor" :rules="nameRules" maxlength="50" counter :disabled="success"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div label class="mr-2 float-left trIban">
                  TR
                </div>
                <v-text-field v-model="item.iban" label="Iban No" required :readonly="!isAdmin && !isSupervisor" :rules="ibanRules" maxlength="24" counter :disabled="success"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-textarea v-model="item.description" label="Açıklama" rows="1" auto-grow required :rules="descriptionRules" maxlength="200" counter :disabled="success"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field v-model="item.lowerLimit" label="Alt Limit" type="number" required :rules="lowerLimitRules" counter :disabled="success"></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field v-model="item.upperLimit" label="Üst Limit" type="number" required :rules="upperLimitRules" counter :disabled="success"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" class="pl-10 pr-10" v-show="!success && !loading" @click="save">Kaydet</v-btn>
        <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="toggleDialog">{{ !success && !loading ? "İptal" : "Kapat" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { UPDATE_BANK_ACCOUNT, TOGGLE_UPDATE_DIALOG } from "../../../../store/modules/account/bankAccount";
import { FETCH_BANKS } from "../../../../store/modules/bank";
import BankDialog from "../../bank/BankDialog";

export default {
  data: () => ({
    id: "",
    item: {
      bankIds: [],
      name: "",
      iban: "",
      description: "",
      lowerLimit: 0,
      upperLimit: 1,
    },
    bankIdRules: [(v) => v.length > 0 || "Lütfen banka seçiniz"],
    nameRules: [(v) => !!v || "Lütfen hesap adını giriniz"],
    ibanRules: [(v) => !!v || "Lütfen iban numarasını giriniz"],
    descriptionRules: [(v) => !!v || "Lütfen açıklama giriniz"],
    lowerLimitRules: [(v) => v >= 0 || "Lütfen alt limiti minimum 0 olarak giriniz"],
    upperLimitRules: [(v) => v >= 1 || "Lütfen üst limiti minimum 1 olarak giriniz"],
  }),
  computed: {
    ...mapState("bankAccount", {
      updateItem: (state) => state.update.item,
      dialog: (state) => state.update.dialog,
      loading: (state) => state.update.isLoading,
      error: (state) => state.update.error,
      success: (state) => state.update.success,
    }),
    ...mapState("bank", {
      banks: (state) => state.banks.filter((item) => item.isActive),
    }),
    ...mapState("user", {
      isAdmin: (state) => state.user.role.isAdmin,
      isBankRole: (state) => state.user.role.isBankRole,
      isSupervisor: (state) => state.user.role.isSupervisor,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
        this.fetchBanks();
      }
    },
    updateItem(newVal) {
      this.id = newVal.id;

      this.item = {
        name: newVal.name,
        iban: newVal.iban,
        description: newVal.description,
        lowerLimit: newVal.lowerLimit,
        upperLimit: newVal.upperLimit,
        bankIds: newVal.banks ? newVal.banks.map((bank) => bank.id) : [],
      };
    },
  },
  components: {
    "bank-dialog": BankDialog,
  },
  methods: {
    ...mapActions("bankAccount", {
      updateBankAccount: UPDATE_BANK_ACCOUNT,
    }),
    ...mapMutations("bankAccount", {
      toggleUpdateDialog: TOGGLE_UPDATE_DIALOG,
    }),
    ...mapActions("bank", {
      fetchBanks: FETCH_BANKS,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.updateBankAccount({ id: this.id, account: this.item });
    },
    toggleDialog() {
      this.toggleUpdateDialog();
    },
  },
};
</script>
<style scoped>
.trIban {
  width: 24px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  line-height: 40px;
  background-color: #eee;
}
</style>
