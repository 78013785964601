exports.getToken = () => {
  return localStorage.getItem("token");
};

exports.setToken = (token) => {
  localStorage.setItem("token", token);
};

exports.getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

exports.setRefreshToken = (refreshToken) => {
  localStorage.setItem("refreshToken", refreshToken);
};

exports.setTokens = ({ token, refreshToken }) => {
  localStorage.setItem("token", token);

  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }
};

exports.isLogin = () => {
  return this.getToken() && this.getRefreshToken();
};

exports.logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
};
