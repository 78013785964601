<template>
  <v-row justify="center">
    <v-dialog v-model="dialog.status" persistent :max-width="dialog.width">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialog.title }}
        </v-card-title>
        <v-card-text>{{ dialog.body }} </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialog">
            İptal
          </v-btn>
          <v-btn color="green darken-1" text @click="approve">
            Onayla
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { TOGGLE_DIALOG } from "../store/modules/global";

export default {
  data() {
    return {
      dialog: { status: false },
    };
  },
  computed: {
    ...mapState("global", {
      stateDialog: (state) => state.dialog,
    }),
  },
  watch: {
    stateDialog(value) {
      Object.assign(this.dialog, value);
    },
  },
  methods: {
    ...mapMutations("global", {
      toggleDialog: TOGGLE_DIALOG,
    }),
    approve() {
      this.dialog.cb(this.dialog.params);

      this.toggleDialog();
    },
  },
};
</script>
