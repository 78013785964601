import Vue from "vue";
import Router from "vue-router";
import { redirectIfAuthenticated, redirectIfNotAuthenticated } from "./middlewares/auth";
import AdminLayout from "./layout/AdminLayout";
import PaymentLayout from "./layout/PaymentLayout";
import Login from "./views/admin/Login";
import Dashboard from "./views/admin/dashboard/Dashboard";
import User from "./views/admin/user/User";
import Payments from "./views/admin/payment/Payments";
import PaparaPayments from "./views/admin/payment/papara/PaparaPayments";
import BankPayments from "./views/admin/payment/bank/BankPayments";
import CryptoPayments from "./views/admin/payment/crypto/CryptoPayments";
import Withdraws from "./views/admin/withdraw/Withdraws";
import PaparaWithdraws from "./views/admin/withdraw/papara/PaparaWithdraws";
import BankWithdraws from "./views/admin/withdraw/bank/BankWithdraws";
import CryptoWithdraws from "./views/admin/withdraw/crypto/CryptoWithdraws";
import PaymentArchives from "./views/admin/payment/PaymentArchives";
import WithdrawArchives from "./views/admin/withdraw/WithdrawArchives";
import Accounts from "./views/admin/account/Accounts";
import Settings from "./views/admin/settings/Settings";
import { checkAdminRoute, checkAdminRoutePaymentType } from "./middlewares/route";
import store from "./store";
import AdminErrorPage from "./views/admin/Error";
import ErrorPage from "./views/payment/Error";
import Payment from "./views/payment/Payment.vue";
import Home from "./views/home/Home.vue";
import Twofa from "./views/admin/2fa.vue";
import Archive from "./views/admin/Archive.vue";
import Job from "./views/admin/job/Job.vue";
import Cache from "./views/admin/cache/Cache.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/404",
      name: "AdminErrorPage",
      component: AdminErrorPage,
    },
    {
      path: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J",
      component: AdminLayout,
      children: [
        {
          path: "",
          component: Dashboard,
          beforeEnter: redirectIfNotAuthenticated,
        },
        {
          path: "login",
          name: "Login",
          component: Login,
          beforeEnter: redirectIfAuthenticated,
        },
        {
          path: "auth",
          name: "Auth",
          component: Twofa,
          beforeEnter: redirectIfAuthenticated,
        },
        {
          path: "users",
          name: "Users",
          component: User,
          beforeEnter: redirectIfNotAuthenticated,
          meta: {
            middleware: [checkAdminRoute],
          },
        },
        {
          path: "payments",
          name: "Payments",
          component: Payments,
          children: [
            {
              name: "PaparaPayments",
              path: "papara",
              component: PaparaPayments,
              meta: {
                parentName: "Payments",
                paymentType: "Papara",
                middleware: [checkAdminRoutePaymentType],
              },
            },
            {
              name: "BankPayments",
              path: "bank",
              component: BankPayments,
              meta: {
                parentName: "Payments",
                paymentType: "Havale",
                middleware: [checkAdminRoutePaymentType],
              },
            },
            {
              name: "CryptoPayments",
              path: "crypto",
              component: CryptoPayments,
              meta: {
                parentName: "Payments",
                paymentType: "Kripto",
                middleware: [checkAdminRoutePaymentType],
              },
            },
          ],
          beforeEnter: redirectIfNotAuthenticated,
        },
        {
          path: "withdraws",
          name: "Withdraws",
          component: Withdraws,
          children: [
            {
              name: "PaparaWithdraws",
              path: "papara",
              component: PaparaWithdraws,
              meta: {
                parentName: "Withdraws",
                paymentType: "Papara",
                middleware: [checkAdminRoutePaymentType],
              },
            },
            {
              name: "BankWithdraws",
              path: "bank",
              component: BankWithdraws,
              meta: {
                parentName: "Withdraws",
                paymentType: "Havale",
                middleware: [checkAdminRoutePaymentType],
              },
            },
            {
              name: "CryptoWithdraws",
              path: "crypto",
              component: CryptoWithdraws,
              meta: {
                parentName: "Withdraws",
                paymentType: "Kripto",
                middleware: [checkAdminRoutePaymentType],
              },
            },
          ],
          beforeEnter: redirectIfNotAuthenticated,
        },
        {
          path: "archive",
          name: "Arşiv",
          component: Archive,
          children: [
            {
              name: "PaymentArchives",
              path: "payments",
              component: PaymentArchives,
              meta: {
                middleware: [checkAdminRoute],
              },
            },
            {
              name: "WithdrawArchives",
              path: "withdraws",
              component: WithdrawArchives,
              meta: {
                middleware: [checkAdminRoute],
              },
            },
            {
              name: "Jobs",
              path: "jobs",
              component: Job,
              meta: {
                middleware: [checkAdminRoute],
              },
            },
          ],
          beforeEnter: redirectIfNotAuthenticated,
        },
        {
          path: "accounts",
          name: "Accounts",
          component: Accounts,
          beforeEnter: redirectIfNotAuthenticated,
          meta: {
            middleware: [checkAdminRoute],
          },
        },
        {
          path: "settings",
          name: "Settings",
          component: Settings,
          beforeEnter: redirectIfNotAuthenticated,
        },
        {
          path: "cache",
          name: "Cache",
          component: Cache,
          beforeEnter: redirectIfNotAuthenticated,
          meta: {
            middleware: [checkAdminRoute],
          },
        },
        {
          path: "*",
          redirect: "/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/404",
        },
      ],
    },
    {
      path: "/",
      name: "PaymentLayout",
      component: PaymentLayout,
      children: [
        {
          path: "/",
          name: "Home",
          component: Home,
        },
        {
          path: "/:tokenId",
          component: Payment,
        },
      ],
    },
    {
      path: "*",
      name: "errorpage",
      component: PaymentLayout,
      children: [
        {
          path: "",
          component: ErrorPage,
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const middlewares = to.meta.middleware;
  if (!middlewares) {
    return next();
  }

  const context = {
    to,
    from,
    next,
    store,
  };

  return middlewares.map((middleware) => {
    return middleware({
      ...context,
    });
  });
});

export default router;
