<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn outlined depressed v-on="on" block>Banka Listesi</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Banka Listesi</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 450px;">
        <v-data-table :headers="headers" :items="banks" hide-default-footer disable-pagination :search="search" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-row>
                <v-col cols="10" sm="8" md="8">
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                </v-col>
              </v-row>
              <new-bank-dialog />
            </v-toolbar>
          </template>
          <template v-slot:item.isFast="{ item }">
            <v-chip class="ma-2" color="blue" small text-color="white" v-if="item.isFast">
              Fast
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed class="mx-1" v-on="on">
                  <v-switch v-model="item.isActive" :loading="statusIsLoading" @click="changeStatus(item.id)" class="my-1" color="green" hide-details></v-switch>
                </v-btn>
              </template>
              <span>{{ item.isActive ? "Bankayı Pasif Et" : "Bankayı Aktif Et" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="pl-4 pr-4 ml-2" color="grey" text @click="dialog = false">Kapat</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { FETCH_BANKS, CHANGE_BANK_STATUS } from "../../../store/modules/bank";
import NewBankDialog from "./NewBankDialog";

export default {
  data: () => ({
    dialog: false,
    search: "",
    headers: [
      {
        text: "Banka",
        sortable: false,
        value: "name",
      },
      {
        text: "Fast",
        sortable: false,
        value: "isFast",
      },
      {
        text: "Durum",
        sortable: false,
        value: "status",
      },
      {
        text: "İşlemler",
        sortable: false,
        value: "actions",
      },
    ],
  }),
  components: {
    "new-bank-dialog": NewBankDialog,
  },
  computed: {
    ...mapState("bank", {
      banks: (state) =>
        state.banks.map((item) => ({
          ...item,
          status: item.isActive ? "Aktif" : "Pasif",
        })),
      isLoading: (state) => state.isLoading,
      error: (state) => state.error || state.status.error,
      statusIsLoading: (state) => state.status.isLoading,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) this.fetchBanks();
    },
  },
  methods: {
    ...mapActions("bank", {
      fetchBanks: FETCH_BANKS,
      changeStatus: CHANGE_BANK_STATUS,
    }),
  },
};
</script>
