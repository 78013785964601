<template>
  <div>
    <v-row>
      <v-col class="gray-border">
        <v-tabs v-model="tab">
          <v-tab v-for="item in paymentTypes" :key="item.name" :to="getUrl(item.name)">
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <router-view>
          <component v-bind:is="item.name" v-for="item in paymentTypes" :key="item.name"></component>
        </router-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PaparaPayments from "./papara/PaparaPayments";
import BankPayments from "./bank/BankPayments";
import CryptoPayments from "./crypto/CryptoPayments";
import { mapState, mapActions } from "vuex";
import { FETCH_PAYMENT_TYPES } from "../../../store/modules/paymentType";
import { FETCH_PAYMENT_STATUSES } from "../../../store/modules/paymentStatus";

export default {
  data() {
    return {
      tab: null,
      status: "",
      mapping: new Map([
        ["Papara", "papara"],
        ["Havale", "bank"],
        ["Kripto", "crypto"],
      ]),
    };
  },
  computed: {
    ...mapState("paymentType", {
      paymentTypes: (state) => state.paymentTypes,
    }),
  },
  components: {
    Papara: PaparaPayments,
    Havale: BankPayments,
    Kripto: CryptoPayments,
  },
  created() {
    this.fetchPaymentStatuses();
    this.fetchPaymentTypes();
  },
  methods: {
    ...mapActions("paymentType", {
      fetchPaymentTypes: FETCH_PAYMENT_TYPES,
    }),
    ...mapActions("paymentStatus", {
      fetchPaymentStatuses: FETCH_PAYMENT_STATUSES,
    }),
    getUrl(paymentType) {
      return `/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/payments/${this.mapping.get(paymentType)}`;
    },
  },
};
</script>
