<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="success" v-on="on">
        <v-icon left>mdi-plus-thick</v-icon> Yeni Hesap</v-btn
      >
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Hesap Oluştur</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <v-form ref="form">
          <v-alert
            v-show="error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="success"
            color="primary"
            type="success"
            icon="mdi-cloud-check"
            >Hesap Oluşturuldu</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.name"
                  label="Hesap Adı"
                  required
                  :rules="nameRules"
                  maxlength="50"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.number"
                  label="Hesap No"
                  required
                  :rules="numberRules"
                  maxlength="20"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="item.lowerLimit"
                  label="Alt Limit"
                  type="number"
                  required
                  :rules="lowerLimitRules"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="item.upperLimit"
                  label="Üst Limit"
                  type="number"
                  required
                  :rules="upperLimitRules"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="pl-10 pr-10"
          v-show="!success && !loading"
          @click="save"
          >Kaydet</v-btn
        >
        <v-btn
          outlined
          class="pl-4 pr-4 ml-2"
          color="grey"
          text
          @click="dialog = false"
          >{{ !success && !loading ? "İptal" : "Kapat" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  CREATE_PAPARA_ACCOUNT,
  TOGGLE_CREATE_DIALOG,
} from "../../../../store/modules/account/paparaAccount";

export default {
  data: () => ({
    dialog: false,
    item: {
      name: "",
      number: "",
      lowerLimit: 0,
      upperLimit: 1,
    },
    nameRules: [(v) => !!v || "Lütfen hesap adını giriniz"],
    numberRules: [(v) => !!v || "Lütfen hesap numarasını giriniz"],
    lowerLimitRules: [
      (v) => v >= 0 || "Lütfen alt limiti minimum 0 olarak giriniz",
    ],
    upperLimitRules: [
      (v) => v >= 1 || "Lütfen üst limiti minimum 1 olarak giriniz",
    ],
  }),
  computed: {
    ...mapState("paparaAccount", {
      loading: (state) => state.create.isLoading,
      error: (state) => state.create.error,
      success: (state) => state.create.success,
    }),
  },

  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
        this.clearCreateDialog();
        this.item = { name: "", number: "", lowerLimit: 0, upperLimit: 1 };
      }
    },
  },
  methods: {
    ...mapActions("paparaAccount", {
      createPaparaAccount: CREATE_PAPARA_ACCOUNT,
    }),
    ...mapMutations("paparaAccount", {
      clearCreateDialog: TOGGLE_CREATE_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.createPaparaAccount(this.item);
    },
  },
};
</script>
