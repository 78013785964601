<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form" :lazy-validation="false">
        <v-card-title>
          <span class="headline">Yetkiyi Sil</span>
        </v-card-title>
        <v-alert v-show="error" text prominent type="error" icon="mdi-alert-circle">
          <span v-html="error" />
        </v-alert>
        <v-alert v-show="success" text prominent type="success" icon="mdi-cloud-check">Yetki Silindi</v-alert>
        <v-card-text>
          <v-container v-if="!success">Bu yetkiyi silmek istediğinize emin misiniz?</v-container>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-show="!success" depressed color="primary" @click="deleteRole(id)">Sil</v-btn>
          <v-btn color="grey darken-1" text @click="toggleDialog">{{ success ? "Kapat" : "İptal" }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { DELETE_ROLE, TOGGLE_DELETE_DIALOG } from "../../../store/modules/role";

export default {
  computed: {
    ...mapState("role", {
      id: (state) => state.delete.id,
      dialog: (state) => state.delete.dialog,
      loading: (state) => state.delete.isLoading,
      success: (state) => state.delete.success,
      error: (state) => state.delete.error,
    }),
  },
  methods: {
    ...mapActions("role", {
      deleteRole: DELETE_ROLE,
    }),
    ...mapMutations("role", {
      toggleDialog: TOGGLE_DELETE_DIALOG,
    }),
  },
};
</script>
