<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Sayfa Yetkileri</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="pageAuths"
          hide-default-footer
          :search="search"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-row>
                <v-col cols="10" sm="3" md="8" offset-md="2">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <new-page-auth-dialog :roleId="roleId" />
              <delete-page-auth-dialog />
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  color="error"
                  dark
                  @click="
                    toggleDialog({ pageId: item.pageId, roleId: item.roleId })
                  "
                  v-on="on"
                >
                  <v-icon size="18">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Yetkiyi Sil</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="togglePageAuthDialog()"
          >İptal</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  TOGGLE_PAGE_AUTH_DIALOG,
  FETCH_PAGE_AUTHS,
  TOGGLE_DELETE_DIALOG,
} from "../../../store/modules/pageAuth";
import NewPageAuthDialog from "./NewPageAuthDialog";
import DeletePageAuthDialog from "./DeletePageAuthDialog";

export default {
  data: () => ({
    search: "",
    headers: [
      {
        text: "Yetki",
        sortable: false,
        value: "role.name",
      },
      {
        text: "Sayfa",
        sortable: false,
        value: "page.name",
      },
      {
        text: "İşlemler",
        sortable: false,
        value: "actions",
      },
    ],
  }),
  components: {
    "new-page-auth-dialog": NewPageAuthDialog,
    "delete-page-auth-dialog": DeletePageAuthDialog,
  },
  computed: {
    ...mapState("pageAuth", {
      dialog: (state) => state.dialog,
      roleId: (state) => state.roleId,
      pageAuths: (state) => state.pageAuths.map((item) => ({ ...item })),
      isLoading: (state) => state.isLoading,
      error: (state) => state.error,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) this.fetchPageAuths(this.roleId);
    },
  },
  methods: {
    ...mapActions("pageAuth", {
      fetchPageAuths: FETCH_PAGE_AUTHS,
    }),
    ...mapMutations("pageAuth", {
      toggleDialog: TOGGLE_DELETE_DIALOG,
      togglePageAuthDialog: TOGGLE_PAGE_AUTH_DIALOG,
    }),
  },
};
</script>
