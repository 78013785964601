<template>
  <div>
    <v-container fluid>
      <h3 v-if="paymentCounts.length > 0" class="mb-2">Yatırımlar</h3>
      <v-row>
        <v-col cols="12" xs="12" sm="4" md="4" v-for="(item, index) in paymentCounts" :key="index">
          <component v-bind:is="item.paymentType" :counts="item.counts"></component>
        </v-col>
      </v-row>
      <br />
      <h3 v-if="withdrawCounts.length > 0" class="mb-2">Çekimler</h3>
      <v-row>
        <v-col cols="12" xs="12" sm="4" md="4" v-for="(item, index) in withdrawCounts" :key="index + 100">
          <component v-bind:is="getWithdrawComponentName(item.paymentType)" :counts="item.counts"></component>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { GET_PAYMENT_COUNTS } from "../../../store/modules/payment/payment";
import { GET_WITHDRAW_COUNTS } from "../../../store/modules/withdraw/withdraw";
import PaparaDashboard from "./PaparaDashboard";
import PaparaWithdrawDashboard from "./PaparaWithdrawDashboard.vue";
import BankDashboard from "./BankDashboard";
import BankWithdrawDashboard from "./BankWithdrawDashboard.vue";
import CryptoDashboard from "./CryptoDashboard";
import CryptoWithdrawDashboard from "./CryptoWithdrawDashboard.vue";

export default {
  components: {
    Papara: PaparaDashboard,
    "Papara-Withdraw": PaparaWithdrawDashboard,
    Havale: BankDashboard,
    "Havale-Withdraw": BankWithdrawDashboard,
    Kripto: CryptoDashboard,
    "Kripto-Withdraw": CryptoWithdrawDashboard,
  },
  computed: {
    ...mapState("payment", {
      paymentCounts: (state) => state.paymentCounts,
    }),
    ...mapState("withdraw", {
      withdrawCounts: (state) => state.withdrawCounts,
    }),
  },
  created() {
    this.getPaymentCounts();
    this.getWithdrawCounts();
  },
  methods: {
    ...mapActions("payment", {
      getPaymentCounts: GET_PAYMENT_COUNTS,
    }),
    ...mapActions("withdraw", {
      getWithdrawCounts: GET_WITHDRAW_COUNTS,
    }),
    getWithdrawComponentName(paymentType) {
      return `${paymentType}-Withdraw`;
    },
  },
};
</script>
