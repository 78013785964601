<template>
  <div>
    <v-skeleton-loader
      v-if="!payment"
      dark
      v-bind="attrs"
      type="article, actions"
      class="mx-auto my-12"
      max-width="600"
    ></v-skeleton-loader>
    <v-card class="mx-auto my-12" dark max-width="600" v-if="payment">
      <v-subheader class="font-weight-normal">
        <strong class="cyellow--text">Banka Havalesi</strong>
      </v-subheader>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-card class="mx-auto" outlined elevation="4" loading="orange">
          <v-card-title class="pa-02">
            <v-icon color="orange" class="mr-3">
              mdi-timeline-clock
            </v-icon>
            <span style="color:orange">Yatırımınız Kontrol Ediliyor</span>
          </v-card-title>
          <v-card-text class="px-4 pb-4">{{
            payment.approvalPeriod
          }}</v-card-text>
        </v-card>
        <v-alert
          class="mt-4 mb-0"
          :value="alert"
          type="info"
          style="font-size:14px"
          transition="fab-transition"
          ><strong>Önemli Bilgilendirme</strong><br />Bu aşamada Canlı Destek'e
          bağlanmayınız ya da yeni form doldurmayınız, bu kontrol işlemini
          yavaşlatmaktadır.</v-alert
        >
      </v-card-text>
      
      <v-card-text>
        <v-card class="mx-auto pa-3" outlined elevation="4">
          <v-alert center dense text class="mb-3">
            Banka Hesabımız
          </v-alert>
          <div class="grey--text">
            Hesap Adı
          </div>
          <v-card-title class="pa-0">
            {{ payment.account.name }}
          </v-card-title>
          <v-spacer class="pa-2"></v-spacer>
          <div class="grey--text">
            IBAN No
          </div>
          <v-card-title class="pa-0">
            TR {{ payment.account.iban }}
          </v-card-title>
          <v-spacer class="pa-2"></v-spacer>
          <div class="grey--text">
            İşlem Açıklaması
          </div>
          <v-card-title class="pa-0">
            {{ payment.account.description }}
          </v-card-title>
          <v-spacer class="pa-2"></v-spacer>
          <div class="grey--text">
            Yatırım Tutarı
            <span v-if="payment.coinValue">/ Kripto Karşılığı</span>
          </div>
          <v-card-title class="pa-0">
            {{ payment.amount }} <small class="ml-1">TL</small>
          </v-card-title>
        </v-card>
      </v-card-text>
      <redirect-to-site class="ml-4" />
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RedirectToSite from "../Redirect";

export default {
  data() {
    return {
      alert: false,
    };
  },
  components: {
    "redirect-to-site": RedirectToSite,
  },
  methods: {
    showAlert: function() {
      window.setInterval(() => {
        this.alert = true;
      }, 1000);
    },
  },
  computed: {
    ...mapState("payment", {
      payment: (state) => state.data,
    }),
  },
  mounted: function() {
    if (alert) {
      this.showAlert();
    }
  },
};
</script>
