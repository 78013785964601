import Axios from "axios";
import { getToken, getRefreshToken, setTokens, logout } from "../auth";
import router from "../router";
import config from "../config";

class Api {
  constructor() {
    this.sender = Axios.create({
      baseURL: `${config.SERVER_URL}${config.API_URL_PATH}/admin`,
      transformRequest: [
        function(data, headers) {
          headers["Authorization"] = `Bearer ${getToken()}`;
          if (headers["Content-Type"] == "application/json") {
            return JSON.stringify(data);
          }
          return data;
        },
      ],
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.sender.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error.response && error.response.status === 401) {
          const refreshToken = getRefreshToken();
          if (!refreshToken) {
            logout();
            router.push("Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/login");
          }

          return Axios.post(`${config.SERVER_URL}${config.API_URL_PATH}/admin/login/refresh`, {
            refreshToken,
          })
            .then((response) => {
              setTokens(response.data);

              if (error.config.data) {
                error.config.data = JSON.parse(error.config.data);
              }

              error.config.headers = {
                Authorization: `Bearer ${response.data.token}`,
                "Content-Type": "application/json",
              };

              Axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;

              return Axios(error.config);
            })
            .catch((refreshError) => {
              console.log("refreshError", refreshError);
              if (refreshError && refreshError.response && refreshError.response.status === 401) {
                logout();
                router.push("/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/login");
              }

              return Promise.reject(error);
            });
        }

        return Promise.reject(error);
      }
    );
  }

  async login(login) {
    let { data } = await this.sender.post("/login", login);

    if (data.token) setTokens(data);

    return data;
  }

  async auth(code) {
    let { data } = await this.sender.post("/login/auth", code);

    if (data.token) setTokens(data);

    return data;
  }

  async getUsers() {
    let { data } = await this.sender.get("/user");
    return data;
  }

  async filterUsers({ status, name, email, roleId, login, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ status, name, email, roleId, login, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1] || item[1] === false) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/user/filter?${query}`);
    return data;
  }

  async exportUsers(filter) {
    return await this.filterUsers(filter);
  }

  async createUser(user) {
    let { data } = await this.sender.post("/user", user);
    return data;
  }

  async updateUser(id, user) {
    let { data } = await this.sender.patch(`/user/${id}`, user);
    return data;
  }

  async changeUserStatus(id) {
    let { data } = await this.sender.patch(`/user/${id}/status`);
    return data;
  }

  async changeUserPassword(password) {
    let { data } = await this.sender.patch(`/user/password/change`, password);
    return data;
  }

  async updateUser2fa() {
    let { data } = await this.sender.patch("/user/2fa/change");
    return data;
  }

  async resetUser2fa(userId) {
    let { data } = await this.sender.patch("/user/2fa/reset", userId);
    return data;
  }

  async getRoles() {
    let { data } = await this.sender.get("/role");
    return data;
  }

  async createRole(role) {
    let { data } = await this.sender.post("/role", role);
    return data;
  }

  async deleteRole(id) {
    let { data } = await this.sender.delete(`/role/${id}`);
    return data;
  }

  async getPageAuths(roleId) {
    let { data } = await this.sender.get(`/auth/page/role/${roleId}`);
    return data;
  }

  async createPageAuths(auth) {
    let { data } = await this.sender.post("/auth/page", auth);
    return data;
  }

  async deletePageAuths(pageId, roleId) {
    let { data } = await this.sender.delete(`/auth/page/${pageId}/role/${roleId}`);
    return data;
  }

  async getPaymentTypeAuths(roleId) {
    let { data } = await this.sender.get(`/auth/payment-type/role/${roleId}`);
    return data;
  }

  async createPaymentTypeAuths(auth) {
    let { data } = await this.sender.post("/auth/payment-type", auth);
    return data;
  }

  async deletePaymentTypeAuths(paymentTypeId, roleId) {
    let { data } = await this.sender.delete(`/auth/payment-type/${paymentTypeId}/role/${roleId}`);
    return data;
  }

  async getPages() {
    let { data } = await this.sender.get(`/page`);
    return data;
  }

  async getPaymentTypes() {
    let { data } = await this.sender.get(`/payment-type`);
    return data;
  }

  async filterPaparaAccounts({ startDate, endDate, status, name, number, isBanned, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ startDate, endDate, status, name, number, isBanned, itemsPerPage, page });
    for (const item of queryArray) {
      if (item[1]) query += `${item[0]}=${item[1]}&`;
    }

    let { data } = await this.sender.get(`/account/papara/filter?${query}`);
    return data;
  }

  async exportPaparaAccounts(filter) {
    return await this.filterPaparaAccounts(filter);
  }

  async createPaparaAccount(account) {
    let { data } = await this.sender.post("/account/papara", account);
    return data;
  }

  async updatePaparaAccount(id, account) {
    let { data } = await this.sender.patch(`/account/papara/${id}`, account);
    return data;
  }

  async changePaparaAccountStatus(id) {
    let { data } = await this.sender.patch(`/account/papara/${id}/status`);
    return data;
  }

  async bannedPaparaAccount(id) {
    let { data } = await this.sender.patch(`/account/papara/${id}/banned`);
    return data;
  }

  async banRollbackPaparaAccount(id) {
    let { data } = await this.sender.patch(`/account/papara/${id}/ban/rollback`);
    return data;
  }

  async updatePaparaAccountNote(id, note) {
    let { data } = await this.sender.patch(`/account/papara/${id}/note`, note);
    return data;
  }

  async updatePaparaAccountBanInfo(id, ban) {
    let { data } = await this.sender.patch(`/account/papara/${id}/banned/info`, ban);
    return data;
  }

  async filterBankAccounts({ startDate, endDate, status, name, iban, banks, isBanned, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ startDate, endDate, status, name, iban, banks, isBanned, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/account/bank/filter?${query}`);
    return data;
  }

  async getAllBankAccounts() {
    let { data } = await this.sender.get(`/account/bank/all`);
    return data;
  }

  async exportBankAccounts(filter) {
    return await this.filterBankAccounts(filter);
  }

  async createBankAccount(account) {
    let { data } = await this.sender.post("/account/bank", account);
    return data;
  }

  async updateBankAccount(id, account) {
    let { data } = await this.sender.patch(`/account/bank/${id}`, account);
    return data;
  }

  async changeBankAccountStatus(id) {
    let { data } = await this.sender.patch(`/account/bank/${id}/status`);
    return data;
  }

  async bannedBankAccount(id) {
    let { data } = await this.sender.patch(`/account/bank/${id}/banned`);
    return data;
  }

  async banRollbackBankAccount(id) {
    let { data } = await this.sender.patch(`/account/bank/${id}/ban/rollback`);
    return data;
  }

  async updateBankAccountNote(id, note) {
    let { data } = await this.sender.patch(`/account/bank/${id}/note`, note);
    return data;
  }

  async filterCryptoAccounts({ startDate, endDate, status, number, cryptos, isBanned, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ startDate, endDate, status, number, cryptos, isBanned, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/account/crypto/filter?${query}`);
    return data;
  }

  async exportCryptoAccounts(filter) {
    return await this.filterCryptoAccounts(filter);
  }

  async createCryptoAccount(account) {
    let { data } = await this.sender.post("/account/crypto", account);
    return data;
  }

  async updateCryptoAccount(id, account) {
    let { data } = await this.sender.patch(`/account/crypto/${id}`, account);
    return data;
  }

  async changeCryptoAccountStatus(id) {
    let { data } = await this.sender.patch(`/account/crypto/${id}/status`);
    return data;
  }

  async bannedCryptoAccount(id) {
    let { data } = await this.sender.patch(`/account/crypto/${id}/banned`);
    return data;
  }

  async banRollbackCryptoAccount(id) {
    let { data } = await this.sender.patch(`/account/crypto/${id}/ban/rollback`);
    return data;
  }

  async updateCryptoAccountNote(id, note) {
    let { data } = await this.sender.patch(`/account/crypto/${id}/note`, note);
    return data;
  }

  async getBanks() {
    let { data } = await this.sender.get("/bank");
    return data;
  }

  async createBank(bank) {
    let { data } = await this.sender.post("/bank", bank);
    return data;
  }

  async changeBankStatus(id) {
    let { data } = await this.sender.patch(`/bank/${id}/status`);
    return data;
  }

  async getCryptos() {
    let { data } = await this.sender.get("/crypto");
    return data;
  }

  async createCrypto(crypto) {
    let { data } = await this.sender.post("/crypto", crypto);
    return data;
  }

  async changeCryptoStatus(id) {
    let { data } = await this.sender.patch(`/crypto/${id}/status`);
    return data;
  }

  async filterPaparaPayments({ startDate, endDate, status, name, surname, loginId, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ startDate, endDate, status, name, surname, loginId, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/payment/papara/filter?${query}`);
    return data;
  }

  async filterPaparaPaymentArchives({ year, startDate, endDate, status, name, surname, loginId, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ year, startDate, endDate, status, name, surname, loginId, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/payment/papara/filter/archive?${query}`);
    return data;
  }

  async exportPaparaPayments(filter) {
    return await this.filterPaparaPayments(filter);
  }

  async exportPaparaPaymentArchives(filter) {
    return await this.filterPaparaPaymentArchives(filter);
  }

  async approvePaparaPayment(id) {
    let { data } = await this.sender.patch(`/payment/papara/${id}/approve`);
    return data;
  }

  async rejectPaparaPayment(id, descriptionId) {
    let { data } = await this.sender.patch(`/payment/papara/${id}/reject`, { descriptionId });
    return data;
  }

  async getPaymentStatuses() {
    let { data } = await this.sender.get(`/payment-status`);
    return data;
  }

  async filterBankPayments({ autoFilter, startDate, endDate, status, name, surname, loginId, accounts, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ autoFilter, startDate, endDate, status, name, surname, loginId, accounts, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/payment/bank/filter?${query}`);
    return data;
  }

  async filterBankPaymentArchives({ autoFilter, year, startDate, endDate, status, name, surname, loginId, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ autoFilter, year, startDate, endDate, status, name, surname, loginId, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/payment/bank/filter/archive?${query}`);
    return data;
  }

  async filterBankPaymentsByStatus({ autoFilter, startDate, endDate, status, name, surname, loginId }) {
    let query = "";

    let queryArray = Object.entries({ autoFilter, startDate, endDate, status, name, surname, loginId });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/payment/bank/filter/status?${query}`);
    return data;
  }

  async filterBankLastPayments({ loginId }) {
    let query = "";

    let queryArray = Object.entries({ loginId });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/payment/bank/filter/last?${query}`);
    return data;
  }

  async exportBankPayments(filter) {
    return await this.filterBankPayments(filter);
  }

  async exportBankPaymentArchives(filter) {
    return await this.filterBankPaymentArchives(filter);
  }

  async approveBankPayment(id) {
    let { data } = await this.sender.patch(`/payment/bank/${id}/approve`);
    return data;
  }

  async rejectBankPayment(id, descriptionId) {
    let { data } = await this.sender.patch(`/payment/bank/${id}/reject`, { descriptionId });
    return data;
  }

  async filterCryptoPayments({ startDate, endDate, status, name, surname, loginId, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ startDate, endDate, status, name, surname, loginId, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/payment/crypto/filter?${query}`);
    return data;
  }

  async filterCryptoPaymentArchives({ year, startDate, endDate, status, name, surname, loginId, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ year, startDate, endDate, status, name, surname, loginId, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/payment/crypto/filter/archive?${query}`);
    return data;
  }

  async exportCryptoPayments(filter) {
    return await this.filterCryptoPayments(filter);
  }

  async exportCryptoPaymentArchives(filter) {
    return await this.filterCryptoPaymentArchives(filter);
  }

  async approveCryptoPayment(id) {
    let { data } = await this.sender.patch(`/payment/crypto/${id}/approve`);
    return data;
  }

  async rejectCryptoPayment(id, descriptionId) {
    let { data } = await this.sender.patch(`/payment/crypto/${id}/reject`, { descriptionId });
    return data;
  }

  async getUserInfo() {
    let { data } = await this.sender.get(`/user/info`);
    return data;
  }

  async getPaparaStatusByCount() {
    let { data } = await this.sender.get(`/payment/papara/status`);
    return data;
  }

  async getBankStatusByCount() {
    let { data } = await this.sender.get(`/payment/bank/status`);
    return data;
  }

  async getCryptoStatusByCount() {
    let { data } = await this.sender.get(`/payment/crypto/status`);
    return data;
  }

  async getPaparaPaymentRejectDescriptions() {
    let { data } = await this.sender.get("/payment-reject/papara");
    return data;
  }

  async getBankPaymentRejectDescriptions() {
    let { data } = await this.sender.get("/payment-reject/bank");
    return data;
  }

  async getCryptoPaymentRejectDescriptions() {
    let { data } = await this.sender.get("/payment-reject/crypto");
    return data;
  }

  async getPaymentCounts() {
    let { data } = await this.sender.get("/payment/count");

    return data;
  }

  async getWithdrawStatus() {
    let { data } = await this.sender.get("/withdraw/status");
    return data;
  }

  async getWithdrawDescriptions(paymentTypeName) {
    let { data } = await this.sender.get(`/withdraw/description/type/${paymentTypeName}`);
    return data;
  }

  async getWithdrawResultDescriptions(paymentTypeName, isSuccess) {
    let { data } = await this.sender.get(`/withdraw/result-description/type/${paymentTypeName}/success/${isSuccess}`);
    return data;
  }

  async createPaparaWithdraw(withdraw) {
    let { data } = await this.sender.post("/withdraw/papara", withdraw);
    return data;
  }

  async filterPaparaWithdraws({ startDate, endDate, status, transactionNumber, customerName, customerSurname, customerId, number, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ startDate, endDate, transactionNumber, status, customerName, customerSurname, customerId, number, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/withdraw/papara/filter?${query}`);
    return data;
  }

  async historyPaparaWithdraw(id) {
    let { data } = await this.sender.get(`/withdraw/papara/${id}/history`);
    return data;
  }

  async exportPaparaWithdraws(filter) {
    return await this.filterPaparaWithdraws(filter);
  }

  async filterPaparaWithdrawsArchive({ startDate, endDate, status, transactionNumber, customerName, customerSurname, customerId, number, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ startDate, endDate, transactionNumber, status, customerName, customerSurname, customerId, number, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/withdraw/papara/filter/archive?${query}`);
    return data;
  }

  async historyPaparaWithdrawArchive(id) {
    let { data } = await this.sender.get(`/withdraw/papara/${id}/history/archive`);
    return data;
  }

  async exportPaparaWithdrawsArchive(filter) {
    return await this.filterPaparaWithdrawsArchive(filter);
  }

  async inProgressPaparaWithdraw(id) {
    let { data } = await this.sender.patch(`/withdraw/papara/${id}/in-progress`);
    return data;
  }

  async cancelPaparaWithdraw(id) {
    let { data } = await this.sender.patch(`/withdraw/papara/${id}/cancel`);
    return data;
  }

  async approvePaparaWithdraw(id, body) {
    let { data } = await this.sender.patch(`/withdraw/papara/${id}/approve`, body);
    return data;
  }

  async rejectPaparaWithdraw(id, body) {
    let { data } = await this.sender.patch(`/withdraw/papara/${id}/reject`, body);
    return data;
  }

  async createBankWithdraw(withdraw) {
    let { data } = await this.sender.post("/withdraw/bank", withdraw);
    return data;
  }

  async filterBankWithdraws({ startDate, endDate, transactionNumber, status, customerName, customerSurname, customerId, iban, users, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ startDate, endDate, transactionNumber, status, customerName, customerSurname, customerId, iban, users, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/withdraw/bank/filter?${query}`);
    return data;
  }

  async exportBankWithdraws(filter) {
    return await this.filterBankWithdraws(filter);
  }

  async historyBankWithdraw(id) {
    let { data } = await this.sender.get(`/withdraw/bank/${id}/history`);
    return data;
  }

  async filterBankWithdrawsArchive({ startDate, endDate, status, transactionNumber, customerName, customerSurname, customerId, iban, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ startDate, endDate, transactionNumber, status, customerName, customerSurname, customerId, iban, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/withdraw/bank/filter/archive?${query}`);
    return data;
  }

  async historyBankWithdrawArchive(id) {
    let { data } = await this.sender.get(`/withdraw/bank/${id}/history/archive`);
    return data;
  }

  async exportBankWithdrawsArchive(filter) {
    return await this.filterBankWithdrawsArchive(filter);
  }

  async inProgressBankWithdraw(id) {
    let { data } = await this.sender.patch(`/withdraw/bank/${id}/in-progress`);
    return data;
  }

  async cancelBankWithdraw(id) {
    let { data } = await this.sender.patch(`/withdraw/bank/${id}/cancel`);
    return data;
  }

  async approveBankWithdraw(id, body) {
    let { data } = await this.sender.patch(`/withdraw/bank/${id}/approve`, body);
    return data;
  }

  async rejectBankWithdraw(id, body) {
    let { data } = await this.sender.patch(`/withdraw/bank/${id}/reject`, body);
    return data;
  }

  async createCryptoWithdraw(withdraw) {
    let { data } = await this.sender.post("/withdraw/crypto", withdraw);
    return data;
  }

  async filterCryptoWithdraws({ startDate, endDate, transactionNumber, status, customerName, customerSurname, customerId, number, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ startDate, endDate, transactionNumber, status, customerName, customerSurname, customerId, number, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/withdraw/crypto/filter?${query}`);
    return data;
  }

  async exportCryptoWithdraws(filter) {
    return await this.filterCryptoWithdraws(filter);
  }

  async historyCryptoWithdraw(id) {
    let { data } = await this.sender.get(`/withdraw/crypto/${id}/history`);
    return data;
  }

  async inProgressCryptoWithdraw(id) {
    let { data } = await this.sender.patch(`/withdraw/crypto/${id}/in-progress`);
    return data;
  }

  async cancelCryptoWithdraw(id) {
    let { data } = await this.sender.patch(`/withdraw/crypto/${id}/cancel`);
    return data;
  }

  async approveCryptoWithdraw(id, body) {
    let { data } = await this.sender.patch(`/withdraw/crypto/${id}/approve`, body);
    return data;
  }

  async rejectCryptoWithdraw(id, body) {
    let { data } = await this.sender.patch(`/withdraw/crypto/${id}/reject`, body);
    return data;
  }

  async getWithdrawCounts() {
    let { data } = await this.sender.get("/withdraw/count");

    return data;
  }

  async getBankUsers() {
    let { data } = await this.sender.get("/user/bank");

    return data;
  }

  async filterJobs({ startDate, endDate, type, isError, paymentTypeId, isProcess, itemsPerPage, page }) {
    let query = "";

    let queryArray = Object.entries({ startDate, endDate, type, isError, paymentTypeId, isProcess, itemsPerPage, page });

    for (const item of queryArray) {
      if (item[1] || item[1] === false) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/job?${query}`);
    return data;
  }

  async createJob(job) {
    let { data } = await this.sender.post("/job", job);
    return data;
  }

  async getJobDetails(id) {
    let { data } = await this.sender.get(`/job/${id}`);
    return data;
  }

  async cancelJob(id) {
    let { data } = await this.sender.delete(`/job/${id}`);
    return data;
  }

  async filterCache({ key }) {
    let query = "";

    let queryArray = Object.entries({ key });

    for (const item of queryArray) {
      if (item[1]) {
        if (Array.isArray(item[1])) {
          query += item[1].map((s) => `${item[0]}[]=${s}`).join("&") + "&";
        } else {
          query += `${item[0]}=${item[1]}&`;
        }
      }
    }

    let { data } = await this.sender.get(`/cache/filter?${query}`);
    return data;
  }

  async deleteCache(key) {
    await this.sender.delete(`/cache/${key}`);
  }

  async getBankPaymentHistory(id) {
    let { data } = await this.sender.get(`/payment/bank/${id}/history`);
    return data;
  }

  async getPaparaPaymentHistory(id) {
    let { data } = await this.sender.get(`/payment/papara/${id}/history`);
    return data;
  }

  async getCryptoPaymentHistory(id) {
    let { data } = await this.sender.get(`/payment/crypto/${id}/history`);
    return data;
  }

  async getBankPaymentArchiveHistory(id) {
    let { data } = await this.sender.get(`/payment/bank/archive/${id}/history`);
    return data;
  }

  async getPaparaPaymentArchiveHistory(id) {
    let { data } = await this.sender.get(`/payment/papara/archive/${id}/history`);
    return data;
  }

  async getCryptoPaymentArchiveHistory(id) {
    let { data } = await this.sender.get(`/payment/crypto/archive/${id}/history`);
    return data;
  }
}

export default new Api();
