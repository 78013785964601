import { isLogin } from "../auth";

export function redirectIfAuthenticated(to, from, next) {
  if (isLogin()) next("Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J");
  else next();
}

export function redirectIfNotAuthenticated(to, from, next) {
  if (isLogin()) next();
  else next("Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/login");
}
