<template>
  <v-container fluid class="bg-img">
    <v-row class="mb-6" justify="center" align="center">
      <v-col lg="4" md="5">
        <v-card class="pa-4 rounded" outlined tile elevation="2">
          <v-progress-linear v-show="isLoading" indeterminate color="yellow darken-2"></v-progress-linear>
          <v-alert v-if="errorMessage" text prominent type="error" icon="mdi-alert-circle">{{ errorMessage }}</v-alert>
          <v-toolbar color="lightgray" flat>
            <v-toolbar-title>
              <v-icon color="primary" class="mr-4">mdi-badge-account-outline</v-icon>
              <span>Lütfen 6 haneli kodu giriniz</span>
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" @submit.prevent="">
              <v-text-field v-model="code" :rules="codeRules" label="Kod" maxlength="6" counter required v-on:keyup.enter="login"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" v-on:click="login" type="button">
              Onayla
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdminApi from "../../api/adminApi";

export default {
  data: () => ({
    code: "",
    codeRules: [(v) => !!v || "Lütfen uygulama üzerinden aldığınız 6 haneli kodu giriniz"],
    isLoading: false,
    errorMessage: "",
  }),
  methods: {
    async login() {
      this.errorMessage = "";

      if (!this.$refs.form.validate()) {
        console.log("validate error");
        return;
      }

      try {
        this.isLoading = true;

        await AdminApi.auth({ code: this.code });
        this.$router.push("/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J");
      } catch (error) {
        this.errorMessage = error.response.data;
        if (error.response && error.response.status === 401) this.$router.push("/Ps1uZwtEozS83aFjeuSDITGBjqHlmk2J/login");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
