<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" depressed v-on="on">
        <v-icon left>mdi-plus-thick</v-icon>Yeni</v-btn
      >
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Yetki Oluştur</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-alert
            v-show="error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
            >Yetki Oluşturuldu</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.name"
                  label="Yetki Adı"
                  required
                  :rules="nameRules"
                  maxlength="20"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-switch
                  v-model="item.isAdmin"
                  label="Tam Yetki"
                  class="my-1"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="pl-10 pr-10"
          v-show="!success && !loading"
          @click="save"
          >Kaydet</v-btn
        >
        <v-btn
          outlined
          class="pl-4 pr-4 ml-2"
          color="grey"
          text
          @click="dialog = false"
          >{{ !success && !loading ? "İptal" : "Kapat" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { CREATE_ROLE, TOGGLE_CREATE_DIALOG } from "../../../store/modules/role";

export default {
  data: () => ({
    dialog: false,
    item: {
      name: "",
      isAdmin: false,
    },
    nameRules: [(v) => !!v || "Yetki adını giriniz"],
  }),
  computed: {
    ...mapState("role", {
      loading: (state) => state.create.isLoading,
      error: (state) => state.create.error,
      success: (state) => state.create.success,
    }),
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset();
        this.clearCreateDialog();
        this.item = { name: "", isAdmin: false };
      }
    },
  },
  methods: {
    ...mapActions("role", {
      createRole: CREATE_ROLE,
    }),
    ...mapMutations("role", {
      clearCreateDialog: TOGGLE_CREATE_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.createRole(this.item);
    },
  },
};
</script>
