import AdminApi from "../../api/adminApi";

export const FETCH_PAGE_AUTHS = "FETCH_PAGE_AUTHS";
export const CREATE_PAGE_AUTHS = "CREATE_PAGE_AUTHS";
export const DELETE_PAGE_AUTHS = "DELETE_PAGE_AUTHS";

export const TOGGLE_PAGE_AUTH_DIALOG = "TOGGLE_PAGE_AUTH_DIALOG";
export const SET_PAGE_AUTHS = "SET_PAGE_AUTHS";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ERROR = "SET_ERROR";

export const CLEAR_CREATE = "CLEAR_CREATE";
export const TOGGLE_CREATE_DIALOG = "TOGGLE_CREATE_DIALOG";
export const SET_CREATE_IS_LOADING = "SET_CREATE_IS_LOADING";
export const SET_CREATE_SUCCESS = "SET_CREATE_SUCCESS";
export const SET_CREATE_ERROR = "SET_CREATE_ERROR";

export const TOGGLE_DELETE_DIALOG = "TOGGLE_DELETE_DIALOG";
export const SET_DELETE_IS_LOADING = "SET_DELETE_IS_LOADING";
export const SET_DELETE_SUCCESS = "SET_DELETE_SUCCESS";
export const SET_DELETE_ERROR = "SET_DELETE_ERROR";

const state = {
  dialog: false,
  roleId: "",
  pageAuths: [],
  isLoading: false,
  error: "",
  create: {
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  delete: {
    dialog: false,
    roleId: "",
    pageId: "",
    isLoading: false,
    success: false,
    error: "",
  },
};

const actions = {
  [FETCH_PAGE_AUTHS]: async ({ commit }, roleId) => {
    try {
      commit(SET_IS_LOADING, true);

      let pageAuths = await AdminApi.getPageAuths(roleId);
      commit(SET_PAGE_AUTHS, pageAuths);
    } catch (error) {
      commit(SET_ERROR, error.response.data);
    } finally {
      commit(SET_IS_LOADING, false);
    }
  },
  [CREATE_PAGE_AUTHS]: async ({ commit, dispatch }, auth) => {
    try {
      commit(SET_CREATE_IS_LOADING, true);

      await AdminApi.createPageAuths(auth);

      commit(SET_CREATE_SUCCESS, true);
      dispatch(FETCH_PAGE_AUTHS, auth.roleId);
    } catch (error) {
      commit(SET_CREATE_ERROR, error.response.data);
    } finally {
      commit(SET_CREATE_IS_LOADING, false);
    }
  },
  [DELETE_PAGE_AUTHS]: async ({ commit, dispatch }, { pageId, roleId }) => {
    try {
      commit(SET_DELETE_IS_LOADING, true);

      await AdminApi.deletePageAuths(pageId, roleId);

      commit(SET_DELETE_SUCCESS, true);
      dispatch(FETCH_PAGE_AUTHS, roleId);
    } catch (error) {
      commit(SET_DELETE_ERROR, error.response.data);
    } finally {
      commit(SET_DELETE_IS_LOADING, false);
    }
  },
};

const mutations = {
  [TOGGLE_PAGE_AUTH_DIALOG]: (state, roleId = "") => {
    state.dialog = !state.dialog;
    state.roleId = roleId;
  },
  [SET_PAGE_AUTHS]: (state, pageAuths) => (state.pageAuths = pageAuths),
  [SET_IS_LOADING]: (state, isLoading) => (state.isLoading = isLoading),
  [SET_ERROR]: (state, error) => (state.error = error),

  [CLEAR_CREATE]: (state) => {
    state.create.success = false;
    state.create.error = "";
    state.create.isLoading = false;
  },
  [TOGGLE_CREATE_DIALOG]: (state) => (state.create.dialog = !state.create.dialog),
  [SET_CREATE_IS_LOADING]: (state, isLoading) => (state.create.isLoading = isLoading),
  [SET_CREATE_SUCCESS]: (state) => (state.create.success = true),
  [SET_CREATE_ERROR]: (state, error) => (state.create.error = error),

  [TOGGLE_DELETE_DIALOG]: (state, { pageId, roleId } = {}) => {
    state.delete.dialog = !state.delete.dialog;
    state.delete.pageId = pageId;
    state.delete.roleId = roleId;
    state.delete.success = false;
    state.delete.error = "";
    state.delete.isLoading = false;
  },
  [SET_DELETE_IS_LOADING]: (state, isLoading) => (state.delete.isLoading = isLoading),
  [SET_DELETE_SUCCESS]: (state) => (state.delete.success = true),
  [SET_DELETE_ERROR]: (state, error) => (state.delete.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
